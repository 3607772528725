/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

/** columns and relationships of "Budget" */
export type Budget = {
  __typename?: "Budget";
  /** An object relationship */
  User: User;
  month: Scalars["Int"];
  timeLeft: Scalars["Int"];
  userId: Scalars["String"];
  year: Scalars["Int"];
};

/** aggregated selection of "Budget" */
export type Budget_Aggregate = {
  __typename?: "Budget_aggregate";
  aggregate?: Maybe<Budget_Aggregate_Fields>;
  nodes: Array<Budget>;
};

/** aggregate fields of "Budget" */
export type Budget_Aggregate_Fields = {
  __typename?: "Budget_aggregate_fields";
  avg?: Maybe<Budget_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Budget_Max_Fields>;
  min?: Maybe<Budget_Min_Fields>;
  stddev?: Maybe<Budget_Stddev_Fields>;
  stddev_pop?: Maybe<Budget_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Budget_Stddev_Samp_Fields>;
  sum?: Maybe<Budget_Sum_Fields>;
  var_pop?: Maybe<Budget_Var_Pop_Fields>;
  var_samp?: Maybe<Budget_Var_Samp_Fields>;
  variance?: Maybe<Budget_Variance_Fields>;
};

/** aggregate fields of "Budget" */
export type Budget_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Budget_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Budget" */
export type Budget_Aggregate_Order_By = {
  avg?: Maybe<Budget_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Budget_Max_Order_By>;
  min?: Maybe<Budget_Min_Order_By>;
  stddev?: Maybe<Budget_Stddev_Order_By>;
  stddev_pop?: Maybe<Budget_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Budget_Stddev_Samp_Order_By>;
  sum?: Maybe<Budget_Sum_Order_By>;
  var_pop?: Maybe<Budget_Var_Pop_Order_By>;
  var_samp?: Maybe<Budget_Var_Samp_Order_By>;
  variance?: Maybe<Budget_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Budget" */
export type Budget_Arr_Rel_Insert_Input = {
  data: Array<Budget_Insert_Input>;
  on_conflict?: Maybe<Budget_On_Conflict>;
};

/** aggregate avg on columns */
export type Budget_Avg_Fields = {
  __typename?: "Budget_avg_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Budget" */
export type Budget_Avg_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Budget". All fields are combined with a logical 'AND'. */
export type Budget_Bool_Exp = {
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Budget_Bool_Exp>>>;
  _not?: Maybe<Budget_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Budget_Bool_Exp>>>;
  month?: Maybe<Int_Comparison_Exp>;
  timeLeft?: Maybe<Int_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Budget" */
export enum Budget_Constraint {
  /** unique or primary key constraint */
  BudgetPkey = "Budget_pkey",
}

/** input type for incrementing integer column in table "Budget" */
export type Budget_Inc_Input = {
  month?: Maybe<Scalars["Int"]>;
  timeLeft?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Budget" */
export type Budget_Insert_Input = {
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  month?: Maybe<Scalars["Int"]>;
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Budget_Max_Fields = {
  __typename?: "Budget_max_fields";
  month?: Maybe<Scalars["Int"]>;
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "Budget" */
export type Budget_Max_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Budget_Min_Fields = {
  __typename?: "Budget_min_fields";
  month?: Maybe<Scalars["Int"]>;
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "Budget" */
export type Budget_Min_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "Budget" */
export type Budget_Mutation_Response = {
  __typename?: "Budget_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Budget>;
};

/** input type for inserting object relation for remote table "Budget" */
export type Budget_Obj_Rel_Insert_Input = {
  data: Budget_Insert_Input;
  on_conflict?: Maybe<Budget_On_Conflict>;
};

/** on conflict condition type for table "Budget" */
export type Budget_On_Conflict = {
  constraint: Budget_Constraint;
  update_columns: Array<Budget_Update_Column>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** ordering options when selecting data from "Budget" */
export type Budget_Order_By = {
  User?: Maybe<User_Order_By>;
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: "Budget" */
export type Budget_Pk_Columns_Input = {
  month: Scalars["Int"];
  userId: Scalars["String"];
  year: Scalars["Int"];
};

/** select columns of table "Budget" */
export enum Budget_Select_Column {
  /** column name */
  Month = "month",
  /** column name */
  TimeLeft = "timeLeft",
  /** column name */
  UserId = "userId",
  /** column name */
  Year = "year",
}

/** input type for updating data in table "Budget" */
export type Budget_Set_Input = {
  month?: Maybe<Scalars["Int"]>;
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Budget_Stddev_Fields = {
  __typename?: "Budget_stddev_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Budget" */
export type Budget_Stddev_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Budget_Stddev_Pop_Fields = {
  __typename?: "Budget_stddev_pop_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Budget" */
export type Budget_Stddev_Pop_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Budget_Stddev_Samp_Fields = {
  __typename?: "Budget_stddev_samp_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Budget" */
export type Budget_Stddev_Samp_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Budget_Sum_Fields = {
  __typename?: "Budget_sum_fields";
  month?: Maybe<Scalars["Int"]>;
  timeLeft?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Budget" */
export type Budget_Sum_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "Budget" */
export enum Budget_Update_Column {
  /** column name */
  Month = "month",
  /** column name */
  TimeLeft = "timeLeft",
  /** column name */
  UserId = "userId",
  /** column name */
  Year = "year",
}

/** aggregate var_pop on columns */
export type Budget_Var_Pop_Fields = {
  __typename?: "Budget_var_pop_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Budget" */
export type Budget_Var_Pop_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Budget_Var_Samp_Fields = {
  __typename?: "Budget_var_samp_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Budget" */
export type Budget_Var_Samp_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Budget_Variance_Fields = {
  __typename?: "Budget_variance_fields";
  month?: Maybe<Scalars["Float"]>;
  timeLeft?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Budget" */
export type Budget_Variance_Order_By = {
  month?: Maybe<Order_By>;
  timeLeft?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "CurrentBudget" */
export type CurrentBudget = {
  __typename?: "CurrentBudget";
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "CurrentBudget" */
export type CurrentBudget_Aggregate = {
  __typename?: "CurrentBudget_aggregate";
  aggregate?: Maybe<CurrentBudget_Aggregate_Fields>;
  nodes: Array<CurrentBudget>;
};

/** aggregate fields of "CurrentBudget" */
export type CurrentBudget_Aggregate_Fields = {
  __typename?: "CurrentBudget_aggregate_fields";
  avg?: Maybe<CurrentBudget_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<CurrentBudget_Max_Fields>;
  min?: Maybe<CurrentBudget_Min_Fields>;
  stddev?: Maybe<CurrentBudget_Stddev_Fields>;
  stddev_pop?: Maybe<CurrentBudget_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CurrentBudget_Stddev_Samp_Fields>;
  sum?: Maybe<CurrentBudget_Sum_Fields>;
  var_pop?: Maybe<CurrentBudget_Var_Pop_Fields>;
  var_samp?: Maybe<CurrentBudget_Var_Samp_Fields>;
  variance?: Maybe<CurrentBudget_Variance_Fields>;
};

/** aggregate fields of "CurrentBudget" */
export type CurrentBudget_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CurrentBudget_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "CurrentBudget" */
export type CurrentBudget_Aggregate_Order_By = {
  avg?: Maybe<CurrentBudget_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CurrentBudget_Max_Order_By>;
  min?: Maybe<CurrentBudget_Min_Order_By>;
  stddev?: Maybe<CurrentBudget_Stddev_Order_By>;
  stddev_pop?: Maybe<CurrentBudget_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CurrentBudget_Stddev_Samp_Order_By>;
  sum?: Maybe<CurrentBudget_Sum_Order_By>;
  var_pop?: Maybe<CurrentBudget_Var_Pop_Order_By>;
  var_samp?: Maybe<CurrentBudget_Var_Samp_Order_By>;
  variance?: Maybe<CurrentBudget_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CurrentBudget" */
export type CurrentBudget_Arr_Rel_Insert_Input = {
  data: Array<CurrentBudget_Insert_Input>;
};

/** aggregate avg on columns */
export type CurrentBudget_Avg_Fields = {
  __typename?: "CurrentBudget_avg_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "CurrentBudget" */
export type CurrentBudget_Avg_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CurrentBudget". All fields are combined with a logical 'AND'. */
export type CurrentBudget_Bool_Exp = {
  _and?: Maybe<Array<Maybe<CurrentBudget_Bool_Exp>>>;
  _not?: Maybe<CurrentBudget_Bool_Exp>;
  _or?: Maybe<Array<Maybe<CurrentBudget_Bool_Exp>>>;
  timeLeft?: Maybe<Int_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "CurrentBudget" */
export type CurrentBudget_Inc_Input = {
  timeLeft?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "CurrentBudget" */
export type CurrentBudget_Insert_Input = {
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type CurrentBudget_Max_Fields = {
  __typename?: "CurrentBudget_max_fields";
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "CurrentBudget" */
export type CurrentBudget_Max_Order_By = {
  timeLeft?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CurrentBudget_Min_Fields = {
  __typename?: "CurrentBudget_min_fields";
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "CurrentBudget" */
export type CurrentBudget_Min_Order_By = {
  timeLeft?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "CurrentBudget" */
export type CurrentBudget_Mutation_Response = {
  __typename?: "CurrentBudget_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<CurrentBudget>;
};

/** input type for inserting object relation for remote table "CurrentBudget" */
export type CurrentBudget_Obj_Rel_Insert_Input = {
  data: CurrentBudget_Insert_Input;
};

/** ordering options when selecting data from "CurrentBudget" */
export type CurrentBudget_Order_By = {
  timeLeft?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** select columns of table "CurrentBudget" */
export enum CurrentBudget_Select_Column {
  /** column name */
  TimeLeft = "timeLeft",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "CurrentBudget" */
export type CurrentBudget_Set_Input = {
  timeLeft?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type CurrentBudget_Stddev_Fields = {
  __typename?: "CurrentBudget_stddev_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "CurrentBudget" */
export type CurrentBudget_Stddev_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CurrentBudget_Stddev_Pop_Fields = {
  __typename?: "CurrentBudget_stddev_pop_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "CurrentBudget" */
export type CurrentBudget_Stddev_Pop_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CurrentBudget_Stddev_Samp_Fields = {
  __typename?: "CurrentBudget_stddev_samp_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "CurrentBudget" */
export type CurrentBudget_Stddev_Samp_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CurrentBudget_Sum_Fields = {
  __typename?: "CurrentBudget_sum_fields";
  timeLeft?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "CurrentBudget" */
export type CurrentBudget_Sum_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type CurrentBudget_Var_Pop_Fields = {
  __typename?: "CurrentBudget_var_pop_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "CurrentBudget" */
export type CurrentBudget_Var_Pop_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CurrentBudget_Var_Samp_Fields = {
  __typename?: "CurrentBudget_var_samp_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "CurrentBudget" */
export type CurrentBudget_Var_Samp_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CurrentBudget_Variance_Fields = {
  __typename?: "CurrentBudget_variance_fields";
  timeLeft?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "CurrentBudget" */
export type CurrentBudget_Variance_Order_By = {
  timeLeft?: Maybe<Order_By>;
};

/** columns and relationships of "ExternalToInternalContent" */
export type ExternalToInternalContent = {
  __typename?: "ExternalToInternalContent";
  /** An object relationship */
  MediaContent?: Maybe<MediaContent>;
  /** An array relationship */
  Sessions: Array<Session>;
  /** An aggregated array relationship */
  Sessions_aggregate: Session_Aggregate;
  /** An object relationship */
  StreamingService: StreamingService;
  /** An object relationship */
  UserGeneratedContent?: Maybe<UserGeneratedContent>;
  host: Scalars["String"];
  hostContentId: Scalars["String"];
  link: Scalars["String"];
  mediaContentId?: Maybe<Scalars["uuid"]>;
  userGeneratedContentId?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "ExternalToInternalContent" */
export type ExternalToInternalContentSessionsArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** columns and relationships of "ExternalToInternalContent" */
export type ExternalToInternalContentSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** aggregated selection of "ExternalToInternalContent" */
export type ExternalToInternalContent_Aggregate = {
  __typename?: "ExternalToInternalContent_aggregate";
  aggregate?: Maybe<ExternalToInternalContent_Aggregate_Fields>;
  nodes: Array<ExternalToInternalContent>;
};

/** aggregate fields of "ExternalToInternalContent" */
export type ExternalToInternalContent_Aggregate_Fields = {
  __typename?: "ExternalToInternalContent_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<ExternalToInternalContent_Max_Fields>;
  min?: Maybe<ExternalToInternalContent_Min_Fields>;
};

/** aggregate fields of "ExternalToInternalContent" */
export type ExternalToInternalContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ExternalToInternalContent" */
export type ExternalToInternalContent_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ExternalToInternalContent_Max_Order_By>;
  min?: Maybe<ExternalToInternalContent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ExternalToInternalContent" */
export type ExternalToInternalContent_Arr_Rel_Insert_Input = {
  data: Array<ExternalToInternalContent_Insert_Input>;
  on_conflict?: Maybe<ExternalToInternalContent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ExternalToInternalContent". All fields are combined with a logical 'AND'. */
export type ExternalToInternalContent_Bool_Exp = {
  MediaContent?: Maybe<MediaContent_Bool_Exp>;
  Sessions?: Maybe<Session_Bool_Exp>;
  StreamingService?: Maybe<StreamingService_Bool_Exp>;
  UserGeneratedContent?: Maybe<UserGeneratedContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<ExternalToInternalContent_Bool_Exp>>>;
  _not?: Maybe<ExternalToInternalContent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ExternalToInternalContent_Bool_Exp>>>;
  host?: Maybe<String_Comparison_Exp>;
  hostContentId?: Maybe<String_Comparison_Exp>;
  link?: Maybe<String_Comparison_Exp>;
  mediaContentId?: Maybe<Uuid_Comparison_Exp>;
  userGeneratedContentId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ExternalToInternalContent" */
export enum ExternalToInternalContent_Constraint {
  /** unique or primary key constraint */
  ExternalToInternalContentLinkKey = "ExternalToInternalContent_link_key",
  /** unique or primary key constraint */
  ExternalToInternalContentPkey = "ExternalToInternalContent_pkey",
}

/** input type for inserting data into table "ExternalToInternalContent" */
export type ExternalToInternalContent_Insert_Input = {
  MediaContent?: Maybe<MediaContent_Obj_Rel_Insert_Input>;
  Sessions?: Maybe<Session_Arr_Rel_Insert_Input>;
  StreamingService?: Maybe<StreamingService_Obj_Rel_Insert_Input>;
  UserGeneratedContent?: Maybe<UserGeneratedContent_Obj_Rel_Insert_Input>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
  userGeneratedContentId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type ExternalToInternalContent_Max_Fields = {
  __typename?: "ExternalToInternalContent_max_fields";
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
  userGeneratedContentId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ExternalToInternalContent" */
export type ExternalToInternalContent_Max_Order_By = {
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  mediaContentId?: Maybe<Order_By>;
  userGeneratedContentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ExternalToInternalContent_Min_Fields = {
  __typename?: "ExternalToInternalContent_min_fields";
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
  userGeneratedContentId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ExternalToInternalContent" */
export type ExternalToInternalContent_Min_Order_By = {
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  mediaContentId?: Maybe<Order_By>;
  userGeneratedContentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ExternalToInternalContent" */
export type ExternalToInternalContent_Mutation_Response = {
  __typename?: "ExternalToInternalContent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<ExternalToInternalContent>;
};

/** input type for inserting object relation for remote table "ExternalToInternalContent" */
export type ExternalToInternalContent_Obj_Rel_Insert_Input = {
  data: ExternalToInternalContent_Insert_Input;
  on_conflict?: Maybe<ExternalToInternalContent_On_Conflict>;
};

/** on conflict condition type for table "ExternalToInternalContent" */
export type ExternalToInternalContent_On_Conflict = {
  constraint: ExternalToInternalContent_Constraint;
  update_columns: Array<ExternalToInternalContent_Update_Column>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** ordering options when selecting data from "ExternalToInternalContent" */
export type ExternalToInternalContent_Order_By = {
  MediaContent?: Maybe<MediaContent_Order_By>;
  Sessions_aggregate?: Maybe<Session_Aggregate_Order_By>;
  StreamingService?: Maybe<StreamingService_Order_By>;
  UserGeneratedContent?: Maybe<UserGeneratedContent_Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  link?: Maybe<Order_By>;
  mediaContentId?: Maybe<Order_By>;
  userGeneratedContentId?: Maybe<Order_By>;
};

/** primary key columns input for table: "ExternalToInternalContent" */
export type ExternalToInternalContent_Pk_Columns_Input = {
  host: Scalars["String"];
  hostContentId: Scalars["String"];
};

/** select columns of table "ExternalToInternalContent" */
export enum ExternalToInternalContent_Select_Column {
  /** column name */
  Host = "host",
  /** column name */
  HostContentId = "hostContentId",
  /** column name */
  Link = "link",
  /** column name */
  MediaContentId = "mediaContentId",
  /** column name */
  UserGeneratedContentId = "userGeneratedContentId",
}

/** input type for updating data in table "ExternalToInternalContent" */
export type ExternalToInternalContent_Set_Input = {
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
  userGeneratedContentId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ExternalToInternalContent" */
export enum ExternalToInternalContent_Update_Column {
  /** column name */
  Host = "host",
  /** column name */
  HostContentId = "hostContentId",
  /** column name */
  Link = "link",
  /** column name */
  MediaContentId = "mediaContentId",
  /** column name */
  UserGeneratedContentId = "userGeneratedContentId",
}

/** columns and relationships of "Genre" */
export type Genre = {
  __typename?: "Genre";
  /** An array relationship */
  GenreI18Ns: Array<GenreI18N>;
  /** An aggregated array relationship */
  GenreI18Ns_aggregate: GenreI18N_Aggregate;
  /** An array relationship */
  GenreMediaContents: Array<GenreMediaContent>;
  /** An aggregated array relationship */
  GenreMediaContents_aggregate: GenreMediaContent_Aggregate;
  id: Scalars["Int"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

/** columns and relationships of "Genre" */
export type GenreGenreI18NsArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** columns and relationships of "Genre" */
export type GenreGenreI18Ns_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** columns and relationships of "Genre" */
export type GenreGenreMediaContentsArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** columns and relationships of "Genre" */
export type GenreGenreMediaContents_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** columns and relationships of "GenreI18N" */
export type GenreI18N = {
  __typename?: "GenreI18N";
  /** An object relationship */
  Genre: Genre;
  /** An object relationship */
  Language: Languages;
  id: Scalars["Int"];
  language: Scalars["String"];
  name: Scalars["String"];
};

/** aggregated selection of "GenreI18N" */
export type GenreI18N_Aggregate = {
  __typename?: "GenreI18N_aggregate";
  aggregate?: Maybe<GenreI18N_Aggregate_Fields>;
  nodes: Array<GenreI18N>;
};

/** aggregate fields of "GenreI18N" */
export type GenreI18N_Aggregate_Fields = {
  __typename?: "GenreI18N_aggregate_fields";
  avg?: Maybe<GenreI18N_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<GenreI18N_Max_Fields>;
  min?: Maybe<GenreI18N_Min_Fields>;
  stddev?: Maybe<GenreI18N_Stddev_Fields>;
  stddev_pop?: Maybe<GenreI18N_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GenreI18N_Stddev_Samp_Fields>;
  sum?: Maybe<GenreI18N_Sum_Fields>;
  var_pop?: Maybe<GenreI18N_Var_Pop_Fields>;
  var_samp?: Maybe<GenreI18N_Var_Samp_Fields>;
  variance?: Maybe<GenreI18N_Variance_Fields>;
};

/** aggregate fields of "GenreI18N" */
export type GenreI18N_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<GenreI18N_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "GenreI18N" */
export type GenreI18N_Aggregate_Order_By = {
  avg?: Maybe<GenreI18N_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<GenreI18N_Max_Order_By>;
  min?: Maybe<GenreI18N_Min_Order_By>;
  stddev?: Maybe<GenreI18N_Stddev_Order_By>;
  stddev_pop?: Maybe<GenreI18N_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<GenreI18N_Stddev_Samp_Order_By>;
  sum?: Maybe<GenreI18N_Sum_Order_By>;
  var_pop?: Maybe<GenreI18N_Var_Pop_Order_By>;
  var_samp?: Maybe<GenreI18N_Var_Samp_Order_By>;
  variance?: Maybe<GenreI18N_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "GenreI18N" */
export type GenreI18N_Arr_Rel_Insert_Input = {
  data: Array<GenreI18N_Insert_Input>;
  on_conflict?: Maybe<GenreI18N_On_Conflict>;
};

/** aggregate avg on columns */
export type GenreI18N_Avg_Fields = {
  __typename?: "GenreI18N_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "GenreI18N" */
export type GenreI18N_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GenreI18N". All fields are combined with a logical 'AND'. */
export type GenreI18N_Bool_Exp = {
  Genre?: Maybe<Genre_Bool_Exp>;
  Language?: Maybe<Languages_Bool_Exp>;
  _and?: Maybe<Array<Maybe<GenreI18N_Bool_Exp>>>;
  _not?: Maybe<GenreI18N_Bool_Exp>;
  _or?: Maybe<Array<Maybe<GenreI18N_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "GenreI18N" */
export enum GenreI18N_Constraint {
  /** unique or primary key constraint */
  GenreI18NPkey = "GenreI18N_pkey",
}

/** input type for incrementing integer column in table "GenreI18N" */
export type GenreI18N_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "GenreI18N" */
export type GenreI18N_Insert_Input = {
  Genre?: Maybe<Genre_Obj_Rel_Insert_Input>;
  Language?: Maybe<Languages_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  language?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type GenreI18N_Max_Fields = {
  __typename?: "GenreI18N_max_fields";
  id?: Maybe<Scalars["Int"]>;
  language?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "GenreI18N" */
export type GenreI18N_Max_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type GenreI18N_Min_Fields = {
  __typename?: "GenreI18N_min_fields";
  id?: Maybe<Scalars["Int"]>;
  language?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "GenreI18N" */
export type GenreI18N_Min_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "GenreI18N" */
export type GenreI18N_Mutation_Response = {
  __typename?: "GenreI18N_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<GenreI18N>;
};

/** input type for inserting object relation for remote table "GenreI18N" */
export type GenreI18N_Obj_Rel_Insert_Input = {
  data: GenreI18N_Insert_Input;
  on_conflict?: Maybe<GenreI18N_On_Conflict>;
};

/** on conflict condition type for table "GenreI18N" */
export type GenreI18N_On_Conflict = {
  constraint: GenreI18N_Constraint;
  update_columns: Array<GenreI18N_Update_Column>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** ordering options when selecting data from "GenreI18N" */
export type GenreI18N_Order_By = {
  Genre?: Maybe<Genre_Order_By>;
  Language?: Maybe<Languages_Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "GenreI18N" */
export type GenreI18N_Pk_Columns_Input = {
  id: Scalars["Int"];
  language: Scalars["String"];
};

/** select columns of table "GenreI18N" */
export enum GenreI18N_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Language = "language",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "GenreI18N" */
export type GenreI18N_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  language?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type GenreI18N_Stddev_Fields = {
  __typename?: "GenreI18N_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "GenreI18N" */
export type GenreI18N_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GenreI18N_Stddev_Pop_Fields = {
  __typename?: "GenreI18N_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "GenreI18N" */
export type GenreI18N_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GenreI18N_Stddev_Samp_Fields = {
  __typename?: "GenreI18N_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "GenreI18N" */
export type GenreI18N_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type GenreI18N_Sum_Fields = {
  __typename?: "GenreI18N_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "GenreI18N" */
export type GenreI18N_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "GenreI18N" */
export enum GenreI18N_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Language = "language",
  /** column name */
  Name = "name",
}

/** aggregate var_pop on columns */
export type GenreI18N_Var_Pop_Fields = {
  __typename?: "GenreI18N_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "GenreI18N" */
export type GenreI18N_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GenreI18N_Var_Samp_Fields = {
  __typename?: "GenreI18N_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "GenreI18N" */
export type GenreI18N_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type GenreI18N_Variance_Fields = {
  __typename?: "GenreI18N_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "GenreI18N" */
export type GenreI18N_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "GenreMediaContent" */
export type GenreMediaContent = {
  __typename?: "GenreMediaContent";
  /** An object relationship */
  Genre: Genre;
  /** An object relationship */
  MediaContent: MediaContent;
  genreId: Scalars["Int"];
  mediaContentId: Scalars["uuid"];
};

/** aggregated selection of "GenreMediaContent" */
export type GenreMediaContent_Aggregate = {
  __typename?: "GenreMediaContent_aggregate";
  aggregate?: Maybe<GenreMediaContent_Aggregate_Fields>;
  nodes: Array<GenreMediaContent>;
};

/** aggregate fields of "GenreMediaContent" */
export type GenreMediaContent_Aggregate_Fields = {
  __typename?: "GenreMediaContent_aggregate_fields";
  avg?: Maybe<GenreMediaContent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<GenreMediaContent_Max_Fields>;
  min?: Maybe<GenreMediaContent_Min_Fields>;
  stddev?: Maybe<GenreMediaContent_Stddev_Fields>;
  stddev_pop?: Maybe<GenreMediaContent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GenreMediaContent_Stddev_Samp_Fields>;
  sum?: Maybe<GenreMediaContent_Sum_Fields>;
  var_pop?: Maybe<GenreMediaContent_Var_Pop_Fields>;
  var_samp?: Maybe<GenreMediaContent_Var_Samp_Fields>;
  variance?: Maybe<GenreMediaContent_Variance_Fields>;
};

/** aggregate fields of "GenreMediaContent" */
export type GenreMediaContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<GenreMediaContent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "GenreMediaContent" */
export type GenreMediaContent_Aggregate_Order_By = {
  avg?: Maybe<GenreMediaContent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<GenreMediaContent_Max_Order_By>;
  min?: Maybe<GenreMediaContent_Min_Order_By>;
  stddev?: Maybe<GenreMediaContent_Stddev_Order_By>;
  stddev_pop?: Maybe<GenreMediaContent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<GenreMediaContent_Stddev_Samp_Order_By>;
  sum?: Maybe<GenreMediaContent_Sum_Order_By>;
  var_pop?: Maybe<GenreMediaContent_Var_Pop_Order_By>;
  var_samp?: Maybe<GenreMediaContent_Var_Samp_Order_By>;
  variance?: Maybe<GenreMediaContent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "GenreMediaContent" */
export type GenreMediaContent_Arr_Rel_Insert_Input = {
  data: Array<GenreMediaContent_Insert_Input>;
  on_conflict?: Maybe<GenreMediaContent_On_Conflict>;
};

/** aggregate avg on columns */
export type GenreMediaContent_Avg_Fields = {
  __typename?: "GenreMediaContent_avg_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Avg_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GenreMediaContent". All fields are combined with a logical 'AND'. */
export type GenreMediaContent_Bool_Exp = {
  Genre?: Maybe<Genre_Bool_Exp>;
  MediaContent?: Maybe<MediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<GenreMediaContent_Bool_Exp>>>;
  _not?: Maybe<GenreMediaContent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<GenreMediaContent_Bool_Exp>>>;
  genreId?: Maybe<Int_Comparison_Exp>;
  mediaContentId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "GenreMediaContent" */
export enum GenreMediaContent_Constraint {
  /** unique or primary key constraint */
  GenreMediaContentPkey = "GenreMediaContent_pkey",
}

/** input type for incrementing integer column in table "GenreMediaContent" */
export type GenreMediaContent_Inc_Input = {
  genreId?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "GenreMediaContent" */
export type GenreMediaContent_Insert_Input = {
  Genre?: Maybe<Genre_Obj_Rel_Insert_Input>;
  MediaContent?: Maybe<MediaContent_Obj_Rel_Insert_Input>;
  genreId?: Maybe<Scalars["Int"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type GenreMediaContent_Max_Fields = {
  __typename?: "GenreMediaContent_max_fields";
  genreId?: Maybe<Scalars["Int"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Max_Order_By = {
  genreId?: Maybe<Order_By>;
  mediaContentId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type GenreMediaContent_Min_Fields = {
  __typename?: "GenreMediaContent_min_fields";
  genreId?: Maybe<Scalars["Int"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Min_Order_By = {
  genreId?: Maybe<Order_By>;
  mediaContentId?: Maybe<Order_By>;
};

/** response of any mutation on the table "GenreMediaContent" */
export type GenreMediaContent_Mutation_Response = {
  __typename?: "GenreMediaContent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<GenreMediaContent>;
};

/** input type for inserting object relation for remote table "GenreMediaContent" */
export type GenreMediaContent_Obj_Rel_Insert_Input = {
  data: GenreMediaContent_Insert_Input;
  on_conflict?: Maybe<GenreMediaContent_On_Conflict>;
};

/** on conflict condition type for table "GenreMediaContent" */
export type GenreMediaContent_On_Conflict = {
  constraint: GenreMediaContent_Constraint;
  update_columns: Array<GenreMediaContent_Update_Column>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** ordering options when selecting data from "GenreMediaContent" */
export type GenreMediaContent_Order_By = {
  Genre?: Maybe<Genre_Order_By>;
  MediaContent?: Maybe<MediaContent_Order_By>;
  genreId?: Maybe<Order_By>;
  mediaContentId?: Maybe<Order_By>;
};

/** primary key columns input for table: "GenreMediaContent" */
export type GenreMediaContent_Pk_Columns_Input = {
  genreId: Scalars["Int"];
  mediaContentId: Scalars["uuid"];
};

/** select columns of table "GenreMediaContent" */
export enum GenreMediaContent_Select_Column {
  /** column name */
  GenreId = "genreId",
  /** column name */
  MediaContentId = "mediaContentId",
}

/** input type for updating data in table "GenreMediaContent" */
export type GenreMediaContent_Set_Input = {
  genreId?: Maybe<Scalars["Int"]>;
  mediaContentId?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type GenreMediaContent_Stddev_Fields = {
  __typename?: "GenreMediaContent_stddev_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Stddev_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GenreMediaContent_Stddev_Pop_Fields = {
  __typename?: "GenreMediaContent_stddev_pop_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Stddev_Pop_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GenreMediaContent_Stddev_Samp_Fields = {
  __typename?: "GenreMediaContent_stddev_samp_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Stddev_Samp_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type GenreMediaContent_Sum_Fields = {
  __typename?: "GenreMediaContent_sum_fields";
  genreId?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Sum_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** update columns of table "GenreMediaContent" */
export enum GenreMediaContent_Update_Column {
  /** column name */
  GenreId = "genreId",
  /** column name */
  MediaContentId = "mediaContentId",
}

/** aggregate var_pop on columns */
export type GenreMediaContent_Var_Pop_Fields = {
  __typename?: "GenreMediaContent_var_pop_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Var_Pop_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GenreMediaContent_Var_Samp_Fields = {
  __typename?: "GenreMediaContent_var_samp_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Var_Samp_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type GenreMediaContent_Variance_Fields = {
  __typename?: "GenreMediaContent_variance_fields";
  genreId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "GenreMediaContent" */
export type GenreMediaContent_Variance_Order_By = {
  genreId?: Maybe<Order_By>;
};

/** aggregated selection of "Genre" */
export type Genre_Aggregate = {
  __typename?: "Genre_aggregate";
  aggregate?: Maybe<Genre_Aggregate_Fields>;
  nodes: Array<Genre>;
};

/** aggregate fields of "Genre" */
export type Genre_Aggregate_Fields = {
  __typename?: "Genre_aggregate_fields";
  avg?: Maybe<Genre_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Genre_Max_Fields>;
  min?: Maybe<Genre_Min_Fields>;
  stddev?: Maybe<Genre_Stddev_Fields>;
  stddev_pop?: Maybe<Genre_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Genre_Stddev_Samp_Fields>;
  sum?: Maybe<Genre_Sum_Fields>;
  var_pop?: Maybe<Genre_Var_Pop_Fields>;
  var_samp?: Maybe<Genre_Var_Samp_Fields>;
  variance?: Maybe<Genre_Variance_Fields>;
};

/** aggregate fields of "Genre" */
export type Genre_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Genre_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Genre" */
export type Genre_Aggregate_Order_By = {
  avg?: Maybe<Genre_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Genre_Max_Order_By>;
  min?: Maybe<Genre_Min_Order_By>;
  stddev?: Maybe<Genre_Stddev_Order_By>;
  stddev_pop?: Maybe<Genre_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Genre_Stddev_Samp_Order_By>;
  sum?: Maybe<Genre_Sum_Order_By>;
  var_pop?: Maybe<Genre_Var_Pop_Order_By>;
  var_samp?: Maybe<Genre_Var_Samp_Order_By>;
  variance?: Maybe<Genre_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Genre" */
export type Genre_Arr_Rel_Insert_Input = {
  data: Array<Genre_Insert_Input>;
  on_conflict?: Maybe<Genre_On_Conflict>;
};

/** aggregate avg on columns */
export type Genre_Avg_Fields = {
  __typename?: "Genre_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Genre" */
export type Genre_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Genre". All fields are combined with a logical 'AND'. */
export type Genre_Bool_Exp = {
  GenreI18Ns?: Maybe<GenreI18N_Bool_Exp>;
  GenreMediaContents?: Maybe<GenreMediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Genre_Bool_Exp>>>;
  _not?: Maybe<Genre_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Genre_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Genre" */
export enum Genre_Constraint {
  /** unique or primary key constraint */
  GenrePkey = "Genre_pkey",
  /** unique or primary key constraint */
  GenreSlugKey = "Genre_slug_key",
}

/** input type for incrementing integer column in table "Genre" */
export type Genre_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Genre" */
export type Genre_Insert_Input = {
  GenreI18Ns?: Maybe<GenreI18N_Arr_Rel_Insert_Input>;
  GenreMediaContents?: Maybe<GenreMediaContent_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Genre_Max_Fields = {
  __typename?: "Genre_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Genre" */
export type Genre_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Genre_Min_Fields = {
  __typename?: "Genre_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Genre" */
export type Genre_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
};

/** response of any mutation on the table "Genre" */
export type Genre_Mutation_Response = {
  __typename?: "Genre_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Genre>;
};

/** input type for inserting object relation for remote table "Genre" */
export type Genre_Obj_Rel_Insert_Input = {
  data: Genre_Insert_Input;
  on_conflict?: Maybe<Genre_On_Conflict>;
};

/** on conflict condition type for table "Genre" */
export type Genre_On_Conflict = {
  constraint: Genre_Constraint;
  update_columns: Array<Genre_Update_Column>;
  where?: Maybe<Genre_Bool_Exp>;
};

/** ordering options when selecting data from "Genre" */
export type Genre_Order_By = {
  GenreI18Ns_aggregate?: Maybe<GenreI18N_Aggregate_Order_By>;
  GenreMediaContents_aggregate?: Maybe<GenreMediaContent_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
};

/** primary key columns input for table: "Genre" */
export type Genre_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "Genre" */
export enum Genre_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Slug = "slug",
}

/** input type for updating data in table "Genre" */
export type Genre_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Genre_Stddev_Fields = {
  __typename?: "Genre_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Genre" */
export type Genre_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Genre_Stddev_Pop_Fields = {
  __typename?: "Genre_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Genre" */
export type Genre_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Genre_Stddev_Samp_Fields = {
  __typename?: "Genre_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Genre" */
export type Genre_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Genre_Sum_Fields = {
  __typename?: "Genre_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Genre" */
export type Genre_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "Genre" */
export enum Genre_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Slug = "slug",
}

/** aggregate var_pop on columns */
export type Genre_Var_Pop_Fields = {
  __typename?: "Genre_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Genre" */
export type Genre_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Genre_Var_Samp_Fields = {
  __typename?: "Genre_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Genre" */
export type Genre_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Genre_Variance_Fields = {
  __typename?: "Genre_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Genre" */
export type Genre_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** columns and relationships of "Languages" */
export type Languages = {
  __typename?: "Languages";
  /** An array relationship */
  GenreI18Ns: Array<GenreI18N>;
  /** An aggregated array relationship */
  GenreI18Ns_aggregate: GenreI18N_Aggregate;
  /** An array relationship */
  MediaContentI18Ns: Array<MediaContentI18N>;
  /** An aggregated array relationship */
  MediaContentI18Ns_aggregate: MediaContentI18N_Aggregate;
  code: Scalars["String"];
  name: Scalars["String"];
};

/** columns and relationships of "Languages" */
export type LanguagesGenreI18NsArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** columns and relationships of "Languages" */
export type LanguagesGenreI18Ns_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** columns and relationships of "Languages" */
export type LanguagesMediaContentI18NsArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** columns and relationships of "Languages" */
export type LanguagesMediaContentI18Ns_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** aggregated selection of "Languages" */
export type Languages_Aggregate = {
  __typename?: "Languages_aggregate";
  aggregate?: Maybe<Languages_Aggregate_Fields>;
  nodes: Array<Languages>;
};

/** aggregate fields of "Languages" */
export type Languages_Aggregate_Fields = {
  __typename?: "Languages_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Languages_Max_Fields>;
  min?: Maybe<Languages_Min_Fields>;
};

/** aggregate fields of "Languages" */
export type Languages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Languages_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Languages" */
export type Languages_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Languages_Max_Order_By>;
  min?: Maybe<Languages_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Languages" */
export type Languages_Arr_Rel_Insert_Input = {
  data: Array<Languages_Insert_Input>;
  on_conflict?: Maybe<Languages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Languages". All fields are combined with a logical 'AND'. */
export type Languages_Bool_Exp = {
  GenreI18Ns?: Maybe<GenreI18N_Bool_Exp>;
  MediaContentI18Ns?: Maybe<MediaContentI18N_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Languages_Bool_Exp>>>;
  _not?: Maybe<Languages_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Languages_Bool_Exp>>>;
  code?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Languages" */
export enum Languages_Constraint {
  /** unique or primary key constraint */
  LanguagesPkey = "Languages_pkey",
}

/** input type for inserting data into table "Languages" */
export type Languages_Insert_Input = {
  GenreI18Ns?: Maybe<GenreI18N_Arr_Rel_Insert_Input>;
  MediaContentI18Ns?: Maybe<MediaContentI18N_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Languages_Max_Fields = {
  __typename?: "Languages_max_fields";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Languages" */
export type Languages_Max_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Languages_Min_Fields = {
  __typename?: "Languages_min_fields";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Languages" */
export type Languages_Min_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "Languages" */
export type Languages_Mutation_Response = {
  __typename?: "Languages_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Languages>;
};

/** input type for inserting object relation for remote table "Languages" */
export type Languages_Obj_Rel_Insert_Input = {
  data: Languages_Insert_Input;
  on_conflict?: Maybe<Languages_On_Conflict>;
};

/** on conflict condition type for table "Languages" */
export type Languages_On_Conflict = {
  constraint: Languages_Constraint;
  update_columns: Array<Languages_Update_Column>;
  where?: Maybe<Languages_Bool_Exp>;
};

/** ordering options when selecting data from "Languages" */
export type Languages_Order_By = {
  GenreI18Ns_aggregate?: Maybe<GenreI18N_Aggregate_Order_By>;
  MediaContentI18Ns_aggregate?: Maybe<MediaContentI18N_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "Languages" */
export type Languages_Pk_Columns_Input = {
  code: Scalars["String"];
};

/** select columns of table "Languages" */
export enum Languages_Select_Column {
  /** column name */
  Code = "code",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "Languages" */
export type Languages_Set_Input = {
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** update columns of table "Languages" */
export enum Languages_Update_Column {
  /** column name */
  Code = "code",
  /** column name */
  Name = "name",
}

/** columns and relationships of "MediaContent" */
export type MediaContent = {
  __typename?: "MediaContent";
  /** An array relationship */
  Episodes: Array<MediaContent>;
  /** An aggregated array relationship */
  Episodes_aggregate: MediaContent_Aggregate;
  /** An array relationship */
  ExternalToInternalContents: Array<ExternalToInternalContent>;
  /** An aggregated array relationship */
  ExternalToInternalContents_aggregate: ExternalToInternalContent_Aggregate;
  /** An array relationship */
  GenreMediaContents: Array<GenreMediaContent>;
  /** An aggregated array relationship */
  GenreMediaContents_aggregate: GenreMediaContent_Aggregate;
  /** An array relationship */
  MediaContentI18Ns: Array<MediaContentI18N>;
  /** An aggregated array relationship */
  MediaContentI18Ns_aggregate: MediaContentI18N_Aggregate;
  /** An array relationship */
  MediaContentReferences: Array<MediaContentReferences>;
  /** An aggregated array relationship */
  MediaContentReferences_aggregate: MediaContentReferences_Aggregate;
  /** An object relationship */
  MediaType: MediaType;
  /** An object relationship */
  Show?: Maybe<MediaContent>;
  backdropURL?: Maybe<Scalars["String"]>;
  createdAt: Scalars["timestamptz"];
  episodeNumber?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  overview?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["String"]>;
  releasedOn: Scalars["date"];
  seasonNumber?: Maybe<Scalars["Int"]>;
  showId?: Maybe<Scalars["uuid"]>;
  slug?: Maybe<Scalars["String"]>;
  stillURL?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  totalEpisodes?: Maybe<Scalars["Int"]>;
  type: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** columns and relationships of "MediaContent" */
export type MediaContentEpisodesArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentEpisodes_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentExternalToInternalContentsArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentExternalToInternalContents_AggregateArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentGenreMediaContentsArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentGenreMediaContents_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentMediaContentI18NsArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentMediaContentI18Ns_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentMediaContentReferencesArgs = {
  distinct_on?: Maybe<Array<MediaContentReferences_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentReferences_Order_By>>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** columns and relationships of "MediaContent" */
export type MediaContentMediaContentReferences_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentReferences_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentReferences_Order_By>>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** columns and relationships of "MediaContentI18N" */
export type MediaContentI18N = {
  __typename?: "MediaContentI18N";
  /** An object relationship */
  Language: Languages;
  /** An object relationship */
  MediaContent: MediaContent;
  id: Scalars["uuid"];
  language: Scalars["String"];
  overview?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

/** aggregated selection of "MediaContentI18N" */
export type MediaContentI18N_Aggregate = {
  __typename?: "MediaContentI18N_aggregate";
  aggregate?: Maybe<MediaContentI18N_Aggregate_Fields>;
  nodes: Array<MediaContentI18N>;
};

/** aggregate fields of "MediaContentI18N" */
export type MediaContentI18N_Aggregate_Fields = {
  __typename?: "MediaContentI18N_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<MediaContentI18N_Max_Fields>;
  min?: Maybe<MediaContentI18N_Min_Fields>;
};

/** aggregate fields of "MediaContentI18N" */
export type MediaContentI18N_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<MediaContentI18N_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "MediaContentI18N" */
export type MediaContentI18N_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<MediaContentI18N_Max_Order_By>;
  min?: Maybe<MediaContentI18N_Min_Order_By>;
};

/** input type for inserting array relation for remote table "MediaContentI18N" */
export type MediaContentI18N_Arr_Rel_Insert_Input = {
  data: Array<MediaContentI18N_Insert_Input>;
  on_conflict?: Maybe<MediaContentI18N_On_Conflict>;
};

/** Boolean expression to filter rows from the table "MediaContentI18N". All fields are combined with a logical 'AND'. */
export type MediaContentI18N_Bool_Exp = {
  Language?: Maybe<Languages_Bool_Exp>;
  MediaContent?: Maybe<MediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<MediaContentI18N_Bool_Exp>>>;
  _not?: Maybe<MediaContentI18N_Bool_Exp>;
  _or?: Maybe<Array<Maybe<MediaContentI18N_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  overview?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "MediaContentI18N" */
export enum MediaContentI18N_Constraint {
  /** unique or primary key constraint */
  MediaContentI18NPkey = "MediaContentI18N_pkey",
}

/** input type for inserting data into table "MediaContentI18N" */
export type MediaContentI18N_Insert_Input = {
  Language?: Maybe<Languages_Obj_Rel_Insert_Input>;
  MediaContent?: Maybe<MediaContent_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars["uuid"]>;
  language?: Maybe<Scalars["String"]>;
  overview?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MediaContentI18N_Max_Fields = {
  __typename?: "MediaContentI18N_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  language?: Maybe<Scalars["String"]>;
  overview?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "MediaContentI18N" */
export type MediaContentI18N_Max_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MediaContentI18N_Min_Fields = {
  __typename?: "MediaContentI18N_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  language?: Maybe<Scalars["String"]>;
  overview?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "MediaContentI18N" */
export type MediaContentI18N_Min_Order_By = {
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "MediaContentI18N" */
export type MediaContentI18N_Mutation_Response = {
  __typename?: "MediaContentI18N_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<MediaContentI18N>;
};

/** input type for inserting object relation for remote table "MediaContentI18N" */
export type MediaContentI18N_Obj_Rel_Insert_Input = {
  data: MediaContentI18N_Insert_Input;
  on_conflict?: Maybe<MediaContentI18N_On_Conflict>;
};

/** on conflict condition type for table "MediaContentI18N" */
export type MediaContentI18N_On_Conflict = {
  constraint: MediaContentI18N_Constraint;
  update_columns: Array<MediaContentI18N_Update_Column>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** ordering options when selecting data from "MediaContentI18N" */
export type MediaContentI18N_Order_By = {
  Language?: Maybe<Languages_Order_By>;
  MediaContent?: Maybe<MediaContent_Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "MediaContentI18N" */
export type MediaContentI18N_Pk_Columns_Input = {
  id: Scalars["uuid"];
  language: Scalars["String"];
};

/** select columns of table "MediaContentI18N" */
export enum MediaContentI18N_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Language = "language",
  /** column name */
  Overview = "overview",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "MediaContentI18N" */
export type MediaContentI18N_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  language?: Maybe<Scalars["String"]>;
  overview?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** update columns of table "MediaContentI18N" */
export enum MediaContentI18N_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Language = "language",
  /** column name */
  Overview = "overview",
  /** column name */
  Title = "title",
}

/** columns and relationships of "MediaContentReferences" */
export type MediaContentReferences = {
  __typename?: "MediaContentReferences";
  /** An object relationship */
  MediaContent: MediaContent;
  id: Scalars["uuid"];
  jwId?: Maybe<Scalars["numeric"]>;
  lastAccessed: Scalars["timestamp"];
  reelgoodId?: Maybe<Scalars["String"]>;
  reelgoodLink?: Maybe<Scalars["String"]>;
  tmdbId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "MediaContentReferences" */
export type MediaContentReferences_Aggregate = {
  __typename?: "MediaContentReferences_aggregate";
  aggregate?: Maybe<MediaContentReferences_Aggregate_Fields>;
  nodes: Array<MediaContentReferences>;
};

/** aggregate fields of "MediaContentReferences" */
export type MediaContentReferences_Aggregate_Fields = {
  __typename?: "MediaContentReferences_aggregate_fields";
  avg?: Maybe<MediaContentReferences_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<MediaContentReferences_Max_Fields>;
  min?: Maybe<MediaContentReferences_Min_Fields>;
  stddev?: Maybe<MediaContentReferences_Stddev_Fields>;
  stddev_pop?: Maybe<MediaContentReferences_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MediaContentReferences_Stddev_Samp_Fields>;
  sum?: Maybe<MediaContentReferences_Sum_Fields>;
  var_pop?: Maybe<MediaContentReferences_Var_Pop_Fields>;
  var_samp?: Maybe<MediaContentReferences_Var_Samp_Fields>;
  variance?: Maybe<MediaContentReferences_Variance_Fields>;
};

/** aggregate fields of "MediaContentReferences" */
export type MediaContentReferences_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<MediaContentReferences_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "MediaContentReferences" */
export type MediaContentReferences_Aggregate_Order_By = {
  avg?: Maybe<MediaContentReferences_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MediaContentReferences_Max_Order_By>;
  min?: Maybe<MediaContentReferences_Min_Order_By>;
  stddev?: Maybe<MediaContentReferences_Stddev_Order_By>;
  stddev_pop?: Maybe<MediaContentReferences_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MediaContentReferences_Stddev_Samp_Order_By>;
  sum?: Maybe<MediaContentReferences_Sum_Order_By>;
  var_pop?: Maybe<MediaContentReferences_Var_Pop_Order_By>;
  var_samp?: Maybe<MediaContentReferences_Var_Samp_Order_By>;
  variance?: Maybe<MediaContentReferences_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "MediaContentReferences" */
export type MediaContentReferences_Arr_Rel_Insert_Input = {
  data: Array<MediaContentReferences_Insert_Input>;
  on_conflict?: Maybe<MediaContentReferences_On_Conflict>;
};

/** aggregate avg on columns */
export type MediaContentReferences_Avg_Fields = {
  __typename?: "MediaContentReferences_avg_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Avg_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MediaContentReferences". All fields are combined with a logical 'AND'. */
export type MediaContentReferences_Bool_Exp = {
  MediaContent?: Maybe<MediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<MediaContentReferences_Bool_Exp>>>;
  _not?: Maybe<MediaContentReferences_Bool_Exp>;
  _or?: Maybe<Array<Maybe<MediaContentReferences_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jwId?: Maybe<Numeric_Comparison_Exp>;
  lastAccessed?: Maybe<Timestamp_Comparison_Exp>;
  reelgoodId?: Maybe<String_Comparison_Exp>;
  reelgoodLink?: Maybe<String_Comparison_Exp>;
  tmdbId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "MediaContentReferences" */
export enum MediaContentReferences_Constraint {
  /** unique or primary key constraint */
  MediaContentReferencesPkey = "MediaContentReferences_pkey",
}

/** input type for incrementing integer column in table "MediaContentReferences" */
export type MediaContentReferences_Inc_Input = {
  jwId?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "MediaContentReferences" */
export type MediaContentReferences_Insert_Input = {
  MediaContent?: Maybe<MediaContent_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars["uuid"]>;
  jwId?: Maybe<Scalars["numeric"]>;
  lastAccessed?: Maybe<Scalars["timestamp"]>;
  reelgoodId?: Maybe<Scalars["String"]>;
  reelgoodLink?: Maybe<Scalars["String"]>;
  tmdbId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MediaContentReferences_Max_Fields = {
  __typename?: "MediaContentReferences_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  jwId?: Maybe<Scalars["numeric"]>;
  lastAccessed?: Maybe<Scalars["timestamp"]>;
  reelgoodId?: Maybe<Scalars["String"]>;
  reelgoodLink?: Maybe<Scalars["String"]>;
  tmdbId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Max_Order_By = {
  id?: Maybe<Order_By>;
  jwId?: Maybe<Order_By>;
  lastAccessed?: Maybe<Order_By>;
  reelgoodId?: Maybe<Order_By>;
  reelgoodLink?: Maybe<Order_By>;
  tmdbId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MediaContentReferences_Min_Fields = {
  __typename?: "MediaContentReferences_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  jwId?: Maybe<Scalars["numeric"]>;
  lastAccessed?: Maybe<Scalars["timestamp"]>;
  reelgoodId?: Maybe<Scalars["String"]>;
  reelgoodLink?: Maybe<Scalars["String"]>;
  tmdbId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Min_Order_By = {
  id?: Maybe<Order_By>;
  jwId?: Maybe<Order_By>;
  lastAccessed?: Maybe<Order_By>;
  reelgoodId?: Maybe<Order_By>;
  reelgoodLink?: Maybe<Order_By>;
  tmdbId?: Maybe<Order_By>;
};

/** response of any mutation on the table "MediaContentReferences" */
export type MediaContentReferences_Mutation_Response = {
  __typename?: "MediaContentReferences_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<MediaContentReferences>;
};

/** input type for inserting object relation for remote table "MediaContentReferences" */
export type MediaContentReferences_Obj_Rel_Insert_Input = {
  data: MediaContentReferences_Insert_Input;
  on_conflict?: Maybe<MediaContentReferences_On_Conflict>;
};

/** on conflict condition type for table "MediaContentReferences" */
export type MediaContentReferences_On_Conflict = {
  constraint: MediaContentReferences_Constraint;
  update_columns: Array<MediaContentReferences_Update_Column>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** ordering options when selecting data from "MediaContentReferences" */
export type MediaContentReferences_Order_By = {
  MediaContent?: Maybe<MediaContent_Order_By>;
  id?: Maybe<Order_By>;
  jwId?: Maybe<Order_By>;
  lastAccessed?: Maybe<Order_By>;
  reelgoodId?: Maybe<Order_By>;
  reelgoodLink?: Maybe<Order_By>;
  tmdbId?: Maybe<Order_By>;
};

/** primary key columns input for table: "MediaContentReferences" */
export type MediaContentReferences_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "MediaContentReferences" */
export enum MediaContentReferences_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  JwId = "jwId",
  /** column name */
  LastAccessed = "lastAccessed",
  /** column name */
  ReelgoodId = "reelgoodId",
  /** column name */
  ReelgoodLink = "reelgoodLink",
  /** column name */
  TmdbId = "tmdbId",
}

/** input type for updating data in table "MediaContentReferences" */
export type MediaContentReferences_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  jwId?: Maybe<Scalars["numeric"]>;
  lastAccessed?: Maybe<Scalars["timestamp"]>;
  reelgoodId?: Maybe<Scalars["String"]>;
  reelgoodLink?: Maybe<Scalars["String"]>;
  tmdbId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type MediaContentReferences_Stddev_Fields = {
  __typename?: "MediaContentReferences_stddev_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Stddev_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MediaContentReferences_Stddev_Pop_Fields = {
  __typename?: "MediaContentReferences_stddev_pop_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Stddev_Pop_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MediaContentReferences_Stddev_Samp_Fields = {
  __typename?: "MediaContentReferences_stddev_samp_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Stddev_Samp_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type MediaContentReferences_Sum_Fields = {
  __typename?: "MediaContentReferences_sum_fields";
  jwId?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Sum_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** update columns of table "MediaContentReferences" */
export enum MediaContentReferences_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  JwId = "jwId",
  /** column name */
  LastAccessed = "lastAccessed",
  /** column name */
  ReelgoodId = "reelgoodId",
  /** column name */
  ReelgoodLink = "reelgoodLink",
  /** column name */
  TmdbId = "tmdbId",
}

/** aggregate var_pop on columns */
export type MediaContentReferences_Var_Pop_Fields = {
  __typename?: "MediaContentReferences_var_pop_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Var_Pop_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MediaContentReferences_Var_Samp_Fields = {
  __typename?: "MediaContentReferences_var_samp_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Var_Samp_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type MediaContentReferences_Variance_Fields = {
  __typename?: "MediaContentReferences_variance_fields";
  jwId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "MediaContentReferences" */
export type MediaContentReferences_Variance_Order_By = {
  jwId?: Maybe<Order_By>;
};

/** aggregated selection of "MediaContent" */
export type MediaContent_Aggregate = {
  __typename?: "MediaContent_aggregate";
  aggregate?: Maybe<MediaContent_Aggregate_Fields>;
  nodes: Array<MediaContent>;
};

/** aggregate fields of "MediaContent" */
export type MediaContent_Aggregate_Fields = {
  __typename?: "MediaContent_aggregate_fields";
  avg?: Maybe<MediaContent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<MediaContent_Max_Fields>;
  min?: Maybe<MediaContent_Min_Fields>;
  stddev?: Maybe<MediaContent_Stddev_Fields>;
  stddev_pop?: Maybe<MediaContent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MediaContent_Stddev_Samp_Fields>;
  sum?: Maybe<MediaContent_Sum_Fields>;
  var_pop?: Maybe<MediaContent_Var_Pop_Fields>;
  var_samp?: Maybe<MediaContent_Var_Samp_Fields>;
  variance?: Maybe<MediaContent_Variance_Fields>;
};

/** aggregate fields of "MediaContent" */
export type MediaContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<MediaContent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "MediaContent" */
export type MediaContent_Aggregate_Order_By = {
  avg?: Maybe<MediaContent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MediaContent_Max_Order_By>;
  min?: Maybe<MediaContent_Min_Order_By>;
  stddev?: Maybe<MediaContent_Stddev_Order_By>;
  stddev_pop?: Maybe<MediaContent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MediaContent_Stddev_Samp_Order_By>;
  sum?: Maybe<MediaContent_Sum_Order_By>;
  var_pop?: Maybe<MediaContent_Var_Pop_Order_By>;
  var_samp?: Maybe<MediaContent_Var_Samp_Order_By>;
  variance?: Maybe<MediaContent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "MediaContent" */
export type MediaContent_Arr_Rel_Insert_Input = {
  data: Array<MediaContent_Insert_Input>;
  on_conflict?: Maybe<MediaContent_On_Conflict>;
};

/** aggregate avg on columns */
export type MediaContent_Avg_Fields = {
  __typename?: "MediaContent_avg_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "MediaContent" */
export type MediaContent_Avg_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MediaContent". All fields are combined with a logical 'AND'. */
export type MediaContent_Bool_Exp = {
  Episodes?: Maybe<MediaContent_Bool_Exp>;
  ExternalToInternalContents?: Maybe<ExternalToInternalContent_Bool_Exp>;
  GenreMediaContents?: Maybe<GenreMediaContent_Bool_Exp>;
  MediaContentI18Ns?: Maybe<MediaContentI18N_Bool_Exp>;
  MediaContentReferences?: Maybe<MediaContentReferences_Bool_Exp>;
  MediaType?: Maybe<MediaType_Bool_Exp>;
  Show?: Maybe<MediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<MediaContent_Bool_Exp>>>;
  _not?: Maybe<MediaContent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<MediaContent_Bool_Exp>>>;
  backdropURL?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  episodeNumber?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  overview?: Maybe<String_Comparison_Exp>;
  posterURL?: Maybe<String_Comparison_Exp>;
  releasedOn?: Maybe<Date_Comparison_Exp>;
  seasonNumber?: Maybe<Int_Comparison_Exp>;
  showId?: Maybe<Uuid_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  stillURL?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  totalEpisodes?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "MediaContent" */
export enum MediaContent_Constraint {
  /** unique or primary key constraint */
  MediaContentPkey = "MediaContent_pkey",
  /** unique or primary key constraint */
  MediaContentSeasonNumberEpisodeNumberShowIdKey = "MediaContent_seasonNumber_episodeNumber_showId_key",
  /** unique or primary key constraint */
  MediaContentSlugKey = "MediaContent_slug_key",
}

/** input type for incrementing integer column in table "MediaContent" */
export type MediaContent_Inc_Input = {
  episodeNumber?: Maybe<Scalars["Int"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  totalEpisodes?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "MediaContent" */
export type MediaContent_Insert_Input = {
  Episodes?: Maybe<MediaContent_Arr_Rel_Insert_Input>;
  ExternalToInternalContents?: Maybe<ExternalToInternalContent_Arr_Rel_Insert_Input>;
  GenreMediaContents?: Maybe<GenreMediaContent_Arr_Rel_Insert_Input>;
  MediaContentI18Ns?: Maybe<MediaContentI18N_Arr_Rel_Insert_Input>;
  MediaContentReferences?: Maybe<MediaContentReferences_Arr_Rel_Insert_Input>;
  MediaType?: Maybe<MediaType_Obj_Rel_Insert_Input>;
  Show?: Maybe<MediaContent_Obj_Rel_Insert_Input>;
  backdropURL?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  overview?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["String"]>;
  releasedOn?: Maybe<Scalars["date"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  showId?: Maybe<Scalars["uuid"]>;
  slug?: Maybe<Scalars["String"]>;
  stillURL?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  totalEpisodes?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type MediaContent_Max_Fields = {
  __typename?: "MediaContent_max_fields";
  backdropURL?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  overview?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["String"]>;
  releasedOn?: Maybe<Scalars["date"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  showId?: Maybe<Scalars["uuid"]>;
  slug?: Maybe<Scalars["String"]>;
  stillURL?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  totalEpisodes?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "MediaContent" */
export type MediaContent_Max_Order_By = {
  backdropURL?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  episodeNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  posterURL?: Maybe<Order_By>;
  releasedOn?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  showId?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  stillURL?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MediaContent_Min_Fields = {
  __typename?: "MediaContent_min_fields";
  backdropURL?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  overview?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["String"]>;
  releasedOn?: Maybe<Scalars["date"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  showId?: Maybe<Scalars["uuid"]>;
  slug?: Maybe<Scalars["String"]>;
  stillURL?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  totalEpisodes?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "MediaContent" */
export type MediaContent_Min_Order_By = {
  backdropURL?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  episodeNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  posterURL?: Maybe<Order_By>;
  releasedOn?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  showId?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  stillURL?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "MediaContent" */
export type MediaContent_Mutation_Response = {
  __typename?: "MediaContent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<MediaContent>;
};

/** input type for inserting object relation for remote table "MediaContent" */
export type MediaContent_Obj_Rel_Insert_Input = {
  data: MediaContent_Insert_Input;
  on_conflict?: Maybe<MediaContent_On_Conflict>;
};

/** on conflict condition type for table "MediaContent" */
export type MediaContent_On_Conflict = {
  constraint: MediaContent_Constraint;
  update_columns: Array<MediaContent_Update_Column>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** ordering options when selecting data from "MediaContent" */
export type MediaContent_Order_By = {
  Episodes_aggregate?: Maybe<MediaContent_Aggregate_Order_By>;
  ExternalToInternalContents_aggregate?: Maybe<ExternalToInternalContent_Aggregate_Order_By>;
  GenreMediaContents_aggregate?: Maybe<GenreMediaContent_Aggregate_Order_By>;
  MediaContentI18Ns_aggregate?: Maybe<MediaContentI18N_Aggregate_Order_By>;
  MediaContentReferences_aggregate?: Maybe<MediaContentReferences_Aggregate_Order_By>;
  MediaType?: Maybe<MediaType_Order_By>;
  Show?: Maybe<MediaContent_Order_By>;
  backdropURL?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  episodeNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  overview?: Maybe<Order_By>;
  posterURL?: Maybe<Order_By>;
  releasedOn?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  showId?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  stillURL?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "MediaContent" */
export type MediaContent_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "MediaContent" */
export enum MediaContent_Select_Column {
  /** column name */
  BackdropUrl = "backdropURL",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  EpisodeNumber = "episodeNumber",
  /** column name */
  Id = "id",
  /** column name */
  Overview = "overview",
  /** column name */
  PosterUrl = "posterURL",
  /** column name */
  ReleasedOn = "releasedOn",
  /** column name */
  SeasonNumber = "seasonNumber",
  /** column name */
  ShowId = "showId",
  /** column name */
  Slug = "slug",
  /** column name */
  StillUrl = "stillURL",
  /** column name */
  Title = "title",
  /** column name */
  TotalEpisodes = "totalEpisodes",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "MediaContent" */
export type MediaContent_Set_Input = {
  backdropURL?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  overview?: Maybe<Scalars["String"]>;
  posterURL?: Maybe<Scalars["String"]>;
  releasedOn?: Maybe<Scalars["date"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  showId?: Maybe<Scalars["uuid"]>;
  slug?: Maybe<Scalars["String"]>;
  stillURL?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  totalEpisodes?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type MediaContent_Stddev_Fields = {
  __typename?: "MediaContent_stddev_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "MediaContent" */
export type MediaContent_Stddev_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MediaContent_Stddev_Pop_Fields = {
  __typename?: "MediaContent_stddev_pop_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "MediaContent" */
export type MediaContent_Stddev_Pop_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MediaContent_Stddev_Samp_Fields = {
  __typename?: "MediaContent_stddev_samp_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "MediaContent" */
export type MediaContent_Stddev_Samp_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type MediaContent_Sum_Fields = {
  __typename?: "MediaContent_sum_fields";
  episodeNumber?: Maybe<Scalars["Int"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  totalEpisodes?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "MediaContent" */
export type MediaContent_Sum_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** update columns of table "MediaContent" */
export enum MediaContent_Update_Column {
  /** column name */
  BackdropUrl = "backdropURL",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  EpisodeNumber = "episodeNumber",
  /** column name */
  Id = "id",
  /** column name */
  Overview = "overview",
  /** column name */
  PosterUrl = "posterURL",
  /** column name */
  ReleasedOn = "releasedOn",
  /** column name */
  SeasonNumber = "seasonNumber",
  /** column name */
  ShowId = "showId",
  /** column name */
  Slug = "slug",
  /** column name */
  StillUrl = "stillURL",
  /** column name */
  Title = "title",
  /** column name */
  TotalEpisodes = "totalEpisodes",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** aggregate var_pop on columns */
export type MediaContent_Var_Pop_Fields = {
  __typename?: "MediaContent_var_pop_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "MediaContent" */
export type MediaContent_Var_Pop_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MediaContent_Var_Samp_Fields = {
  __typename?: "MediaContent_var_samp_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "MediaContent" */
export type MediaContent_Var_Samp_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type MediaContent_Variance_Fields = {
  __typename?: "MediaContent_variance_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
  totalEpisodes?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "MediaContent" */
export type MediaContent_Variance_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  totalEpisodes?: Maybe<Order_By>;
};

/** columns and relationships of "MediaType" */
export type MediaType = {
  __typename?: "MediaType";
  /** An array relationship */
  MediaContents: Array<MediaContent>;
  /** An aggregated array relationship */
  MediaContents_aggregate: MediaContent_Aggregate;
  id: Scalars["String"];
  name: Scalars["String"];
};

/** columns and relationships of "MediaType" */
export type MediaTypeMediaContentsArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** columns and relationships of "MediaType" */
export type MediaTypeMediaContents_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** aggregated selection of "MediaType" */
export type MediaType_Aggregate = {
  __typename?: "MediaType_aggregate";
  aggregate?: Maybe<MediaType_Aggregate_Fields>;
  nodes: Array<MediaType>;
};

/** aggregate fields of "MediaType" */
export type MediaType_Aggregate_Fields = {
  __typename?: "MediaType_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<MediaType_Max_Fields>;
  min?: Maybe<MediaType_Min_Fields>;
};

/** aggregate fields of "MediaType" */
export type MediaType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<MediaType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "MediaType" */
export type MediaType_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<MediaType_Max_Order_By>;
  min?: Maybe<MediaType_Min_Order_By>;
};

/** input type for inserting array relation for remote table "MediaType" */
export type MediaType_Arr_Rel_Insert_Input = {
  data: Array<MediaType_Insert_Input>;
  on_conflict?: Maybe<MediaType_On_Conflict>;
};

/** Boolean expression to filter rows from the table "MediaType". All fields are combined with a logical 'AND'. */
export type MediaType_Bool_Exp = {
  MediaContents?: Maybe<MediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<MediaType_Bool_Exp>>>;
  _not?: Maybe<MediaType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<MediaType_Bool_Exp>>>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "MediaType" */
export enum MediaType_Constraint {
  /** unique or primary key constraint */
  MediaTypePkey = "MediaType_pkey",
}

/** input type for inserting data into table "MediaType" */
export type MediaType_Insert_Input = {
  MediaContents?: Maybe<MediaContent_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type MediaType_Max_Fields = {
  __typename?: "MediaType_max_fields";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "MediaType" */
export type MediaType_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MediaType_Min_Fields = {
  __typename?: "MediaType_min_fields";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "MediaType" */
export type MediaType_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "MediaType" */
export type MediaType_Mutation_Response = {
  __typename?: "MediaType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<MediaType>;
};

/** input type for inserting object relation for remote table "MediaType" */
export type MediaType_Obj_Rel_Insert_Input = {
  data: MediaType_Insert_Input;
  on_conflict?: Maybe<MediaType_On_Conflict>;
};

/** on conflict condition type for table "MediaType" */
export type MediaType_On_Conflict = {
  constraint: MediaType_Constraint;
  update_columns: Array<MediaType_Update_Column>;
  where?: Maybe<MediaType_Bool_Exp>;
};

/** ordering options when selecting data from "MediaType" */
export type MediaType_Order_By = {
  MediaContents_aggregate?: Maybe<MediaContent_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "MediaType" */
export type MediaType_Pk_Columns_Input = {
  id: Scalars["String"];
};

/** select columns of table "MediaType" */
export enum MediaType_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "MediaType" */
export type MediaType_Set_Input = {
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** update columns of table "MediaType" */
export enum MediaType_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** columns and relationships of "Message" */
export type Message = {
  __typename?: "Message";
  /** An object relationship */
  Session: Session;
  /** An object relationship */
  User: User;
  createdAt: Scalars["timestamp"];
  id: Scalars["uuid"];
  message: Scalars["String"];
  senderId: Scalars["String"];
  sessionId: Scalars["uuid"];
};

/** aggregated selection of "Message" */
export type Message_Aggregate = {
  __typename?: "Message_aggregate";
  aggregate?: Maybe<Message_Aggregate_Fields>;
  nodes: Array<Message>;
};

/** aggregate fields of "Message" */
export type Message_Aggregate_Fields = {
  __typename?: "Message_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Message_Max_Fields>;
  min?: Maybe<Message_Min_Fields>;
};

/** aggregate fields of "Message" */
export type Message_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Message" */
export type Message_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Max_Order_By>;
  min?: Maybe<Message_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Message" */
export type Message_Arr_Rel_Insert_Input = {
  data: Array<Message_Insert_Input>;
  on_conflict?: Maybe<Message_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Message". All fields are combined with a logical 'AND'. */
export type Message_Bool_Exp = {
  Session?: Maybe<Session_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Message_Bool_Exp>>>;
  _not?: Maybe<Message_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Message_Bool_Exp>>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  senderId?: Maybe<String_Comparison_Exp>;
  sessionId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Message" */
export enum Message_Constraint {
  /** unique or primary key constraint */
  MessagePkey = "Message_pkey",
}

/** input type for inserting data into table "Message" */
export type Message_Insert_Input = {
  Session?: Maybe<Session_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  senderId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Message_Max_Fields = {
  __typename?: "Message_max_fields";
  createdAt?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  senderId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Message" */
export type Message_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  senderId?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Min_Fields = {
  __typename?: "Message_min_fields";
  createdAt?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  senderId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Message" */
export type Message_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  senderId?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Message" */
export type Message_Mutation_Response = {
  __typename?: "Message_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Message>;
};

/** input type for inserting object relation for remote table "Message" */
export type Message_Obj_Rel_Insert_Input = {
  data: Message_Insert_Input;
  on_conflict?: Maybe<Message_On_Conflict>;
};

/** on conflict condition type for table "Message" */
export type Message_On_Conflict = {
  constraint: Message_Constraint;
  update_columns: Array<Message_Update_Column>;
  where?: Maybe<Message_Bool_Exp>;
};

/** ordering options when selecting data from "Message" */
export type Message_Order_By = {
  Session?: Maybe<Session_Order_By>;
  User?: Maybe<User_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  senderId?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** primary key columns input for table: "Message" */
export type Message_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Message" */
export enum Message_Select_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  SenderId = "senderId",
  /** column name */
  SessionId = "sessionId",
}

/** input type for updating data in table "Message" */
export type Message_Set_Input = {
  createdAt?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["uuid"]>;
  message?: Maybe<Scalars["String"]>;
  senderId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "Message" */
export enum Message_Update_Column {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  Message = "message",
  /** column name */
  SenderId = "senderId",
  /** column name */
  SessionId = "sessionId",
}

/** columns and relationships of "MostWatchedContent" */
export type MostWatchedContent = {
  __typename?: "MostWatchedContent";
  /** An object relationship */
  MediaContent?: Maybe<MediaContent>;
  count?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "MostWatchedContent" */
export type MostWatchedContent_Aggregate = {
  __typename?: "MostWatchedContent_aggregate";
  aggregate?: Maybe<MostWatchedContent_Aggregate_Fields>;
  nodes: Array<MostWatchedContent>;
};

/** aggregate fields of "MostWatchedContent" */
export type MostWatchedContent_Aggregate_Fields = {
  __typename?: "MostWatchedContent_aggregate_fields";
  avg?: Maybe<MostWatchedContent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<MostWatchedContent_Max_Fields>;
  min?: Maybe<MostWatchedContent_Min_Fields>;
  stddev?: Maybe<MostWatchedContent_Stddev_Fields>;
  stddev_pop?: Maybe<MostWatchedContent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MostWatchedContent_Stddev_Samp_Fields>;
  sum?: Maybe<MostWatchedContent_Sum_Fields>;
  var_pop?: Maybe<MostWatchedContent_Var_Pop_Fields>;
  var_samp?: Maybe<MostWatchedContent_Var_Samp_Fields>;
  variance?: Maybe<MostWatchedContent_Variance_Fields>;
};

/** aggregate fields of "MostWatchedContent" */
export type MostWatchedContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<MostWatchedContent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "MostWatchedContent" */
export type MostWatchedContent_Aggregate_Order_By = {
  avg?: Maybe<MostWatchedContent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MostWatchedContent_Max_Order_By>;
  min?: Maybe<MostWatchedContent_Min_Order_By>;
  stddev?: Maybe<MostWatchedContent_Stddev_Order_By>;
  stddev_pop?: Maybe<MostWatchedContent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MostWatchedContent_Stddev_Samp_Order_By>;
  sum?: Maybe<MostWatchedContent_Sum_Order_By>;
  var_pop?: Maybe<MostWatchedContent_Var_Pop_Order_By>;
  var_samp?: Maybe<MostWatchedContent_Var_Samp_Order_By>;
  variance?: Maybe<MostWatchedContent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "MostWatchedContent" */
export type MostWatchedContent_Arr_Rel_Insert_Input = {
  data: Array<MostWatchedContent_Insert_Input>;
};

/** aggregate avg on columns */
export type MostWatchedContent_Avg_Fields = {
  __typename?: "MostWatchedContent_avg_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "MostWatchedContent". All fields are combined with a logical 'AND'. */
export type MostWatchedContent_Bool_Exp = {
  MediaContent?: Maybe<MediaContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<MostWatchedContent_Bool_Exp>>>;
  _not?: Maybe<MostWatchedContent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<MostWatchedContent_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing integer column in table "MostWatchedContent" */
export type MostWatchedContent_Inc_Input = {
  count?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "MostWatchedContent" */
export type MostWatchedContent_Insert_Input = {
  MediaContent?: Maybe<MediaContent_Obj_Rel_Insert_Input>;
  count?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type MostWatchedContent_Max_Fields = {
  __typename?: "MostWatchedContent_max_fields";
  count?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Max_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type MostWatchedContent_Min_Fields = {
  __typename?: "MostWatchedContent_min_fields";
  count?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Min_Order_By = {
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "MostWatchedContent" */
export type MostWatchedContent_Mutation_Response = {
  __typename?: "MostWatchedContent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<MostWatchedContent>;
};

/** input type for inserting object relation for remote table "MostWatchedContent" */
export type MostWatchedContent_Obj_Rel_Insert_Input = {
  data: MostWatchedContent_Insert_Input;
};

/** ordering options when selecting data from "MostWatchedContent" */
export type MostWatchedContent_Order_By = {
  MediaContent?: Maybe<MediaContent_Order_By>;
  count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "MostWatchedContent" */
export enum MostWatchedContent_Select_Column {
  /** column name */
  Count = "count",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "MostWatchedContent" */
export type MostWatchedContent_Set_Input = {
  count?: Maybe<Scalars["bigint"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type MostWatchedContent_Stddev_Fields = {
  __typename?: "MostWatchedContent_stddev_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MostWatchedContent_Stddev_Pop_Fields = {
  __typename?: "MostWatchedContent_stddev_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MostWatchedContent_Stddev_Samp_Fields = {
  __typename?: "MostWatchedContent_stddev_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type MostWatchedContent_Sum_Fields = {
  __typename?: "MostWatchedContent_sum_fields";
  count?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type MostWatchedContent_Var_Pop_Fields = {
  __typename?: "MostWatchedContent_var_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MostWatchedContent_Var_Samp_Fields = {
  __typename?: "MostWatchedContent_var_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type MostWatchedContent_Variance_Fields = {
  __typename?: "MostWatchedContent_variance_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "MostWatchedContent" */
export type MostWatchedContent_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "Participants" */
export type Participants = {
  __typename?: "Participants";
  count?: Maybe<Scalars["bigint"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "Participants" */
export type Participants_Aggregate = {
  __typename?: "Participants_aggregate";
  aggregate?: Maybe<Participants_Aggregate_Fields>;
  nodes: Array<Participants>;
};

/** aggregate fields of "Participants" */
export type Participants_Aggregate_Fields = {
  __typename?: "Participants_aggregate_fields";
  avg?: Maybe<Participants_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Participants_Max_Fields>;
  min?: Maybe<Participants_Min_Fields>;
  stddev?: Maybe<Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Participants_Sum_Fields>;
  var_pop?: Maybe<Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Participants_Var_Samp_Fields>;
  variance?: Maybe<Participants_Variance_Fields>;
};

/** aggregate fields of "Participants" */
export type Participants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Participants_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Participants" */
export type Participants_Aggregate_Order_By = {
  avg?: Maybe<Participants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Participants_Max_Order_By>;
  min?: Maybe<Participants_Min_Order_By>;
  stddev?: Maybe<Participants_Stddev_Order_By>;
  stddev_pop?: Maybe<Participants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Participants_Stddev_Samp_Order_By>;
  sum?: Maybe<Participants_Sum_Order_By>;
  var_pop?: Maybe<Participants_Var_Pop_Order_By>;
  var_samp?: Maybe<Participants_Var_Samp_Order_By>;
  variance?: Maybe<Participants_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Participants_Avg_Fields = {
  __typename?: "Participants_avg_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Participants" */
export type Participants_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Participants". All fields are combined with a logical 'AND'. */
export type Participants_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Participants_Bool_Exp>>>;
  _not?: Maybe<Participants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Participants_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  sessionId?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Participants_Max_Fields = {
  __typename?: "Participants_max_fields";
  count?: Maybe<Scalars["bigint"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "Participants" */
export type Participants_Max_Order_By = {
  count?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Participants_Min_Fields = {
  __typename?: "Participants_min_fields";
  count?: Maybe<Scalars["bigint"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "Participants" */
export type Participants_Min_Order_By = {
  count?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** ordering options when selecting data from "Participants" */
export type Participants_Order_By = {
  count?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** select columns of table "Participants" */
export enum Participants_Select_Column {
  /** column name */
  Count = "count",
  /** column name */
  SessionId = "sessionId",
}

/** aggregate stddev on columns */
export type Participants_Stddev_Fields = {
  __typename?: "Participants_stddev_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Participants" */
export type Participants_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Participants_Stddev_Pop_Fields = {
  __typename?: "Participants_stddev_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Participants" */
export type Participants_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Participants_Stddev_Samp_Fields = {
  __typename?: "Participants_stddev_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Participants" */
export type Participants_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Participants_Sum_Fields = {
  __typename?: "Participants_sum_fields";
  count?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "Participants" */
export type Participants_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Participants_Var_Pop_Fields = {
  __typename?: "Participants_var_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Participants" */
export type Participants_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Participants_Var_Samp_Fields = {
  __typename?: "Participants_var_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Participants" */
export type Participants_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Participants_Variance_Fields = {
  __typename?: "Participants_variance_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Participants" */
export type Participants_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "PublicParties" */
export type PublicParties = {
  __typename?: "PublicParties";
  /** An object relationship */
  ExternalContent?: Maybe<ExternalToInternalContent>;
  /** An object relationship */
  Owner?: Maybe<UserPublic>;
  /** An object relationship */
  Participants?: Maybe<Participants>;
  controlLockEnabled?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  public?: Maybe<Scalars["Boolean"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoEnabled?: Maybe<Scalars["Boolean"]>;
};

/** aggregated selection of "PublicParties" */
export type PublicParties_Aggregate = {
  __typename?: "PublicParties_aggregate";
  aggregate?: Maybe<PublicParties_Aggregate_Fields>;
  nodes: Array<PublicParties>;
};

/** aggregate fields of "PublicParties" */
export type PublicParties_Aggregate_Fields = {
  __typename?: "PublicParties_aggregate_fields";
  avg?: Maybe<PublicParties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<PublicParties_Max_Fields>;
  min?: Maybe<PublicParties_Min_Fields>;
  stddev?: Maybe<PublicParties_Stddev_Fields>;
  stddev_pop?: Maybe<PublicParties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PublicParties_Stddev_Samp_Fields>;
  sum?: Maybe<PublicParties_Sum_Fields>;
  var_pop?: Maybe<PublicParties_Var_Pop_Fields>;
  var_samp?: Maybe<PublicParties_Var_Samp_Fields>;
  variance?: Maybe<PublicParties_Variance_Fields>;
};

/** aggregate fields of "PublicParties" */
export type PublicParties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PublicParties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "PublicParties" */
export type PublicParties_Aggregate_Order_By = {
  avg?: Maybe<PublicParties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PublicParties_Max_Order_By>;
  min?: Maybe<PublicParties_Min_Order_By>;
  stddev?: Maybe<PublicParties_Stddev_Order_By>;
  stddev_pop?: Maybe<PublicParties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PublicParties_Stddev_Samp_Order_By>;
  sum?: Maybe<PublicParties_Sum_Order_By>;
  var_pop?: Maybe<PublicParties_Var_Pop_Order_By>;
  var_samp?: Maybe<PublicParties_Var_Samp_Order_By>;
  variance?: Maybe<PublicParties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PublicParties" */
export type PublicParties_Arr_Rel_Insert_Input = {
  data: Array<PublicParties_Insert_Input>;
};

/** aggregate avg on columns */
export type PublicParties_Avg_Fields = {
  __typename?: "PublicParties_avg_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "PublicParties" */
export type PublicParties_Avg_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PublicParties". All fields are combined with a logical 'AND'. */
export type PublicParties_Bool_Exp = {
  ExternalContent?: Maybe<ExternalToInternalContent_Bool_Exp>;
  Owner?: Maybe<UserPublic_Bool_Exp>;
  Participants?: Maybe<Participants_Bool_Exp>;
  _and?: Maybe<Array<Maybe<PublicParties_Bool_Exp>>>;
  _not?: Maybe<PublicParties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<PublicParties_Bool_Exp>>>;
  controlLockEnabled?: Maybe<Boolean_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  currentControlUser?: Maybe<String_Comparison_Exp>;
  endTime?: Maybe<Timestamp_Comparison_Exp>;
  host?: Maybe<String_Comparison_Exp>;
  hostContentId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastKnownTime?: Maybe<Float8_Comparison_Exp>;
  lastKnownTimeUpdatedAt?: Maybe<Timestamp_Comparison_Exp>;
  maxParticipants?: Maybe<Int_Comparison_Exp>;
  ownerId?: Maybe<String_Comparison_Exp>;
  ownerLeftTime?: Maybe<Timestamp_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  startTime?: Maybe<Timestamp_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  videoEnabled?: Maybe<Boolean_Comparison_Exp>;
};

/** input type for incrementing integer column in table "PublicParties" */
export type PublicParties_Inc_Input = {
  lastKnownTime?: Maybe<Scalars["float8"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "PublicParties" */
export type PublicParties_Insert_Input = {
  ExternalContent?: Maybe<ExternalToInternalContent_Obj_Rel_Insert_Input>;
  Owner?: Maybe<UserPublic_Obj_Rel_Insert_Input>;
  controlLockEnabled?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  public?: Maybe<Scalars["Boolean"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoEnabled?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type PublicParties_Max_Fields = {
  __typename?: "PublicParties_max_fields";
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "PublicParties" */
export type PublicParties_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  currentControlUser?: Maybe<Order_By>;
  endTime?: Maybe<Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastKnownTime?: Maybe<Order_By>;
  lastKnownTimeUpdatedAt?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  ownerLeftTime?: Maybe<Order_By>;
  startTime?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type PublicParties_Min_Fields = {
  __typename?: "PublicParties_min_fields";
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "PublicParties" */
export type PublicParties_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  currentControlUser?: Maybe<Order_By>;
  endTime?: Maybe<Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastKnownTime?: Maybe<Order_By>;
  lastKnownTimeUpdatedAt?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  ownerLeftTime?: Maybe<Order_By>;
  startTime?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "PublicParties" */
export type PublicParties_Mutation_Response = {
  __typename?: "PublicParties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<PublicParties>;
};

/** input type for inserting object relation for remote table "PublicParties" */
export type PublicParties_Obj_Rel_Insert_Input = {
  data: PublicParties_Insert_Input;
};

/** ordering options when selecting data from "PublicParties" */
export type PublicParties_Order_By = {
  ExternalContent?: Maybe<ExternalToInternalContent_Order_By>;
  Owner?: Maybe<UserPublic_Order_By>;
  Participants?: Maybe<Participants_Order_By>;
  controlLockEnabled?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  currentControlUser?: Maybe<Order_By>;
  endTime?: Maybe<Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastKnownTime?: Maybe<Order_By>;
  lastKnownTimeUpdatedAt?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  ownerLeftTime?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  startTime?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  videoEnabled?: Maybe<Order_By>;
};

/** select columns of table "PublicParties" */
export enum PublicParties_Select_Column {
  /** column name */
  ControlLockEnabled = "controlLockEnabled",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  CurrentControlUser = "currentControlUser",
  /** column name */
  EndTime = "endTime",
  /** column name */
  Host = "host",
  /** column name */
  HostContentId = "hostContentId",
  /** column name */
  Id = "id",
  /** column name */
  LastKnownTime = "lastKnownTime",
  /** column name */
  LastKnownTimeUpdatedAt = "lastKnownTimeUpdatedAt",
  /** column name */
  MaxParticipants = "maxParticipants",
  /** column name */
  OwnerId = "ownerId",
  /** column name */
  OwnerLeftTime = "ownerLeftTime",
  /** column name */
  Public = "public",
  /** column name */
  StartTime = "startTime",
  /** column name */
  State = "state",
  /** column name */
  Title = "title",
  /** column name */
  Url = "url",
  /** column name */
  VideoEnabled = "videoEnabled",
}

/** input type for updating data in table "PublicParties" */
export type PublicParties_Set_Input = {
  controlLockEnabled?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  public?: Maybe<Scalars["Boolean"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoEnabled?: Maybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type PublicParties_Stddev_Fields = {
  __typename?: "PublicParties_stddev_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "PublicParties" */
export type PublicParties_Stddev_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PublicParties_Stddev_Pop_Fields = {
  __typename?: "PublicParties_stddev_pop_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "PublicParties" */
export type PublicParties_Stddev_Pop_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PublicParties_Stddev_Samp_Fields = {
  __typename?: "PublicParties_stddev_samp_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "PublicParties" */
export type PublicParties_Stddev_Samp_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type PublicParties_Sum_Fields = {
  __typename?: "PublicParties_sum_fields";
  lastKnownTime?: Maybe<Scalars["float8"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "PublicParties" */
export type PublicParties_Sum_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type PublicParties_Var_Pop_Fields = {
  __typename?: "PublicParties_var_pop_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "PublicParties" */
export type PublicParties_Var_Pop_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PublicParties_Var_Samp_Fields = {
  __typename?: "PublicParties_var_samp_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "PublicParties" */
export type PublicParties_Var_Samp_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type PublicParties_Variance_Fields = {
  __typename?: "PublicParties_variance_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "PublicParties" */
export type PublicParties_Variance_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
};

/** columns and relationships of "Session" */
export type Session = {
  __typename?: "Session";
  /** An object relationship */
  ExternalToInternalContent?: Maybe<ExternalToInternalContent>;
  /** An array relationship */
  Messages: Array<Message>;
  /** An aggregated array relationship */
  Messages_aggregate: Message_Aggregate;
  /** An array relationship */
  SessionParticipations: Array<SessionParticipation>;
  /** An aggregated array relationship */
  SessionParticipations_aggregate: SessionParticipation_Aggregate;
  /** An object relationship */
  User: User;
  /** An object relationship */
  VideoChatServer?: Maybe<VideoChatServer>;
  controlLockEnabled: Scalars["Boolean"];
  createdAt: Scalars["timestamp"];
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host: Scalars["String"];
  hostContentId: Scalars["String"];
  id: Scalars["uuid"];
  lastKnownTime: Scalars["float8"];
  lastKnownTimeUpdatedAt: Scalars["timestamp"];
  maxParticipants: Scalars["Int"];
  ownerId: Scalars["String"];
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  public: Scalars["Boolean"];
  startTime: Scalars["timestamp"];
  state: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  videoChatServerId?: Maybe<Scalars["Int"]>;
  videoEnabled: Scalars["Boolean"];
};

/** columns and relationships of "Session" */
export type SessionMessagesArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** columns and relationships of "Session" */
export type SessionMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** columns and relationships of "Session" */
export type SessionSessionParticipationsArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** columns and relationships of "Session" */
export type SessionSessionParticipations_AggregateArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** columns and relationships of "SessionParticipation" */
export type SessionParticipation = {
  __typename?: "SessionParticipation";
  /** An object relationship */
  Session: Session;
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserPublic?: Maybe<UserPublic>;
  active: Scalars["Boolean"];
  created_at: Scalars["timestamptz"];
  sessionId: Scalars["uuid"];
  userId: Scalars["String"];
};

/** aggregated selection of "SessionParticipation" */
export type SessionParticipation_Aggregate = {
  __typename?: "SessionParticipation_aggregate";
  aggregate?: Maybe<SessionParticipation_Aggregate_Fields>;
  nodes: Array<SessionParticipation>;
};

/** aggregate fields of "SessionParticipation" */
export type SessionParticipation_Aggregate_Fields = {
  __typename?: "SessionParticipation_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<SessionParticipation_Max_Fields>;
  min?: Maybe<SessionParticipation_Min_Fields>;
};

/** aggregate fields of "SessionParticipation" */
export type SessionParticipation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SessionParticipation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "SessionParticipation" */
export type SessionParticipation_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SessionParticipation_Max_Order_By>;
  min?: Maybe<SessionParticipation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SessionParticipation" */
export type SessionParticipation_Arr_Rel_Insert_Input = {
  data: Array<SessionParticipation_Insert_Input>;
  on_conflict?: Maybe<SessionParticipation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SessionParticipation". All fields are combined with a logical 'AND'. */
export type SessionParticipation_Bool_Exp = {
  Session?: Maybe<Session_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  UserPublic?: Maybe<UserPublic_Bool_Exp>;
  _and?: Maybe<Array<Maybe<SessionParticipation_Bool_Exp>>>;
  _not?: Maybe<SessionParticipation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SessionParticipation_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  sessionId?: Maybe<Uuid_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SessionParticipation" */
export enum SessionParticipation_Constraint {
  /** unique or primary key constraint */
  SessionParticipationPkey = "SessionParticipation_pkey",
}

/** input type for inserting data into table "SessionParticipation" */
export type SessionParticipation_Insert_Input = {
  Session?: Maybe<Session_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  UserPublic?: Maybe<UserPublic_Obj_Rel_Insert_Input>;
  active?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type SessionParticipation_Max_Fields = {
  __typename?: "SessionParticipation_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "SessionParticipation" */
export type SessionParticipation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SessionParticipation_Min_Fields = {
  __typename?: "SessionParticipation_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "SessionParticipation" */
export type SessionParticipation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SessionParticipation" */
export type SessionParticipation_Mutation_Response = {
  __typename?: "SessionParticipation_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<SessionParticipation>;
};

/** input type for inserting object relation for remote table "SessionParticipation" */
export type SessionParticipation_Obj_Rel_Insert_Input = {
  data: SessionParticipation_Insert_Input;
  on_conflict?: Maybe<SessionParticipation_On_Conflict>;
};

/** on conflict condition type for table "SessionParticipation" */
export type SessionParticipation_On_Conflict = {
  constraint: SessionParticipation_Constraint;
  update_columns: Array<SessionParticipation_Update_Column>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** ordering options when selecting data from "SessionParticipation" */
export type SessionParticipation_Order_By = {
  Session?: Maybe<Session_Order_By>;
  User?: Maybe<User_Order_By>;
  UserPublic?: Maybe<UserPublic_Order_By>;
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "SessionParticipation" */
export type SessionParticipation_Pk_Columns_Input = {
  sessionId: Scalars["uuid"];
  userId: Scalars["String"];
};

/** select columns of table "SessionParticipation" */
export enum SessionParticipation_Select_Column {
  /** column name */
  Active = "active",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  SessionId = "sessionId",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "SessionParticipation" */
export type SessionParticipation_Set_Input = {
  active?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  sessionId?: Maybe<Scalars["uuid"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** update columns of table "SessionParticipation" */
export enum SessionParticipation_Update_Column {
  /** column name */
  Active = "active",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  SessionId = "sessionId",
  /** column name */
  UserId = "userId",
}

/** aggregated selection of "Session" */
export type Session_Aggregate = {
  __typename?: "Session_aggregate";
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

/** aggregate fields of "Session" */
export type Session_Aggregate_Fields = {
  __typename?: "Session_aggregate_fields";
  avg?: Maybe<Session_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
  stddev?: Maybe<Session_Stddev_Fields>;
  stddev_pop?: Maybe<Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Session_Stddev_Samp_Fields>;
  sum?: Maybe<Session_Sum_Fields>;
  var_pop?: Maybe<Session_Var_Pop_Fields>;
  var_samp?: Maybe<Session_Var_Samp_Fields>;
  variance?: Maybe<Session_Variance_Fields>;
};

/** aggregate fields of "Session" */
export type Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Session_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Session" */
export type Session_Aggregate_Order_By = {
  avg?: Maybe<Session_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Session_Max_Order_By>;
  min?: Maybe<Session_Min_Order_By>;
  stddev?: Maybe<Session_Stddev_Order_By>;
  stddev_pop?: Maybe<Session_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Session_Stddev_Samp_Order_By>;
  sum?: Maybe<Session_Sum_Order_By>;
  var_pop?: Maybe<Session_Var_Pop_Order_By>;
  var_samp?: Maybe<Session_Var_Samp_Order_By>;
  variance?: Maybe<Session_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Session" */
export type Session_Arr_Rel_Insert_Input = {
  data: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** aggregate avg on columns */
export type Session_Avg_Fields = {
  __typename?: "Session_avg_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Session" */
export type Session_Avg_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Session". All fields are combined with a logical 'AND'. */
export type Session_Bool_Exp = {
  ExternalToInternalContent?: Maybe<ExternalToInternalContent_Bool_Exp>;
  Messages?: Maybe<Message_Bool_Exp>;
  SessionParticipations?: Maybe<SessionParticipation_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  VideoChatServer?: Maybe<VideoChatServer_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Session_Bool_Exp>>>;
  _not?: Maybe<Session_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Session_Bool_Exp>>>;
  controlLockEnabled?: Maybe<Boolean_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  currentControlUser?: Maybe<String_Comparison_Exp>;
  endTime?: Maybe<Timestamp_Comparison_Exp>;
  host?: Maybe<String_Comparison_Exp>;
  hostContentId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastKnownTime?: Maybe<Float8_Comparison_Exp>;
  lastKnownTimeUpdatedAt?: Maybe<Timestamp_Comparison_Exp>;
  maxParticipants?: Maybe<Int_Comparison_Exp>;
  ownerId?: Maybe<String_Comparison_Exp>;
  ownerLeftTime?: Maybe<Timestamp_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  startTime?: Maybe<Timestamp_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  videoChatServerId?: Maybe<Int_Comparison_Exp>;
  videoEnabled?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "Session" */
export enum Session_Constraint {
  /** unique or primary key constraint */
  SessionsIdKey = "sessions_id_key",
  /** unique or primary key constraint */
  SessionsPkey = "sessions_pkey",
}

/** input type for incrementing integer column in table "Session" */
export type Session_Inc_Input = {
  lastKnownTime?: Maybe<Scalars["float8"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  videoChatServerId?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Session" */
export type Session_Insert_Input = {
  ExternalToInternalContent?: Maybe<ExternalToInternalContent_Obj_Rel_Insert_Input>;
  Messages?: Maybe<Message_Arr_Rel_Insert_Input>;
  SessionParticipations?: Maybe<SessionParticipation_Arr_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  VideoChatServer?: Maybe<VideoChatServer_Obj_Rel_Insert_Input>;
  controlLockEnabled?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  public?: Maybe<Scalars["Boolean"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoChatServerId?: Maybe<Scalars["Int"]>;
  videoEnabled?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type Session_Max_Fields = {
  __typename?: "Session_max_fields";
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoChatServerId?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "Session" */
export type Session_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  currentControlUser?: Maybe<Order_By>;
  endTime?: Maybe<Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastKnownTime?: Maybe<Order_By>;
  lastKnownTimeUpdatedAt?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  ownerLeftTime?: Maybe<Order_By>;
  startTime?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Min_Fields = {
  __typename?: "Session_min_fields";
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoChatServerId?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "Session" */
export type Session_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  currentControlUser?: Maybe<Order_By>;
  endTime?: Maybe<Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastKnownTime?: Maybe<Order_By>;
  lastKnownTimeUpdatedAt?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  ownerLeftTime?: Maybe<Order_By>;
  startTime?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Session" */
export type Session_Mutation_Response = {
  __typename?: "Session_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Session>;
};

/** input type for inserting object relation for remote table "Session" */
export type Session_Obj_Rel_Insert_Input = {
  data: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** on conflict condition type for table "Session" */
export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns: Array<Session_Update_Column>;
  where?: Maybe<Session_Bool_Exp>;
};

/** ordering options when selecting data from "Session" */
export type Session_Order_By = {
  ExternalToInternalContent?: Maybe<ExternalToInternalContent_Order_By>;
  Messages_aggregate?: Maybe<Message_Aggregate_Order_By>;
  SessionParticipations_aggregate?: Maybe<SessionParticipation_Aggregate_Order_By>;
  User?: Maybe<User_Order_By>;
  VideoChatServer?: Maybe<VideoChatServer_Order_By>;
  controlLockEnabled?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  currentControlUser?: Maybe<Order_By>;
  endTime?: Maybe<Order_By>;
  host?: Maybe<Order_By>;
  hostContentId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastKnownTime?: Maybe<Order_By>;
  lastKnownTimeUpdatedAt?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  ownerLeftTime?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  startTime?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
  videoEnabled?: Maybe<Order_By>;
};

/** primary key columns input for table: "Session" */
export type Session_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "Session" */
export enum Session_Select_Column {
  /** column name */
  ControlLockEnabled = "controlLockEnabled",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  CurrentControlUser = "currentControlUser",
  /** column name */
  EndTime = "endTime",
  /** column name */
  Host = "host",
  /** column name */
  HostContentId = "hostContentId",
  /** column name */
  Id = "id",
  /** column name */
  LastKnownTime = "lastKnownTime",
  /** column name */
  LastKnownTimeUpdatedAt = "lastKnownTimeUpdatedAt",
  /** column name */
  MaxParticipants = "maxParticipants",
  /** column name */
  OwnerId = "ownerId",
  /** column name */
  OwnerLeftTime = "ownerLeftTime",
  /** column name */
  Public = "public",
  /** column name */
  StartTime = "startTime",
  /** column name */
  State = "state",
  /** column name */
  Title = "title",
  /** column name */
  Url = "url",
  /** column name */
  VideoChatServerId = "videoChatServerId",
  /** column name */
  VideoEnabled = "videoEnabled",
}

/** input type for updating data in table "Session" */
export type Session_Set_Input = {
  controlLockEnabled?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["timestamp"]>;
  currentControlUser?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["timestamp"]>;
  host?: Maybe<Scalars["String"]>;
  hostContentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastKnownTime?: Maybe<Scalars["float8"]>;
  lastKnownTimeUpdatedAt?: Maybe<Scalars["timestamp"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  ownerId?: Maybe<Scalars["String"]>;
  ownerLeftTime?: Maybe<Scalars["timestamp"]>;
  public?: Maybe<Scalars["Boolean"]>;
  startTime?: Maybe<Scalars["timestamp"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  videoChatServerId?: Maybe<Scalars["Int"]>;
  videoEnabled?: Maybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type Session_Stddev_Fields = {
  __typename?: "Session_stddev_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Session" */
export type Session_Stddev_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Session_Stddev_Pop_Fields = {
  __typename?: "Session_stddev_pop_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Session" */
export type Session_Stddev_Pop_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Session_Stddev_Samp_Fields = {
  __typename?: "Session_stddev_samp_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Session" */
export type Session_Stddev_Samp_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Session_Sum_Fields = {
  __typename?: "Session_sum_fields";
  lastKnownTime?: Maybe<Scalars["float8"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  videoChatServerId?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Session" */
export type Session_Sum_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** update columns of table "Session" */
export enum Session_Update_Column {
  /** column name */
  ControlLockEnabled = "controlLockEnabled",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  CurrentControlUser = "currentControlUser",
  /** column name */
  EndTime = "endTime",
  /** column name */
  Host = "host",
  /** column name */
  HostContentId = "hostContentId",
  /** column name */
  Id = "id",
  /** column name */
  LastKnownTime = "lastKnownTime",
  /** column name */
  LastKnownTimeUpdatedAt = "lastKnownTimeUpdatedAt",
  /** column name */
  MaxParticipants = "maxParticipants",
  /** column name */
  OwnerId = "ownerId",
  /** column name */
  OwnerLeftTime = "ownerLeftTime",
  /** column name */
  Public = "public",
  /** column name */
  StartTime = "startTime",
  /** column name */
  State = "state",
  /** column name */
  Title = "title",
  /** column name */
  Url = "url",
  /** column name */
  VideoChatServerId = "videoChatServerId",
  /** column name */
  VideoEnabled = "videoEnabled",
}

/** aggregate var_pop on columns */
export type Session_Var_Pop_Fields = {
  __typename?: "Session_var_pop_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Session" */
export type Session_Var_Pop_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Session_Var_Samp_Fields = {
  __typename?: "Session_var_samp_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Session" */
export type Session_Var_Samp_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Session_Variance_Fields = {
  __typename?: "Session_variance_fields";
  lastKnownTime?: Maybe<Scalars["Float"]>;
  maxParticipants?: Maybe<Scalars["Float"]>;
  videoChatServerId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Session" */
export type Session_Variance_Order_By = {
  lastKnownTime?: Maybe<Order_By>;
  maxParticipants?: Maybe<Order_By>;
  videoChatServerId?: Maybe<Order_By>;
};

/** columns and relationships of "StreamingService" */
export type StreamingService = {
  __typename?: "StreamingService";
  /** An array relationship */
  ExternalToInternalContents: Array<ExternalToInternalContent>;
  /** An aggregated array relationship */
  ExternalToInternalContents_aggregate: ExternalToInternalContent_Aggregate;
  /** An array relationship */
  UserStreamingServices: Array<UserStreamingService>;
  /** An aggregated array relationship */
  UserStreamingServices_aggregate: UserStreamingService_Aggregate;
  hostname: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

/** columns and relationships of "StreamingService" */
export type StreamingServiceExternalToInternalContentsArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** columns and relationships of "StreamingService" */
export type StreamingServiceExternalToInternalContents_AggregateArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** columns and relationships of "StreamingService" */
export type StreamingServiceUserStreamingServicesArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** columns and relationships of "StreamingService" */
export type StreamingServiceUserStreamingServices_AggregateArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** aggregated selection of "StreamingService" */
export type StreamingService_Aggregate = {
  __typename?: "StreamingService_aggregate";
  aggregate?: Maybe<StreamingService_Aggregate_Fields>;
  nodes: Array<StreamingService>;
};

/** aggregate fields of "StreamingService" */
export type StreamingService_Aggregate_Fields = {
  __typename?: "StreamingService_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<StreamingService_Max_Fields>;
  min?: Maybe<StreamingService_Min_Fields>;
};

/** aggregate fields of "StreamingService" */
export type StreamingService_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<StreamingService_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "StreamingService" */
export type StreamingService_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<StreamingService_Max_Order_By>;
  min?: Maybe<StreamingService_Min_Order_By>;
};

/** input type for inserting array relation for remote table "StreamingService" */
export type StreamingService_Arr_Rel_Insert_Input = {
  data: Array<StreamingService_Insert_Input>;
  on_conflict?: Maybe<StreamingService_On_Conflict>;
};

/** Boolean expression to filter rows from the table "StreamingService". All fields are combined with a logical 'AND'. */
export type StreamingService_Bool_Exp = {
  ExternalToInternalContents?: Maybe<ExternalToInternalContent_Bool_Exp>;
  UserStreamingServices?: Maybe<UserStreamingService_Bool_Exp>;
  _and?: Maybe<Array<Maybe<StreamingService_Bool_Exp>>>;
  _not?: Maybe<StreamingService_Bool_Exp>;
  _or?: Maybe<Array<Maybe<StreamingService_Bool_Exp>>>;
  hostname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "StreamingService" */
export enum StreamingService_Constraint {
  /** unique or primary key constraint */
  StreamingServiceHostnameKey = "StreamingService_hostname_key",
  /** unique or primary key constraint */
  StreamingServicePkey = "StreamingService_pkey",
}

/** input type for inserting data into table "StreamingService" */
export type StreamingService_Insert_Input = {
  ExternalToInternalContents?: Maybe<ExternalToInternalContent_Arr_Rel_Insert_Input>;
  UserStreamingServices?: Maybe<UserStreamingService_Arr_Rel_Insert_Input>;
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type StreamingService_Max_Fields = {
  __typename?: "StreamingService_max_fields";
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "StreamingService" */
export type StreamingService_Max_Order_By = {
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type StreamingService_Min_Fields = {
  __typename?: "StreamingService_min_fields";
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "StreamingService" */
export type StreamingService_Min_Order_By = {
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "StreamingService" */
export type StreamingService_Mutation_Response = {
  __typename?: "StreamingService_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<StreamingService>;
};

/** input type for inserting object relation for remote table "StreamingService" */
export type StreamingService_Obj_Rel_Insert_Input = {
  data: StreamingService_Insert_Input;
  on_conflict?: Maybe<StreamingService_On_Conflict>;
};

/** on conflict condition type for table "StreamingService" */
export type StreamingService_On_Conflict = {
  constraint: StreamingService_Constraint;
  update_columns: Array<StreamingService_Update_Column>;
  where?: Maybe<StreamingService_Bool_Exp>;
};

/** ordering options when selecting data from "StreamingService" */
export type StreamingService_Order_By = {
  ExternalToInternalContents_aggregate?: Maybe<ExternalToInternalContent_Aggregate_Order_By>;
  UserStreamingServices_aggregate?: Maybe<UserStreamingService_Aggregate_Order_By>;
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "StreamingService" */
export type StreamingService_Pk_Columns_Input = {
  id: Scalars["String"];
};

/** select columns of table "StreamingService" */
export enum StreamingService_Select_Column {
  /** column name */
  Hostname = "hostname",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "StreamingService" */
export type StreamingService_Set_Input = {
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** update columns of table "StreamingService" */
export enum StreamingService_Update_Column {
  /** column name */
  Hostname = "hostname",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  _nlike?: Maybe<Scalars["String"]>;
  _nsimilar?: Maybe<Scalars["String"]>;
  _similar?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "SubscriptionPlanTier" */
export type SubscriptionPlanTier = {
  __typename?: "SubscriptionPlanTier";
  /** An object relationship */
  Tier: Tier;
  /** An array relationship */
  UserSubscriptions: Array<UserSubscription>;
  /** An aggregated array relationship */
  UserSubscriptions_aggregate: UserSubscription_Aggregate;
  subscriptionPlanId: Scalars["String"];
  tierId: Scalars["String"];
};

/** columns and relationships of "SubscriptionPlanTier" */
export type SubscriptionPlanTierUserSubscriptionsArgs = {
  distinct_on?: Maybe<Array<UserSubscription_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserSubscription_Order_By>>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** columns and relationships of "SubscriptionPlanTier" */
export type SubscriptionPlanTierUserSubscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSubscription_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserSubscription_Order_By>>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** aggregated selection of "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Aggregate = {
  __typename?: "SubscriptionPlanTier_aggregate";
  aggregate?: Maybe<SubscriptionPlanTier_Aggregate_Fields>;
  nodes: Array<SubscriptionPlanTier>;
};

/** aggregate fields of "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Aggregate_Fields = {
  __typename?: "SubscriptionPlanTier_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<SubscriptionPlanTier_Max_Fields>;
  min?: Maybe<SubscriptionPlanTier_Min_Fields>;
};

/** aggregate fields of "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SubscriptionPlanTier_Max_Order_By>;
  min?: Maybe<SubscriptionPlanTier_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Arr_Rel_Insert_Input = {
  data: Array<SubscriptionPlanTier_Insert_Input>;
  on_conflict?: Maybe<SubscriptionPlanTier_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SubscriptionPlanTier". All fields are combined with a logical 'AND'. */
export type SubscriptionPlanTier_Bool_Exp = {
  Tier?: Maybe<Tier_Bool_Exp>;
  UserSubscriptions?: Maybe<UserSubscription_Bool_Exp>;
  _and?: Maybe<Array<Maybe<SubscriptionPlanTier_Bool_Exp>>>;
  _not?: Maybe<SubscriptionPlanTier_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SubscriptionPlanTier_Bool_Exp>>>;
  subscriptionPlanId?: Maybe<String_Comparison_Exp>;
  tierId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "SubscriptionPlanTier" */
export enum SubscriptionPlanTier_Constraint {
  /** unique or primary key constraint */
  SubscriptionPlanTierPkey = "SubscriptionPlanTier_pkey",
}

/** input type for inserting data into table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Insert_Input = {
  Tier?: Maybe<Tier_Obj_Rel_Insert_Input>;
  UserSubscriptions?: Maybe<UserSubscription_Arr_Rel_Insert_Input>;
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  tierId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type SubscriptionPlanTier_Max_Fields = {
  __typename?: "SubscriptionPlanTier_max_fields";
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  tierId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Max_Order_By = {
  subscriptionPlanId?: Maybe<Order_By>;
  tierId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SubscriptionPlanTier_Min_Fields = {
  __typename?: "SubscriptionPlanTier_min_fields";
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  tierId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Min_Order_By = {
  subscriptionPlanId?: Maybe<Order_By>;
  tierId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Mutation_Response = {
  __typename?: "SubscriptionPlanTier_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<SubscriptionPlanTier>;
};

/** input type for inserting object relation for remote table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Obj_Rel_Insert_Input = {
  data: SubscriptionPlanTier_Insert_Input;
  on_conflict?: Maybe<SubscriptionPlanTier_On_Conflict>;
};

/** on conflict condition type for table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_On_Conflict = {
  constraint: SubscriptionPlanTier_Constraint;
  update_columns: Array<SubscriptionPlanTier_Update_Column>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** ordering options when selecting data from "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Order_By = {
  Tier?: Maybe<Tier_Order_By>;
  UserSubscriptions_aggregate?: Maybe<UserSubscription_Aggregate_Order_By>;
  subscriptionPlanId?: Maybe<Order_By>;
  tierId?: Maybe<Order_By>;
};

/** primary key columns input for table: "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Pk_Columns_Input = {
  subscriptionPlanId: Scalars["String"];
};

/** select columns of table "SubscriptionPlanTier" */
export enum SubscriptionPlanTier_Select_Column {
  /** column name */
  SubscriptionPlanId = "subscriptionPlanId",
  /** column name */
  TierId = "tierId",
}

/** input type for updating data in table "SubscriptionPlanTier" */
export type SubscriptionPlanTier_Set_Input = {
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  tierId?: Maybe<Scalars["String"]>;
};

/** update columns of table "SubscriptionPlanTier" */
export enum SubscriptionPlanTier_Update_Column {
  /** column name */
  SubscriptionPlanId = "subscriptionPlanId",
  /** column name */
  TierId = "tierId",
}

/** columns and relationships of "Tier" */
export type Tier = {
  __typename?: "Tier";
  /** An array relationship */
  SubscriptionPlanTiers: Array<SubscriptionPlanTier>;
  /** An aggregated array relationship */
  SubscriptionPlanTiers_aggregate: SubscriptionPlanTier_Aggregate;
  freeTime?: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  maxPeoplePrivate: Scalars["Int"];
  maxPeoplePublic: Scalars["Int"];
  name: Scalars["String"];
  unlimitedTime: Scalars["Boolean"];
};

/** columns and relationships of "Tier" */
export type TierSubscriptionPlanTiersArgs = {
  distinct_on?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SubscriptionPlanTier_Order_By>>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** columns and relationships of "Tier" */
export type TierSubscriptionPlanTiers_AggregateArgs = {
  distinct_on?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SubscriptionPlanTier_Order_By>>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** aggregated selection of "Tier" */
export type Tier_Aggregate = {
  __typename?: "Tier_aggregate";
  aggregate?: Maybe<Tier_Aggregate_Fields>;
  nodes: Array<Tier>;
};

/** aggregate fields of "Tier" */
export type Tier_Aggregate_Fields = {
  __typename?: "Tier_aggregate_fields";
  avg?: Maybe<Tier_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Tier_Max_Fields>;
  min?: Maybe<Tier_Min_Fields>;
  stddev?: Maybe<Tier_Stddev_Fields>;
  stddev_pop?: Maybe<Tier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tier_Stddev_Samp_Fields>;
  sum?: Maybe<Tier_Sum_Fields>;
  var_pop?: Maybe<Tier_Var_Pop_Fields>;
  var_samp?: Maybe<Tier_Var_Samp_Fields>;
  variance?: Maybe<Tier_Variance_Fields>;
};

/** aggregate fields of "Tier" */
export type Tier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tier_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "Tier" */
export type Tier_Aggregate_Order_By = {
  avg?: Maybe<Tier_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tier_Max_Order_By>;
  min?: Maybe<Tier_Min_Order_By>;
  stddev?: Maybe<Tier_Stddev_Order_By>;
  stddev_pop?: Maybe<Tier_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tier_Stddev_Samp_Order_By>;
  sum?: Maybe<Tier_Sum_Order_By>;
  var_pop?: Maybe<Tier_Var_Pop_Order_By>;
  var_samp?: Maybe<Tier_Var_Samp_Order_By>;
  variance?: Maybe<Tier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Tier" */
export type Tier_Arr_Rel_Insert_Input = {
  data: Array<Tier_Insert_Input>;
  on_conflict?: Maybe<Tier_On_Conflict>;
};

/** aggregate avg on columns */
export type Tier_Avg_Fields = {
  __typename?: "Tier_avg_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "Tier" */
export type Tier_Avg_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Tier". All fields are combined with a logical 'AND'. */
export type Tier_Bool_Exp = {
  SubscriptionPlanTiers?: Maybe<SubscriptionPlanTier_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Tier_Bool_Exp>>>;
  _not?: Maybe<Tier_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tier_Bool_Exp>>>;
  freeTime?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  maxPeoplePrivate?: Maybe<Int_Comparison_Exp>;
  maxPeoplePublic?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  unlimitedTime?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tier" */
export enum Tier_Constraint {
  /** unique or primary key constraint */
  TierPkey = "Tier_pkey",
}

/** input type for incrementing integer column in table "Tier" */
export type Tier_Inc_Input = {
  freeTime?: Maybe<Scalars["Int"]>;
  maxPeoplePrivate?: Maybe<Scalars["Int"]>;
  maxPeoplePublic?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "Tier" */
export type Tier_Insert_Input = {
  SubscriptionPlanTiers?: Maybe<SubscriptionPlanTier_Arr_Rel_Insert_Input>;
  freeTime?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  maxPeoplePrivate?: Maybe<Scalars["Int"]>;
  maxPeoplePublic?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  unlimitedTime?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type Tier_Max_Fields = {
  __typename?: "Tier_max_fields";
  freeTime?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  maxPeoplePrivate?: Maybe<Scalars["Int"]>;
  maxPeoplePublic?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "Tier" */
export type Tier_Max_Order_By = {
  freeTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tier_Min_Fields = {
  __typename?: "Tier_min_fields";
  freeTime?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  maxPeoplePrivate?: Maybe<Scalars["Int"]>;
  maxPeoplePublic?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "Tier" */
export type Tier_Min_Order_By = {
  freeTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "Tier" */
export type Tier_Mutation_Response = {
  __typename?: "Tier_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Tier>;
};

/** input type for inserting object relation for remote table "Tier" */
export type Tier_Obj_Rel_Insert_Input = {
  data: Tier_Insert_Input;
  on_conflict?: Maybe<Tier_On_Conflict>;
};

/** on conflict condition type for table "Tier" */
export type Tier_On_Conflict = {
  constraint: Tier_Constraint;
  update_columns: Array<Tier_Update_Column>;
  where?: Maybe<Tier_Bool_Exp>;
};

/** ordering options when selecting data from "Tier" */
export type Tier_Order_By = {
  SubscriptionPlanTiers_aggregate?: Maybe<SubscriptionPlanTier_Aggregate_Order_By>;
  freeTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  unlimitedTime?: Maybe<Order_By>;
};

/** primary key columns input for table: "Tier" */
export type Tier_Pk_Columns_Input = {
  id: Scalars["String"];
};

/** select columns of table "Tier" */
export enum Tier_Select_Column {
  /** column name */
  FreeTime = "freeTime",
  /** column name */
  Id = "id",
  /** column name */
  MaxPeoplePrivate = "maxPeoplePrivate",
  /** column name */
  MaxPeoplePublic = "maxPeoplePublic",
  /** column name */
  Name = "name",
  /** column name */
  UnlimitedTime = "unlimitedTime",
}

/** input type for updating data in table "Tier" */
export type Tier_Set_Input = {
  freeTime?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  maxPeoplePrivate?: Maybe<Scalars["Int"]>;
  maxPeoplePublic?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  unlimitedTime?: Maybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type Tier_Stddev_Fields = {
  __typename?: "Tier_stddev_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "Tier" */
export type Tier_Stddev_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tier_Stddev_Pop_Fields = {
  __typename?: "Tier_stddev_pop_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "Tier" */
export type Tier_Stddev_Pop_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tier_Stddev_Samp_Fields = {
  __typename?: "Tier_stddev_samp_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "Tier" */
export type Tier_Stddev_Samp_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tier_Sum_Fields = {
  __typename?: "Tier_sum_fields";
  freeTime?: Maybe<Scalars["Int"]>;
  maxPeoplePrivate?: Maybe<Scalars["Int"]>;
  maxPeoplePublic?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "Tier" */
export type Tier_Sum_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** update columns of table "Tier" */
export enum Tier_Update_Column {
  /** column name */
  FreeTime = "freeTime",
  /** column name */
  Id = "id",
  /** column name */
  MaxPeoplePrivate = "maxPeoplePrivate",
  /** column name */
  MaxPeoplePublic = "maxPeoplePublic",
  /** column name */
  Name = "name",
  /** column name */
  UnlimitedTime = "unlimitedTime",
}

/** aggregate var_pop on columns */
export type Tier_Var_Pop_Fields = {
  __typename?: "Tier_var_pop_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "Tier" */
export type Tier_Var_Pop_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tier_Var_Samp_Fields = {
  __typename?: "Tier_var_samp_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "Tier" */
export type Tier_Var_Samp_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tier_Variance_Fields = {
  __typename?: "Tier_variance_fields";
  freeTime?: Maybe<Scalars["Float"]>;
  maxPeoplePrivate?: Maybe<Scalars["Float"]>;
  maxPeoplePublic?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "Tier" */
export type Tier_Variance_Order_By = {
  freeTime?: Maybe<Order_By>;
  maxPeoplePrivate?: Maybe<Order_By>;
  maxPeoplePublic?: Maybe<Order_By>;
};

/** columns and relationships of "User" */
export type User = {
  __typename?: "User";
  /** An array relationship */
  Budgets: Array<Budget>;
  /** An aggregated array relationship */
  Budgets_aggregate: Budget_Aggregate;
  /** An array relationship */
  Messages: Array<Message>;
  /** An aggregated array relationship */
  Messages_aggregate: Message_Aggregate;
  /** An array relationship */
  SessionParticipations: Array<SessionParticipation>;
  /** An aggregated array relationship */
  SessionParticipations_aggregate: SessionParticipation_Aggregate;
  /** An array relationship */
  Sessions: Array<Session>;
  /** An aggregated array relationship */
  Sessions_aggregate: Session_Aggregate;
  /** An array relationship */
  UserStreamingServices: Array<UserStreamingService>;
  /** An aggregated array relationship */
  UserStreamingServices_aggregate: UserStreamingService_Aggregate;
  /** An object relationship */
  UserSubscription?: Maybe<UserSubscription>;
  avatar_version?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  email: Scalars["String"];
  id: Scalars["String"];
  username?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "User" */
export type UserBudgetsArgs = {
  distinct_on?: Maybe<Array<Budget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Budget_Order_By>>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserBudgets_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Budget_Order_By>>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserMessagesArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserSessionParticipationsArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserSessionParticipations_AggregateArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserSessionsArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserUserStreamingServicesArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** columns and relationships of "User" */
export type UserUserStreamingServices_AggregateArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** columns and relationships of "UserGeneratedContent" */
export type UserGeneratedContent = {
  __typename?: "UserGeneratedContent";
  /** An array relationship */
  ExternalToInternalContents: Array<ExternalToInternalContent>;
  /** An aggregated array relationship */
  ExternalToInternalContents_aggregate: ExternalToInternalContent_Aggregate;
  episodeNumber?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  imageURL?: Maybe<Scalars["String"]>;
  language: Scalars["String"];
  seasonNumber?: Maybe<Scalars["Int"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "UserGeneratedContent" */
export type UserGeneratedContentExternalToInternalContentsArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** columns and relationships of "UserGeneratedContent" */
export type UserGeneratedContentExternalToInternalContents_AggregateArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** aggregated selection of "UserGeneratedContent" */
export type UserGeneratedContent_Aggregate = {
  __typename?: "UserGeneratedContent_aggregate";
  aggregate?: Maybe<UserGeneratedContent_Aggregate_Fields>;
  nodes: Array<UserGeneratedContent>;
};

/** aggregate fields of "UserGeneratedContent" */
export type UserGeneratedContent_Aggregate_Fields = {
  __typename?: "UserGeneratedContent_aggregate_fields";
  avg?: Maybe<UserGeneratedContent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<UserGeneratedContent_Max_Fields>;
  min?: Maybe<UserGeneratedContent_Min_Fields>;
  stddev?: Maybe<UserGeneratedContent_Stddev_Fields>;
  stddev_pop?: Maybe<UserGeneratedContent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserGeneratedContent_Stddev_Samp_Fields>;
  sum?: Maybe<UserGeneratedContent_Sum_Fields>;
  var_pop?: Maybe<UserGeneratedContent_Var_Pop_Fields>;
  var_samp?: Maybe<UserGeneratedContent_Var_Samp_Fields>;
  variance?: Maybe<UserGeneratedContent_Variance_Fields>;
};

/** aggregate fields of "UserGeneratedContent" */
export type UserGeneratedContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserGeneratedContent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "UserGeneratedContent" */
export type UserGeneratedContent_Aggregate_Order_By = {
  avg?: Maybe<UserGeneratedContent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<UserGeneratedContent_Max_Order_By>;
  min?: Maybe<UserGeneratedContent_Min_Order_By>;
  stddev?: Maybe<UserGeneratedContent_Stddev_Order_By>;
  stddev_pop?: Maybe<UserGeneratedContent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<UserGeneratedContent_Stddev_Samp_Order_By>;
  sum?: Maybe<UserGeneratedContent_Sum_Order_By>;
  var_pop?: Maybe<UserGeneratedContent_Var_Pop_Order_By>;
  var_samp?: Maybe<UserGeneratedContent_Var_Samp_Order_By>;
  variance?: Maybe<UserGeneratedContent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserGeneratedContent" */
export type UserGeneratedContent_Arr_Rel_Insert_Input = {
  data: Array<UserGeneratedContent_Insert_Input>;
  on_conflict?: Maybe<UserGeneratedContent_On_Conflict>;
};

/** aggregate avg on columns */
export type UserGeneratedContent_Avg_Fields = {
  __typename?: "UserGeneratedContent_avg_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Avg_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserGeneratedContent". All fields are combined with a logical 'AND'. */
export type UserGeneratedContent_Bool_Exp = {
  ExternalToInternalContents?: Maybe<ExternalToInternalContent_Bool_Exp>;
  _and?: Maybe<Array<Maybe<UserGeneratedContent_Bool_Exp>>>;
  _not?: Maybe<UserGeneratedContent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserGeneratedContent_Bool_Exp>>>;
  episodeNumber?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  imageURL?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  seasonNumber?: Maybe<Int_Comparison_Exp>;
  subtitle?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserGeneratedContent" */
export enum UserGeneratedContent_Constraint {
  /** unique or primary key constraint */
  UserGeneratedContentPkey = "UserGeneratedContent_pkey",
}

/** input type for incrementing integer column in table "UserGeneratedContent" */
export type UserGeneratedContent_Inc_Input = {
  episodeNumber?: Maybe<Scalars["Int"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "UserGeneratedContent" */
export type UserGeneratedContent_Insert_Input = {
  ExternalToInternalContents?: Maybe<ExternalToInternalContent_Arr_Rel_Insert_Input>;
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  imageURL?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type UserGeneratedContent_Max_Fields = {
  __typename?: "UserGeneratedContent_max_fields";
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  imageURL?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Max_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageURL?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  subtitle?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserGeneratedContent_Min_Fields = {
  __typename?: "UserGeneratedContent_min_fields";
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  imageURL?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Min_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageURL?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  subtitle?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "UserGeneratedContent" */
export type UserGeneratedContent_Mutation_Response = {
  __typename?: "UserGeneratedContent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<UserGeneratedContent>;
};

/** input type for inserting object relation for remote table "UserGeneratedContent" */
export type UserGeneratedContent_Obj_Rel_Insert_Input = {
  data: UserGeneratedContent_Insert_Input;
  on_conflict?: Maybe<UserGeneratedContent_On_Conflict>;
};

/** on conflict condition type for table "UserGeneratedContent" */
export type UserGeneratedContent_On_Conflict = {
  constraint: UserGeneratedContent_Constraint;
  update_columns: Array<UserGeneratedContent_Update_Column>;
  where?: Maybe<UserGeneratedContent_Bool_Exp>;
};

/** ordering options when selecting data from "UserGeneratedContent" */
export type UserGeneratedContent_Order_By = {
  ExternalToInternalContents_aggregate?: Maybe<ExternalToInternalContent_Aggregate_Order_By>;
  episodeNumber?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  imageURL?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
  subtitle?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "UserGeneratedContent" */
export type UserGeneratedContent_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "UserGeneratedContent" */
export enum UserGeneratedContent_Select_Column {
  /** column name */
  EpisodeNumber = "episodeNumber",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "imageURL",
  /** column name */
  Language = "language",
  /** column name */
  SeasonNumber = "seasonNumber",
  /** column name */
  Subtitle = "subtitle",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "UserGeneratedContent" */
export type UserGeneratedContent_Set_Input = {
  episodeNumber?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  imageURL?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type UserGeneratedContent_Stddev_Fields = {
  __typename?: "UserGeneratedContent_stddev_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Stddev_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserGeneratedContent_Stddev_Pop_Fields = {
  __typename?: "UserGeneratedContent_stddev_pop_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Stddev_Pop_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserGeneratedContent_Stddev_Samp_Fields = {
  __typename?: "UserGeneratedContent_stddev_samp_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Stddev_Samp_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type UserGeneratedContent_Sum_Fields = {
  __typename?: "UserGeneratedContent_sum_fields";
  episodeNumber?: Maybe<Scalars["Int"]>;
  seasonNumber?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Sum_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** update columns of table "UserGeneratedContent" */
export enum UserGeneratedContent_Update_Column {
  /** column name */
  EpisodeNumber = "episodeNumber",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "imageURL",
  /** column name */
  Language = "language",
  /** column name */
  SeasonNumber = "seasonNumber",
  /** column name */
  Subtitle = "subtitle",
  /** column name */
  Title = "title",
}

/** aggregate var_pop on columns */
export type UserGeneratedContent_Var_Pop_Fields = {
  __typename?: "UserGeneratedContent_var_pop_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Var_Pop_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserGeneratedContent_Var_Samp_Fields = {
  __typename?: "UserGeneratedContent_var_samp_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Var_Samp_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type UserGeneratedContent_Variance_Fields = {
  __typename?: "UserGeneratedContent_variance_fields";
  episodeNumber?: Maybe<Scalars["Float"]>;
  seasonNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "UserGeneratedContent" */
export type UserGeneratedContent_Variance_Order_By = {
  episodeNumber?: Maybe<Order_By>;
  seasonNumber?: Maybe<Order_By>;
};

/** columns and relationships of "UserPublic" */
export type UserPublic = {
  __typename?: "UserPublic";
  avatar_version?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "UserPublic" */
export type UserPublic_Aggregate = {
  __typename?: "UserPublic_aggregate";
  aggregate?: Maybe<UserPublic_Aggregate_Fields>;
  nodes: Array<UserPublic>;
};

/** aggregate fields of "UserPublic" */
export type UserPublic_Aggregate_Fields = {
  __typename?: "UserPublic_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<UserPublic_Max_Fields>;
  min?: Maybe<UserPublic_Min_Fields>;
};

/** aggregate fields of "UserPublic" */
export type UserPublic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserPublic_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "UserPublic" */
export type UserPublic_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserPublic_Max_Order_By>;
  min?: Maybe<UserPublic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserPublic" */
export type UserPublic_Arr_Rel_Insert_Input = {
  data: Array<UserPublic_Insert_Input>;
};

/** Boolean expression to filter rows from the table "UserPublic". All fields are combined with a logical 'AND'. */
export type UserPublic_Bool_Exp = {
  _and?: Maybe<Array<Maybe<UserPublic_Bool_Exp>>>;
  _not?: Maybe<UserPublic_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserPublic_Bool_Exp>>>;
  avatar_version?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "UserPublic" */
export type UserPublic_Insert_Input = {
  avatar_version?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type UserPublic_Max_Fields = {
  __typename?: "UserPublic_max_fields";
  avatar_version?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "UserPublic" */
export type UserPublic_Max_Order_By = {
  avatar_version?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserPublic_Min_Fields = {
  __typename?: "UserPublic_min_fields";
  avatar_version?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "UserPublic" */
export type UserPublic_Min_Order_By = {
  avatar_version?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** response of any mutation on the table "UserPublic" */
export type UserPublic_Mutation_Response = {
  __typename?: "UserPublic_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<UserPublic>;
};

/** input type for inserting object relation for remote table "UserPublic" */
export type UserPublic_Obj_Rel_Insert_Input = {
  data: UserPublic_Insert_Input;
};

/** ordering options when selecting data from "UserPublic" */
export type UserPublic_Order_By = {
  avatar_version?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** select columns of table "UserPublic" */
export enum UserPublic_Select_Column {
  /** column name */
  AvatarVersion = "avatar_version",
  /** column name */
  Id = "id",
  /** column name */
  Username = "username",
}

/** input type for updating data in table "UserPublic" */
export type UserPublic_Set_Input = {
  avatar_version?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "UserStreamingService" */
export type UserStreamingService = {
  __typename?: "UserStreamingService";
  /** An object relationship */
  StreamingService: StreamingService;
  /** An object relationship */
  User: User;
  serviceId: Scalars["String"];
  userId: Scalars["String"];
};

/** aggregated selection of "UserStreamingService" */
export type UserStreamingService_Aggregate = {
  __typename?: "UserStreamingService_aggregate";
  aggregate?: Maybe<UserStreamingService_Aggregate_Fields>;
  nodes: Array<UserStreamingService>;
};

/** aggregate fields of "UserStreamingService" */
export type UserStreamingService_Aggregate_Fields = {
  __typename?: "UserStreamingService_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<UserStreamingService_Max_Fields>;
  min?: Maybe<UserStreamingService_Min_Fields>;
};

/** aggregate fields of "UserStreamingService" */
export type UserStreamingService_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserStreamingService_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "UserStreamingService" */
export type UserStreamingService_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserStreamingService_Max_Order_By>;
  min?: Maybe<UserStreamingService_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserStreamingService" */
export type UserStreamingService_Arr_Rel_Insert_Input = {
  data: Array<UserStreamingService_Insert_Input>;
  on_conflict?: Maybe<UserStreamingService_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserStreamingService". All fields are combined with a logical 'AND'. */
export type UserStreamingService_Bool_Exp = {
  StreamingService?: Maybe<StreamingService_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<UserStreamingService_Bool_Exp>>>;
  _not?: Maybe<UserStreamingService_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserStreamingService_Bool_Exp>>>;
  serviceId?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserStreamingService" */
export enum UserStreamingService_Constraint {
  /** unique or primary key constraint */
  UserStreamingServicePkey = "UserStreamingService_pkey",
}

/** input type for inserting data into table "UserStreamingService" */
export type UserStreamingService_Insert_Input = {
  StreamingService?: Maybe<StreamingService_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  serviceId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type UserStreamingService_Max_Fields = {
  __typename?: "UserStreamingService_max_fields";
  serviceId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "UserStreamingService" */
export type UserStreamingService_Max_Order_By = {
  serviceId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserStreamingService_Min_Fields = {
  __typename?: "UserStreamingService_min_fields";
  serviceId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "UserStreamingService" */
export type UserStreamingService_Min_Order_By = {
  serviceId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "UserStreamingService" */
export type UserStreamingService_Mutation_Response = {
  __typename?: "UserStreamingService_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<UserStreamingService>;
};

/** input type for inserting object relation for remote table "UserStreamingService" */
export type UserStreamingService_Obj_Rel_Insert_Input = {
  data: UserStreamingService_Insert_Input;
  on_conflict?: Maybe<UserStreamingService_On_Conflict>;
};

/** on conflict condition type for table "UserStreamingService" */
export type UserStreamingService_On_Conflict = {
  constraint: UserStreamingService_Constraint;
  update_columns: Array<UserStreamingService_Update_Column>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** ordering options when selecting data from "UserStreamingService" */
export type UserStreamingService_Order_By = {
  StreamingService?: Maybe<StreamingService_Order_By>;
  User?: Maybe<User_Order_By>;
  serviceId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "UserStreamingService" */
export type UserStreamingService_Pk_Columns_Input = {
  serviceId: Scalars["String"];
  userId: Scalars["String"];
};

/** select columns of table "UserStreamingService" */
export enum UserStreamingService_Select_Column {
  /** column name */
  ServiceId = "serviceId",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "UserStreamingService" */
export type UserStreamingService_Set_Input = {
  serviceId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** update columns of table "UserStreamingService" */
export enum UserStreamingService_Update_Column {
  /** column name */
  ServiceId = "serviceId",
  /** column name */
  UserId = "userId",
}

/** columns and relationships of "UserSubscription" */
export type UserSubscription = {
  __typename?: "UserSubscription";
  /** An object relationship */
  SubscriptionPlanTier: SubscriptionPlanTier;
  /** An object relationship */
  User: User;
  cancelUrl?: Maybe<Scalars["String"]>;
  cancellationEffectiveDate?: Maybe<Scalars["timestamp"]>;
  extraTime: Scalars["Int"];
  status?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionPlanId: Scalars["String"];
  updateUrl?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
};

/** aggregated selection of "UserSubscription" */
export type UserSubscription_Aggregate = {
  __typename?: "UserSubscription_aggregate";
  aggregate?: Maybe<UserSubscription_Aggregate_Fields>;
  nodes: Array<UserSubscription>;
};

/** aggregate fields of "UserSubscription" */
export type UserSubscription_Aggregate_Fields = {
  __typename?: "UserSubscription_aggregate_fields";
  avg?: Maybe<UserSubscription_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<UserSubscription_Max_Fields>;
  min?: Maybe<UserSubscription_Min_Fields>;
  stddev?: Maybe<UserSubscription_Stddev_Fields>;
  stddev_pop?: Maybe<UserSubscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserSubscription_Stddev_Samp_Fields>;
  sum?: Maybe<UserSubscription_Sum_Fields>;
  var_pop?: Maybe<UserSubscription_Var_Pop_Fields>;
  var_samp?: Maybe<UserSubscription_Var_Samp_Fields>;
  variance?: Maybe<UserSubscription_Variance_Fields>;
};

/** aggregate fields of "UserSubscription" */
export type UserSubscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserSubscription_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "UserSubscription" */
export type UserSubscription_Aggregate_Order_By = {
  avg?: Maybe<UserSubscription_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<UserSubscription_Max_Order_By>;
  min?: Maybe<UserSubscription_Min_Order_By>;
  stddev?: Maybe<UserSubscription_Stddev_Order_By>;
  stddev_pop?: Maybe<UserSubscription_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<UserSubscription_Stddev_Samp_Order_By>;
  sum?: Maybe<UserSubscription_Sum_Order_By>;
  var_pop?: Maybe<UserSubscription_Var_Pop_Order_By>;
  var_samp?: Maybe<UserSubscription_Var_Samp_Order_By>;
  variance?: Maybe<UserSubscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserSubscription" */
export type UserSubscription_Arr_Rel_Insert_Input = {
  data: Array<UserSubscription_Insert_Input>;
  on_conflict?: Maybe<UserSubscription_On_Conflict>;
};

/** aggregate avg on columns */
export type UserSubscription_Avg_Fields = {
  __typename?: "UserSubscription_avg_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "UserSubscription" */
export type UserSubscription_Avg_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserSubscription". All fields are combined with a logical 'AND'. */
export type UserSubscription_Bool_Exp = {
  SubscriptionPlanTier?: Maybe<SubscriptionPlanTier_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<UserSubscription_Bool_Exp>>>;
  _not?: Maybe<UserSubscription_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserSubscription_Bool_Exp>>>;
  cancelUrl?: Maybe<String_Comparison_Exp>;
  cancellationEffectiveDate?: Maybe<Timestamp_Comparison_Exp>;
  extraTime?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  subscriptionId?: Maybe<String_Comparison_Exp>;
  subscriptionPlanId?: Maybe<String_Comparison_Exp>;
  updateUrl?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserSubscription" */
export enum UserSubscription_Constraint {
  /** unique or primary key constraint */
  UserTierPkey = "UserTier_pkey",
  /** unique or primary key constraint */
  UserTierSubscriptionIdKey = "UserTier_subscriptionId_key",
  /** unique or primary key constraint */
  UserTierUserIdKey = "UserTier_userId_key",
}

/** input type for incrementing integer column in table "UserSubscription" */
export type UserSubscription_Inc_Input = {
  extraTime?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "UserSubscription" */
export type UserSubscription_Insert_Input = {
  SubscriptionPlanTier?: Maybe<SubscriptionPlanTier_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  cancelUrl?: Maybe<Scalars["String"]>;
  cancellationEffectiveDate?: Maybe<Scalars["timestamp"]>;
  extraTime?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  updateUrl?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type UserSubscription_Max_Fields = {
  __typename?: "UserSubscription_max_fields";
  cancelUrl?: Maybe<Scalars["String"]>;
  cancellationEffectiveDate?: Maybe<Scalars["timestamp"]>;
  extraTime?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  updateUrl?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "UserSubscription" */
export type UserSubscription_Max_Order_By = {
  cancelUrl?: Maybe<Order_By>;
  cancellationEffectiveDate?: Maybe<Order_By>;
  extraTime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscriptionId?: Maybe<Order_By>;
  subscriptionPlanId?: Maybe<Order_By>;
  updateUrl?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserSubscription_Min_Fields = {
  __typename?: "UserSubscription_min_fields";
  cancelUrl?: Maybe<Scalars["String"]>;
  cancellationEffectiveDate?: Maybe<Scalars["timestamp"]>;
  extraTime?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  updateUrl?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "UserSubscription" */
export type UserSubscription_Min_Order_By = {
  cancelUrl?: Maybe<Order_By>;
  cancellationEffectiveDate?: Maybe<Order_By>;
  extraTime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscriptionId?: Maybe<Order_By>;
  subscriptionPlanId?: Maybe<Order_By>;
  updateUrl?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "UserSubscription" */
export type UserSubscription_Mutation_Response = {
  __typename?: "UserSubscription_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<UserSubscription>;
};

/** input type for inserting object relation for remote table "UserSubscription" */
export type UserSubscription_Obj_Rel_Insert_Input = {
  data: UserSubscription_Insert_Input;
  on_conflict?: Maybe<UserSubscription_On_Conflict>;
};

/** on conflict condition type for table "UserSubscription" */
export type UserSubscription_On_Conflict = {
  constraint: UserSubscription_Constraint;
  update_columns: Array<UserSubscription_Update_Column>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** ordering options when selecting data from "UserSubscription" */
export type UserSubscription_Order_By = {
  SubscriptionPlanTier?: Maybe<SubscriptionPlanTier_Order_By>;
  User?: Maybe<User_Order_By>;
  cancelUrl?: Maybe<Order_By>;
  cancellationEffectiveDate?: Maybe<Order_By>;
  extraTime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subscriptionId?: Maybe<Order_By>;
  subscriptionPlanId?: Maybe<Order_By>;
  updateUrl?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "UserSubscription" */
export type UserSubscription_Pk_Columns_Input = {
  userId: Scalars["String"];
};

/** select columns of table "UserSubscription" */
export enum UserSubscription_Select_Column {
  /** column name */
  CancelUrl = "cancelUrl",
  /** column name */
  CancellationEffectiveDate = "cancellationEffectiveDate",
  /** column name */
  ExtraTime = "extraTime",
  /** column name */
  Status = "status",
  /** column name */
  SubscriptionId = "subscriptionId",
  /** column name */
  SubscriptionPlanId = "subscriptionPlanId",
  /** column name */
  UpdateUrl = "updateUrl",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "UserSubscription" */
export type UserSubscription_Set_Input = {
  cancelUrl?: Maybe<Scalars["String"]>;
  cancellationEffectiveDate?: Maybe<Scalars["timestamp"]>;
  extraTime?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionPlanId?: Maybe<Scalars["String"]>;
  updateUrl?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type UserSubscription_Stddev_Fields = {
  __typename?: "UserSubscription_stddev_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "UserSubscription" */
export type UserSubscription_Stddev_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserSubscription_Stddev_Pop_Fields = {
  __typename?: "UserSubscription_stddev_pop_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "UserSubscription" */
export type UserSubscription_Stddev_Pop_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserSubscription_Stddev_Samp_Fields = {
  __typename?: "UserSubscription_stddev_samp_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "UserSubscription" */
export type UserSubscription_Stddev_Samp_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type UserSubscription_Sum_Fields = {
  __typename?: "UserSubscription_sum_fields";
  extraTime?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "UserSubscription" */
export type UserSubscription_Sum_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** update columns of table "UserSubscription" */
export enum UserSubscription_Update_Column {
  /** column name */
  CancelUrl = "cancelUrl",
  /** column name */
  CancellationEffectiveDate = "cancellationEffectiveDate",
  /** column name */
  ExtraTime = "extraTime",
  /** column name */
  Status = "status",
  /** column name */
  SubscriptionId = "subscriptionId",
  /** column name */
  SubscriptionPlanId = "subscriptionPlanId",
  /** column name */
  UpdateUrl = "updateUrl",
  /** column name */
  UserId = "userId",
}

/** aggregate var_pop on columns */
export type UserSubscription_Var_Pop_Fields = {
  __typename?: "UserSubscription_var_pop_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "UserSubscription" */
export type UserSubscription_Var_Pop_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserSubscription_Var_Samp_Fields = {
  __typename?: "UserSubscription_var_samp_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "UserSubscription" */
export type UserSubscription_Var_Samp_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type UserSubscription_Variance_Fields = {
  __typename?: "UserSubscription_variance_fields";
  extraTime?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "UserSubscription" */
export type UserSubscription_Variance_Order_By = {
  extraTime?: Maybe<Order_By>;
};

/** aggregated selection of "User" */
export type User_Aggregate = {
  __typename?: "User_aggregate";
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type User_Aggregate_Fields = {
  __typename?: "User_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "User" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "User" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "User" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  Budgets?: Maybe<Budget_Bool_Exp>;
  Messages?: Maybe<Message_Bool_Exp>;
  SessionParticipations?: Maybe<SessionParticipation_Bool_Exp>;
  Sessions?: Maybe<Session_Bool_Exp>;
  UserStreamingServices?: Maybe<UserStreamingService_Bool_Exp>;
  UserSubscription?: Maybe<UserSubscription_Bool_Exp>;
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  avatar_version?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "User" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = "User_email_key",
  /** unique or primary key constraint */
  UserUsernameKey = "User_username_key",
  /** unique or primary key constraint */
  UsersPkey = "users_pkey",
}

/** input type for inserting data into table "User" */
export type User_Insert_Input = {
  Budgets?: Maybe<Budget_Arr_Rel_Insert_Input>;
  Messages?: Maybe<Message_Arr_Rel_Insert_Input>;
  SessionParticipations?: Maybe<SessionParticipation_Arr_Rel_Insert_Input>;
  Sessions?: Maybe<Session_Arr_Rel_Insert_Input>;
  UserStreamingServices?: Maybe<UserStreamingService_Arr_Rel_Insert_Input>;
  UserSubscription?: Maybe<UserSubscription_Obj_Rel_Insert_Input>;
  avatar_version?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: "User_max_fields";
  avatar_version?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "User" */
export type User_Max_Order_By = {
  avatar_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: "User_min_fields";
  avatar_version?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "User" */
export type User_Min_Order_By = {
  avatar_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** response of any mutation on the table "User" */
export type User_Mutation_Response = {
  __typename?: "User_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "User" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "User" */
export type User_Order_By = {
  Budgets_aggregate?: Maybe<Budget_Aggregate_Order_By>;
  Messages_aggregate?: Maybe<Message_Aggregate_Order_By>;
  SessionParticipations_aggregate?: Maybe<SessionParticipation_Aggregate_Order_By>;
  Sessions_aggregate?: Maybe<Session_Aggregate_Order_By>;
  UserStreamingServices_aggregate?: Maybe<UserStreamingService_Aggregate_Order_By>;
  UserSubscription?: Maybe<UserSubscription_Order_By>;
  avatar_version?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** primary key columns input for table: "User" */
export type User_Pk_Columns_Input = {
  id: Scalars["String"];
};

/** select columns of table "User" */
export enum User_Select_Column {
  /** column name */
  AvatarVersion = "avatar_version",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Username = "username",
}

/** input type for updating data in table "User" */
export type User_Set_Input = {
  avatar_version?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** update columns of table "User" */
export enum User_Update_Column {
  /** column name */
  AvatarVersion = "avatar_version",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Username = "username",
}

/** columns and relationships of "VideoChatServer" */
export type VideoChatServer = {
  __typename?: "VideoChatServer";
  /** An array relationship */
  Sessions: Array<Session>;
  /** An aggregated array relationship */
  Sessions_aggregate: Session_Aggregate;
  hostname: Scalars["String"];
  id: Scalars["Int"];
};

/** columns and relationships of "VideoChatServer" */
export type VideoChatServerSessionsArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** columns and relationships of "VideoChatServer" */
export type VideoChatServerSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** aggregated selection of "VideoChatServer" */
export type VideoChatServer_Aggregate = {
  __typename?: "VideoChatServer_aggregate";
  aggregate?: Maybe<VideoChatServer_Aggregate_Fields>;
  nodes: Array<VideoChatServer>;
};

/** aggregate fields of "VideoChatServer" */
export type VideoChatServer_Aggregate_Fields = {
  __typename?: "VideoChatServer_aggregate_fields";
  avg?: Maybe<VideoChatServer_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<VideoChatServer_Max_Fields>;
  min?: Maybe<VideoChatServer_Min_Fields>;
  stddev?: Maybe<VideoChatServer_Stddev_Fields>;
  stddev_pop?: Maybe<VideoChatServer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VideoChatServer_Stddev_Samp_Fields>;
  sum?: Maybe<VideoChatServer_Sum_Fields>;
  var_pop?: Maybe<VideoChatServer_Var_Pop_Fields>;
  var_samp?: Maybe<VideoChatServer_Var_Samp_Fields>;
  variance?: Maybe<VideoChatServer_Variance_Fields>;
};

/** aggregate fields of "VideoChatServer" */
export type VideoChatServer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<VideoChatServer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "VideoChatServer" */
export type VideoChatServer_Aggregate_Order_By = {
  avg?: Maybe<VideoChatServer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<VideoChatServer_Max_Order_By>;
  min?: Maybe<VideoChatServer_Min_Order_By>;
  stddev?: Maybe<VideoChatServer_Stddev_Order_By>;
  stddev_pop?: Maybe<VideoChatServer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<VideoChatServer_Stddev_Samp_Order_By>;
  sum?: Maybe<VideoChatServer_Sum_Order_By>;
  var_pop?: Maybe<VideoChatServer_Var_Pop_Order_By>;
  var_samp?: Maybe<VideoChatServer_Var_Samp_Order_By>;
  variance?: Maybe<VideoChatServer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "VideoChatServer" */
export type VideoChatServer_Arr_Rel_Insert_Input = {
  data: Array<VideoChatServer_Insert_Input>;
  on_conflict?: Maybe<VideoChatServer_On_Conflict>;
};

/** aggregate avg on columns */
export type VideoChatServer_Avg_Fields = {
  __typename?: "VideoChatServer_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "VideoChatServer" */
export type VideoChatServer_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "VideoChatServer". All fields are combined with a logical 'AND'. */
export type VideoChatServer_Bool_Exp = {
  Sessions?: Maybe<Session_Bool_Exp>;
  _and?: Maybe<Array<Maybe<VideoChatServer_Bool_Exp>>>;
  _not?: Maybe<VideoChatServer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<VideoChatServer_Bool_Exp>>>;
  hostname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "VideoChatServer" */
export enum VideoChatServer_Constraint {
  /** unique or primary key constraint */
  VideoChatServerPkey = "VideoChatServer_pkey",
}

/** input type for incrementing integer column in table "VideoChatServer" */
export type VideoChatServer_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "VideoChatServer" */
export type VideoChatServer_Insert_Input = {
  Sessions?: Maybe<Session_Arr_Rel_Insert_Input>;
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type VideoChatServer_Max_Fields = {
  __typename?: "VideoChatServer_max_fields";
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "VideoChatServer" */
export type VideoChatServer_Max_Order_By = {
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type VideoChatServer_Min_Fields = {
  __typename?: "VideoChatServer_min_fields";
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "VideoChatServer" */
export type VideoChatServer_Min_Order_By = {
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "VideoChatServer" */
export type VideoChatServer_Mutation_Response = {
  __typename?: "VideoChatServer_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<VideoChatServer>;
};

/** input type for inserting object relation for remote table "VideoChatServer" */
export type VideoChatServer_Obj_Rel_Insert_Input = {
  data: VideoChatServer_Insert_Input;
  on_conflict?: Maybe<VideoChatServer_On_Conflict>;
};

/** on conflict condition type for table "VideoChatServer" */
export type VideoChatServer_On_Conflict = {
  constraint: VideoChatServer_Constraint;
  update_columns: Array<VideoChatServer_Update_Column>;
  where?: Maybe<VideoChatServer_Bool_Exp>;
};

/** ordering options when selecting data from "VideoChatServer" */
export type VideoChatServer_Order_By = {
  Sessions_aggregate?: Maybe<Session_Aggregate_Order_By>;
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "VideoChatServer" */
export type VideoChatServer_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "VideoChatServer" */
export enum VideoChatServer_Select_Column {
  /** column name */
  Hostname = "hostname",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "VideoChatServer" */
export type VideoChatServer_Set_Input = {
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type VideoChatServer_Stddev_Fields = {
  __typename?: "VideoChatServer_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "VideoChatServer" */
export type VideoChatServer_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VideoChatServer_Stddev_Pop_Fields = {
  __typename?: "VideoChatServer_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "VideoChatServer" */
export type VideoChatServer_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VideoChatServer_Stddev_Samp_Fields = {
  __typename?: "VideoChatServer_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "VideoChatServer" */
export type VideoChatServer_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type VideoChatServer_Sum_Fields = {
  __typename?: "VideoChatServer_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "VideoChatServer" */
export type VideoChatServer_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "VideoChatServer" */
export enum VideoChatServer_Update_Column {
  /** column name */
  Hostname = "hostname",
  /** column name */
  Id = "id",
}

/** aggregate var_pop on columns */
export type VideoChatServer_Var_Pop_Fields = {
  __typename?: "VideoChatServer_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "VideoChatServer" */
export type VideoChatServer_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VideoChatServer_Var_Samp_Fields = {
  __typename?: "VideoChatServer_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "VideoChatServer" */
export type VideoChatServer_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type VideoChatServer_Variance_Fields = {
  __typename?: "VideoChatServer_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "VideoChatServer" */
export type VideoChatServer_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars["float8"]>;
  _gt?: Maybe<Scalars["float8"]>;
  _gte?: Maybe<Scalars["float8"]>;
  _in?: Maybe<Array<Scalars["float8"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["float8"]>;
  _lte?: Maybe<Scalars["float8"]>;
  _neq?: Maybe<Scalars["float8"]>;
  _nin?: Maybe<Array<Scalars["float8"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "Budget" */
  delete_Budget?: Maybe<Budget_Mutation_Response>;
  /** delete single row from the table: "Budget" */
  delete_Budget_by_pk?: Maybe<Budget>;
  /** delete data from the table: "CurrentBudget" */
  delete_CurrentBudget?: Maybe<CurrentBudget_Mutation_Response>;
  /** delete data from the table: "ExternalToInternalContent" */
  delete_ExternalToInternalContent?: Maybe<ExternalToInternalContent_Mutation_Response>;
  /** delete single row from the table: "ExternalToInternalContent" */
  delete_ExternalToInternalContent_by_pk?: Maybe<ExternalToInternalContent>;
  /** delete data from the table: "Genre" */
  delete_Genre?: Maybe<Genre_Mutation_Response>;
  /** delete data from the table: "GenreI18N" */
  delete_GenreI18N?: Maybe<GenreI18N_Mutation_Response>;
  /** delete single row from the table: "GenreI18N" */
  delete_GenreI18N_by_pk?: Maybe<GenreI18N>;
  /** delete data from the table: "GenreMediaContent" */
  delete_GenreMediaContent?: Maybe<GenreMediaContent_Mutation_Response>;
  /** delete single row from the table: "GenreMediaContent" */
  delete_GenreMediaContent_by_pk?: Maybe<GenreMediaContent>;
  /** delete single row from the table: "Genre" */
  delete_Genre_by_pk?: Maybe<Genre>;
  /** delete data from the table: "Languages" */
  delete_Languages?: Maybe<Languages_Mutation_Response>;
  /** delete single row from the table: "Languages" */
  delete_Languages_by_pk?: Maybe<Languages>;
  /** delete data from the table: "MediaContent" */
  delete_MediaContent?: Maybe<MediaContent_Mutation_Response>;
  /** delete data from the table: "MediaContentI18N" */
  delete_MediaContentI18N?: Maybe<MediaContentI18N_Mutation_Response>;
  /** delete single row from the table: "MediaContentI18N" */
  delete_MediaContentI18N_by_pk?: Maybe<MediaContentI18N>;
  /** delete data from the table: "MediaContentReferences" */
  delete_MediaContentReferences?: Maybe<MediaContentReferences_Mutation_Response>;
  /** delete single row from the table: "MediaContentReferences" */
  delete_MediaContentReferences_by_pk?: Maybe<MediaContentReferences>;
  /** delete single row from the table: "MediaContent" */
  delete_MediaContent_by_pk?: Maybe<MediaContent>;
  /** delete data from the table: "MediaType" */
  delete_MediaType?: Maybe<MediaType_Mutation_Response>;
  /** delete single row from the table: "MediaType" */
  delete_MediaType_by_pk?: Maybe<MediaType>;
  /** delete data from the table: "Message" */
  delete_Message?: Maybe<Message_Mutation_Response>;
  /** delete single row from the table: "Message" */
  delete_Message_by_pk?: Maybe<Message>;
  /** delete data from the table: "MostWatchedContent" */
  delete_MostWatchedContent?: Maybe<MostWatchedContent_Mutation_Response>;
  /** delete data from the table: "PublicParties" */
  delete_PublicParties?: Maybe<PublicParties_Mutation_Response>;
  /** delete data from the table: "Session" */
  delete_Session?: Maybe<Session_Mutation_Response>;
  /** delete data from the table: "SessionParticipation" */
  delete_SessionParticipation?: Maybe<SessionParticipation_Mutation_Response>;
  /** delete single row from the table: "SessionParticipation" */
  delete_SessionParticipation_by_pk?: Maybe<SessionParticipation>;
  /** delete single row from the table: "Session" */
  delete_Session_by_pk?: Maybe<Session>;
  /** delete data from the table: "StreamingService" */
  delete_StreamingService?: Maybe<StreamingService_Mutation_Response>;
  /** delete single row from the table: "StreamingService" */
  delete_StreamingService_by_pk?: Maybe<StreamingService>;
  /** delete data from the table: "SubscriptionPlanTier" */
  delete_SubscriptionPlanTier?: Maybe<SubscriptionPlanTier_Mutation_Response>;
  /** delete single row from the table: "SubscriptionPlanTier" */
  delete_SubscriptionPlanTier_by_pk?: Maybe<SubscriptionPlanTier>;
  /** delete data from the table: "Tier" */
  delete_Tier?: Maybe<Tier_Mutation_Response>;
  /** delete single row from the table: "Tier" */
  delete_Tier_by_pk?: Maybe<Tier>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "UserGeneratedContent" */
  delete_UserGeneratedContent?: Maybe<UserGeneratedContent_Mutation_Response>;
  /** delete single row from the table: "UserGeneratedContent" */
  delete_UserGeneratedContent_by_pk?: Maybe<UserGeneratedContent>;
  /** delete data from the table: "UserPublic" */
  delete_UserPublic?: Maybe<UserPublic_Mutation_Response>;
  /** delete data from the table: "UserStreamingService" */
  delete_UserStreamingService?: Maybe<UserStreamingService_Mutation_Response>;
  /** delete single row from the table: "UserStreamingService" */
  delete_UserStreamingService_by_pk?: Maybe<UserStreamingService>;
  /** delete data from the table: "UserSubscription" */
  delete_UserSubscription?: Maybe<UserSubscription_Mutation_Response>;
  /** delete single row from the table: "UserSubscription" */
  delete_UserSubscription_by_pk?: Maybe<UserSubscription>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** delete data from the table: "VideoChatServer" */
  delete_VideoChatServer?: Maybe<VideoChatServer_Mutation_Response>;
  /** delete single row from the table: "VideoChatServer" */
  delete_VideoChatServer_by_pk?: Maybe<VideoChatServer>;
  /** insert data into the table: "Budget" */
  insert_Budget?: Maybe<Budget_Mutation_Response>;
  /** insert a single row into the table: "Budget" */
  insert_Budget_one?: Maybe<Budget>;
  /** insert data into the table: "CurrentBudget" */
  insert_CurrentBudget?: Maybe<CurrentBudget_Mutation_Response>;
  /** insert a single row into the table: "CurrentBudget" */
  insert_CurrentBudget_one?: Maybe<CurrentBudget>;
  /** insert data into the table: "ExternalToInternalContent" */
  insert_ExternalToInternalContent?: Maybe<ExternalToInternalContent_Mutation_Response>;
  /** insert a single row into the table: "ExternalToInternalContent" */
  insert_ExternalToInternalContent_one?: Maybe<ExternalToInternalContent>;
  /** insert data into the table: "Genre" */
  insert_Genre?: Maybe<Genre_Mutation_Response>;
  /** insert data into the table: "GenreI18N" */
  insert_GenreI18N?: Maybe<GenreI18N_Mutation_Response>;
  /** insert a single row into the table: "GenreI18N" */
  insert_GenreI18N_one?: Maybe<GenreI18N>;
  /** insert data into the table: "GenreMediaContent" */
  insert_GenreMediaContent?: Maybe<GenreMediaContent_Mutation_Response>;
  /** insert a single row into the table: "GenreMediaContent" */
  insert_GenreMediaContent_one?: Maybe<GenreMediaContent>;
  /** insert a single row into the table: "Genre" */
  insert_Genre_one?: Maybe<Genre>;
  /** insert data into the table: "Languages" */
  insert_Languages?: Maybe<Languages_Mutation_Response>;
  /** insert a single row into the table: "Languages" */
  insert_Languages_one?: Maybe<Languages>;
  /** insert data into the table: "MediaContent" */
  insert_MediaContent?: Maybe<MediaContent_Mutation_Response>;
  /** insert data into the table: "MediaContentI18N" */
  insert_MediaContentI18N?: Maybe<MediaContentI18N_Mutation_Response>;
  /** insert a single row into the table: "MediaContentI18N" */
  insert_MediaContentI18N_one?: Maybe<MediaContentI18N>;
  /** insert data into the table: "MediaContentReferences" */
  insert_MediaContentReferences?: Maybe<MediaContentReferences_Mutation_Response>;
  /** insert a single row into the table: "MediaContentReferences" */
  insert_MediaContentReferences_one?: Maybe<MediaContentReferences>;
  /** insert a single row into the table: "MediaContent" */
  insert_MediaContent_one?: Maybe<MediaContent>;
  /** insert data into the table: "MediaType" */
  insert_MediaType?: Maybe<MediaType_Mutation_Response>;
  /** insert a single row into the table: "MediaType" */
  insert_MediaType_one?: Maybe<MediaType>;
  /** insert data into the table: "Message" */
  insert_Message?: Maybe<Message_Mutation_Response>;
  /** insert a single row into the table: "Message" */
  insert_Message_one?: Maybe<Message>;
  /** insert data into the table: "MostWatchedContent" */
  insert_MostWatchedContent?: Maybe<MostWatchedContent_Mutation_Response>;
  /** insert a single row into the table: "MostWatchedContent" */
  insert_MostWatchedContent_one?: Maybe<MostWatchedContent>;
  /** insert data into the table: "PublicParties" */
  insert_PublicParties?: Maybe<PublicParties_Mutation_Response>;
  /** insert a single row into the table: "PublicParties" */
  insert_PublicParties_one?: Maybe<PublicParties>;
  /** insert data into the table: "Session" */
  insert_Session?: Maybe<Session_Mutation_Response>;
  /** insert data into the table: "SessionParticipation" */
  insert_SessionParticipation?: Maybe<SessionParticipation_Mutation_Response>;
  /** insert a single row into the table: "SessionParticipation" */
  insert_SessionParticipation_one?: Maybe<SessionParticipation>;
  /** insert a single row into the table: "Session" */
  insert_Session_one?: Maybe<Session>;
  /** insert data into the table: "StreamingService" */
  insert_StreamingService?: Maybe<StreamingService_Mutation_Response>;
  /** insert a single row into the table: "StreamingService" */
  insert_StreamingService_one?: Maybe<StreamingService>;
  /** insert data into the table: "SubscriptionPlanTier" */
  insert_SubscriptionPlanTier?: Maybe<SubscriptionPlanTier_Mutation_Response>;
  /** insert a single row into the table: "SubscriptionPlanTier" */
  insert_SubscriptionPlanTier_one?: Maybe<SubscriptionPlanTier>;
  /** insert data into the table: "Tier" */
  insert_Tier?: Maybe<Tier_Mutation_Response>;
  /** insert a single row into the table: "Tier" */
  insert_Tier_one?: Maybe<Tier>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "UserGeneratedContent" */
  insert_UserGeneratedContent?: Maybe<UserGeneratedContent_Mutation_Response>;
  /** insert a single row into the table: "UserGeneratedContent" */
  insert_UserGeneratedContent_one?: Maybe<UserGeneratedContent>;
  /** insert data into the table: "UserPublic" */
  insert_UserPublic?: Maybe<UserPublic_Mutation_Response>;
  /** insert a single row into the table: "UserPublic" */
  insert_UserPublic_one?: Maybe<UserPublic>;
  /** insert data into the table: "UserStreamingService" */
  insert_UserStreamingService?: Maybe<UserStreamingService_Mutation_Response>;
  /** insert a single row into the table: "UserStreamingService" */
  insert_UserStreamingService_one?: Maybe<UserStreamingService>;
  /** insert data into the table: "UserSubscription" */
  insert_UserSubscription?: Maybe<UserSubscription_Mutation_Response>;
  /** insert a single row into the table: "UserSubscription" */
  insert_UserSubscription_one?: Maybe<UserSubscription>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** insert data into the table: "VideoChatServer" */
  insert_VideoChatServer?: Maybe<VideoChatServer_Mutation_Response>;
  /** insert a single row into the table: "VideoChatServer" */
  insert_VideoChatServer_one?: Maybe<VideoChatServer>;
  /** update data of the table: "Budget" */
  update_Budget?: Maybe<Budget_Mutation_Response>;
  /** update single row of the table: "Budget" */
  update_Budget_by_pk?: Maybe<Budget>;
  /** update data of the table: "CurrentBudget" */
  update_CurrentBudget?: Maybe<CurrentBudget_Mutation_Response>;
  /** update data of the table: "ExternalToInternalContent" */
  update_ExternalToInternalContent?: Maybe<ExternalToInternalContent_Mutation_Response>;
  /** update single row of the table: "ExternalToInternalContent" */
  update_ExternalToInternalContent_by_pk?: Maybe<ExternalToInternalContent>;
  /** update data of the table: "Genre" */
  update_Genre?: Maybe<Genre_Mutation_Response>;
  /** update data of the table: "GenreI18N" */
  update_GenreI18N?: Maybe<GenreI18N_Mutation_Response>;
  /** update single row of the table: "GenreI18N" */
  update_GenreI18N_by_pk?: Maybe<GenreI18N>;
  /** update data of the table: "GenreMediaContent" */
  update_GenreMediaContent?: Maybe<GenreMediaContent_Mutation_Response>;
  /** update single row of the table: "GenreMediaContent" */
  update_GenreMediaContent_by_pk?: Maybe<GenreMediaContent>;
  /** update single row of the table: "Genre" */
  update_Genre_by_pk?: Maybe<Genre>;
  /** update data of the table: "Languages" */
  update_Languages?: Maybe<Languages_Mutation_Response>;
  /** update single row of the table: "Languages" */
  update_Languages_by_pk?: Maybe<Languages>;
  /** update data of the table: "MediaContent" */
  update_MediaContent?: Maybe<MediaContent_Mutation_Response>;
  /** update data of the table: "MediaContentI18N" */
  update_MediaContentI18N?: Maybe<MediaContentI18N_Mutation_Response>;
  /** update single row of the table: "MediaContentI18N" */
  update_MediaContentI18N_by_pk?: Maybe<MediaContentI18N>;
  /** update data of the table: "MediaContentReferences" */
  update_MediaContentReferences?: Maybe<MediaContentReferences_Mutation_Response>;
  /** update single row of the table: "MediaContentReferences" */
  update_MediaContentReferences_by_pk?: Maybe<MediaContentReferences>;
  /** update single row of the table: "MediaContent" */
  update_MediaContent_by_pk?: Maybe<MediaContent>;
  /** update data of the table: "MediaType" */
  update_MediaType?: Maybe<MediaType_Mutation_Response>;
  /** update single row of the table: "MediaType" */
  update_MediaType_by_pk?: Maybe<MediaType>;
  /** update data of the table: "Message" */
  update_Message?: Maybe<Message_Mutation_Response>;
  /** update single row of the table: "Message" */
  update_Message_by_pk?: Maybe<Message>;
  /** update data of the table: "MostWatchedContent" */
  update_MostWatchedContent?: Maybe<MostWatchedContent_Mutation_Response>;
  /** update data of the table: "PublicParties" */
  update_PublicParties?: Maybe<PublicParties_Mutation_Response>;
  /** update data of the table: "Session" */
  update_Session?: Maybe<Session_Mutation_Response>;
  /** update data of the table: "SessionParticipation" */
  update_SessionParticipation?: Maybe<SessionParticipation_Mutation_Response>;
  /** update single row of the table: "SessionParticipation" */
  update_SessionParticipation_by_pk?: Maybe<SessionParticipation>;
  /** update single row of the table: "Session" */
  update_Session_by_pk?: Maybe<Session>;
  /** update data of the table: "StreamingService" */
  update_StreamingService?: Maybe<StreamingService_Mutation_Response>;
  /** update single row of the table: "StreamingService" */
  update_StreamingService_by_pk?: Maybe<StreamingService>;
  /** update data of the table: "SubscriptionPlanTier" */
  update_SubscriptionPlanTier?: Maybe<SubscriptionPlanTier_Mutation_Response>;
  /** update single row of the table: "SubscriptionPlanTier" */
  update_SubscriptionPlanTier_by_pk?: Maybe<SubscriptionPlanTier>;
  /** update data of the table: "Tier" */
  update_Tier?: Maybe<Tier_Mutation_Response>;
  /** update single row of the table: "Tier" */
  update_Tier_by_pk?: Maybe<Tier>;
  /** update data of the table: "User" */
  update_User?: Maybe<User_Mutation_Response>;
  /** update data of the table: "UserGeneratedContent" */
  update_UserGeneratedContent?: Maybe<UserGeneratedContent_Mutation_Response>;
  /** update single row of the table: "UserGeneratedContent" */
  update_UserGeneratedContent_by_pk?: Maybe<UserGeneratedContent>;
  /** update data of the table: "UserPublic" */
  update_UserPublic?: Maybe<UserPublic_Mutation_Response>;
  /** update data of the table: "UserStreamingService" */
  update_UserStreamingService?: Maybe<UserStreamingService_Mutation_Response>;
  /** update single row of the table: "UserStreamingService" */
  update_UserStreamingService_by_pk?: Maybe<UserStreamingService>;
  /** update data of the table: "UserSubscription" */
  update_UserSubscription?: Maybe<UserSubscription_Mutation_Response>;
  /** update single row of the table: "UserSubscription" */
  update_UserSubscription_by_pk?: Maybe<UserSubscription>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
  /** update data of the table: "VideoChatServer" */
  update_VideoChatServer?: Maybe<VideoChatServer_Mutation_Response>;
  /** update single row of the table: "VideoChatServer" */
  update_VideoChatServer_by_pk?: Maybe<VideoChatServer>;
};

/** mutation root */
export type Mutation_RootDelete_BudgetArgs = {
  where: Budget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Budget_By_PkArgs = {
  month: Scalars["Int"];
  userId: Scalars["String"];
  year: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_CurrentBudgetArgs = {
  where: CurrentBudget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ExternalToInternalContentArgs = {
  where: ExternalToInternalContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ExternalToInternalContent_By_PkArgs = {
  host: Scalars["String"];
  hostContentId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_GenreArgs = {
  where: Genre_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GenreI18NArgs = {
  where: GenreI18N_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GenreI18N_By_PkArgs = {
  id: Scalars["Int"];
  language: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_GenreMediaContentArgs = {
  where: GenreMediaContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GenreMediaContent_By_PkArgs = {
  genreId: Scalars["Int"];
  mediaContentId: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Genre_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_LanguagesArgs = {
  where: Languages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Languages_By_PkArgs = {
  code: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_MediaContentArgs = {
  where: MediaContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MediaContentI18NArgs = {
  where: MediaContentI18N_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MediaContentI18N_By_PkArgs = {
  id: Scalars["uuid"];
  language: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_MediaContentReferencesArgs = {
  where: MediaContentReferences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MediaContentReferences_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_MediaContent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_MediaTypeArgs = {
  where: MediaType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MediaType_By_PkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_MessageArgs = {
  where: Message_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Message_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_MostWatchedContentArgs = {
  where: MostWatchedContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_PublicPartiesArgs = {
  where: PublicParties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SessionParticipationArgs = {
  where: SessionParticipation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SessionParticipation_By_PkArgs = {
  sessionId: Scalars["uuid"];
  userId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Session_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_StreamingServiceArgs = {
  where: StreamingService_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_StreamingService_By_PkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_SubscriptionPlanTierArgs = {
  where: SubscriptionPlanTier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SubscriptionPlanTier_By_PkArgs = {
  subscriptionPlanId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_TierArgs = {
  where: Tier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tier_By_PkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserGeneratedContentArgs = {
  where: UserGeneratedContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserGeneratedContent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_UserPublicArgs = {
  where: UserPublic_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserStreamingServiceArgs = {
  where: UserStreamingService_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserStreamingService_By_PkArgs = {
  serviceId: Scalars["String"];
  userId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_UserSubscriptionArgs = {
  where: UserSubscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserSubscription_By_PkArgs = {
  userId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_VideoChatServerArgs = {
  where: VideoChatServer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_VideoChatServer_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootInsert_BudgetArgs = {
  objects: Array<Budget_Insert_Input>;
  on_conflict?: Maybe<Budget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Budget_OneArgs = {
  object: Budget_Insert_Input;
  on_conflict?: Maybe<Budget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CurrentBudgetArgs = {
  objects: Array<CurrentBudget_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_CurrentBudget_OneArgs = {
  object: CurrentBudget_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_ExternalToInternalContentArgs = {
  objects: Array<ExternalToInternalContent_Insert_Input>;
  on_conflict?: Maybe<ExternalToInternalContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ExternalToInternalContent_OneArgs = {
  object: ExternalToInternalContent_Insert_Input;
  on_conflict?: Maybe<ExternalToInternalContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GenreArgs = {
  objects: Array<Genre_Insert_Input>;
  on_conflict?: Maybe<Genre_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GenreI18NArgs = {
  objects: Array<GenreI18N_Insert_Input>;
  on_conflict?: Maybe<GenreI18N_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GenreI18N_OneArgs = {
  object: GenreI18N_Insert_Input;
  on_conflict?: Maybe<GenreI18N_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GenreMediaContentArgs = {
  objects: Array<GenreMediaContent_Insert_Input>;
  on_conflict?: Maybe<GenreMediaContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GenreMediaContent_OneArgs = {
  object: GenreMediaContent_Insert_Input;
  on_conflict?: Maybe<GenreMediaContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Genre_OneArgs = {
  object: Genre_Insert_Input;
  on_conflict?: Maybe<Genre_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LanguagesArgs = {
  objects: Array<Languages_Insert_Input>;
  on_conflict?: Maybe<Languages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Languages_OneArgs = {
  object: Languages_Insert_Input;
  on_conflict?: Maybe<Languages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaContentArgs = {
  objects: Array<MediaContent_Insert_Input>;
  on_conflict?: Maybe<MediaContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaContentI18NArgs = {
  objects: Array<MediaContentI18N_Insert_Input>;
  on_conflict?: Maybe<MediaContentI18N_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaContentI18N_OneArgs = {
  object: MediaContentI18N_Insert_Input;
  on_conflict?: Maybe<MediaContentI18N_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaContentReferencesArgs = {
  objects: Array<MediaContentReferences_Insert_Input>;
  on_conflict?: Maybe<MediaContentReferences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaContentReferences_OneArgs = {
  object: MediaContentReferences_Insert_Input;
  on_conflict?: Maybe<MediaContentReferences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaContent_OneArgs = {
  object: MediaContent_Insert_Input;
  on_conflict?: Maybe<MediaContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaTypeArgs = {
  objects: Array<MediaType_Insert_Input>;
  on_conflict?: Maybe<MediaType_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MediaType_OneArgs = {
  object: MediaType_Insert_Input;
  on_conflict?: Maybe<MediaType_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MessageArgs = {
  objects: Array<Message_Insert_Input>;
  on_conflict?: Maybe<Message_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Message_OneArgs = {
  object: Message_Insert_Input;
  on_conflict?: Maybe<Message_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MostWatchedContentArgs = {
  objects: Array<MostWatchedContent_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_MostWatchedContent_OneArgs = {
  object: MostWatchedContent_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_PublicPartiesArgs = {
  objects: Array<PublicParties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_PublicParties_OneArgs = {
  object: PublicParties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SessionParticipationArgs = {
  objects: Array<SessionParticipation_Insert_Input>;
  on_conflict?: Maybe<SessionParticipation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SessionParticipation_OneArgs = {
  object: SessionParticipation_Insert_Input;
  on_conflict?: Maybe<SessionParticipation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_StreamingServiceArgs = {
  objects: Array<StreamingService_Insert_Input>;
  on_conflict?: Maybe<StreamingService_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_StreamingService_OneArgs = {
  object: StreamingService_Insert_Input;
  on_conflict?: Maybe<StreamingService_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SubscriptionPlanTierArgs = {
  objects: Array<SubscriptionPlanTier_Insert_Input>;
  on_conflict?: Maybe<SubscriptionPlanTier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SubscriptionPlanTier_OneArgs = {
  object: SubscriptionPlanTier_Insert_Input;
  on_conflict?: Maybe<SubscriptionPlanTier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TierArgs = {
  objects: Array<Tier_Insert_Input>;
  on_conflict?: Maybe<Tier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tier_OneArgs = {
  object: Tier_Insert_Input;
  on_conflict?: Maybe<Tier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserGeneratedContentArgs = {
  objects: Array<UserGeneratedContent_Insert_Input>;
  on_conflict?: Maybe<UserGeneratedContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserGeneratedContent_OneArgs = {
  object: UserGeneratedContent_Insert_Input;
  on_conflict?: Maybe<UserGeneratedContent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserPublicArgs = {
  objects: Array<UserPublic_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_UserPublic_OneArgs = {
  object: UserPublic_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_UserStreamingServiceArgs = {
  objects: Array<UserStreamingService_Insert_Input>;
  on_conflict?: Maybe<UserStreamingService_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserStreamingService_OneArgs = {
  object: UserStreamingService_Insert_Input;
  on_conflict?: Maybe<UserStreamingService_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserSubscriptionArgs = {
  objects: Array<UserSubscription_Insert_Input>;
  on_conflict?: Maybe<UserSubscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserSubscription_OneArgs = {
  object: UserSubscription_Insert_Input;
  on_conflict?: Maybe<UserSubscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VideoChatServerArgs = {
  objects: Array<VideoChatServer_Insert_Input>;
  on_conflict?: Maybe<VideoChatServer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VideoChatServer_OneArgs = {
  object: VideoChatServer_Insert_Input;
  on_conflict?: Maybe<VideoChatServer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_BudgetArgs = {
  _inc?: Maybe<Budget_Inc_Input>;
  _set?: Maybe<Budget_Set_Input>;
  where: Budget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Budget_By_PkArgs = {
  _inc?: Maybe<Budget_Inc_Input>;
  _set?: Maybe<Budget_Set_Input>;
  pk_columns: Budget_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CurrentBudgetArgs = {
  _inc?: Maybe<CurrentBudget_Inc_Input>;
  _set?: Maybe<CurrentBudget_Set_Input>;
  where: CurrentBudget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ExternalToInternalContentArgs = {
  _set?: Maybe<ExternalToInternalContent_Set_Input>;
  where: ExternalToInternalContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ExternalToInternalContent_By_PkArgs = {
  _set?: Maybe<ExternalToInternalContent_Set_Input>;
  pk_columns: ExternalToInternalContent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GenreArgs = {
  _inc?: Maybe<Genre_Inc_Input>;
  _set?: Maybe<Genre_Set_Input>;
  where: Genre_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GenreI18NArgs = {
  _inc?: Maybe<GenreI18N_Inc_Input>;
  _set?: Maybe<GenreI18N_Set_Input>;
  where: GenreI18N_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GenreI18N_By_PkArgs = {
  _inc?: Maybe<GenreI18N_Inc_Input>;
  _set?: Maybe<GenreI18N_Set_Input>;
  pk_columns: GenreI18N_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GenreMediaContentArgs = {
  _inc?: Maybe<GenreMediaContent_Inc_Input>;
  _set?: Maybe<GenreMediaContent_Set_Input>;
  where: GenreMediaContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GenreMediaContent_By_PkArgs = {
  _inc?: Maybe<GenreMediaContent_Inc_Input>;
  _set?: Maybe<GenreMediaContent_Set_Input>;
  pk_columns: GenreMediaContent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Genre_By_PkArgs = {
  _inc?: Maybe<Genre_Inc_Input>;
  _set?: Maybe<Genre_Set_Input>;
  pk_columns: Genre_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_LanguagesArgs = {
  _set?: Maybe<Languages_Set_Input>;
  where: Languages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Languages_By_PkArgs = {
  _set?: Maybe<Languages_Set_Input>;
  pk_columns: Languages_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MediaContentArgs = {
  _inc?: Maybe<MediaContent_Inc_Input>;
  _set?: Maybe<MediaContent_Set_Input>;
  where: MediaContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MediaContentI18NArgs = {
  _set?: Maybe<MediaContentI18N_Set_Input>;
  where: MediaContentI18N_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MediaContentI18N_By_PkArgs = {
  _set?: Maybe<MediaContentI18N_Set_Input>;
  pk_columns: MediaContentI18N_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MediaContentReferencesArgs = {
  _inc?: Maybe<MediaContentReferences_Inc_Input>;
  _set?: Maybe<MediaContentReferences_Set_Input>;
  where: MediaContentReferences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MediaContentReferences_By_PkArgs = {
  _inc?: Maybe<MediaContentReferences_Inc_Input>;
  _set?: Maybe<MediaContentReferences_Set_Input>;
  pk_columns: MediaContentReferences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MediaContent_By_PkArgs = {
  _inc?: Maybe<MediaContent_Inc_Input>;
  _set?: Maybe<MediaContent_Set_Input>;
  pk_columns: MediaContent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MediaTypeArgs = {
  _set?: Maybe<MediaType_Set_Input>;
  where: MediaType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MediaType_By_PkArgs = {
  _set?: Maybe<MediaType_Set_Input>;
  pk_columns: MediaType_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MessageArgs = {
  _set?: Maybe<Message_Set_Input>;
  where: Message_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Message_By_PkArgs = {
  _set?: Maybe<Message_Set_Input>;
  pk_columns: Message_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MostWatchedContentArgs = {
  _inc?: Maybe<MostWatchedContent_Inc_Input>;
  _set?: Maybe<MostWatchedContent_Set_Input>;
  where: MostWatchedContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_PublicPartiesArgs = {
  _inc?: Maybe<PublicParties_Inc_Input>;
  _set?: Maybe<PublicParties_Set_Input>;
  where: PublicParties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SessionArgs = {
  _inc?: Maybe<Session_Inc_Input>;
  _set?: Maybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SessionParticipationArgs = {
  _set?: Maybe<SessionParticipation_Set_Input>;
  where: SessionParticipation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SessionParticipation_By_PkArgs = {
  _set?: Maybe<SessionParticipation_Set_Input>;
  pk_columns: SessionParticipation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Session_By_PkArgs = {
  _inc?: Maybe<Session_Inc_Input>;
  _set?: Maybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_StreamingServiceArgs = {
  _set?: Maybe<StreamingService_Set_Input>;
  where: StreamingService_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_StreamingService_By_PkArgs = {
  _set?: Maybe<StreamingService_Set_Input>;
  pk_columns: StreamingService_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SubscriptionPlanTierArgs = {
  _set?: Maybe<SubscriptionPlanTier_Set_Input>;
  where: SubscriptionPlanTier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SubscriptionPlanTier_By_PkArgs = {
  _set?: Maybe<SubscriptionPlanTier_Set_Input>;
  pk_columns: SubscriptionPlanTier_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TierArgs = {
  _inc?: Maybe<Tier_Inc_Input>;
  _set?: Maybe<Tier_Set_Input>;
  where: Tier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tier_By_PkArgs = {
  _inc?: Maybe<Tier_Inc_Input>;
  _set?: Maybe<Tier_Set_Input>;
  pk_columns: Tier_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserGeneratedContentArgs = {
  _inc?: Maybe<UserGeneratedContent_Inc_Input>;
  _set?: Maybe<UserGeneratedContent_Set_Input>;
  where: UserGeneratedContent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserGeneratedContent_By_PkArgs = {
  _inc?: Maybe<UserGeneratedContent_Inc_Input>;
  _set?: Maybe<UserGeneratedContent_Set_Input>;
  pk_columns: UserGeneratedContent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserPublicArgs = {
  _set?: Maybe<UserPublic_Set_Input>;
  where: UserPublic_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserStreamingServiceArgs = {
  _set?: Maybe<UserStreamingService_Set_Input>;
  where: UserStreamingService_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserStreamingService_By_PkArgs = {
  _set?: Maybe<UserStreamingService_Set_Input>;
  pk_columns: UserStreamingService_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserSubscriptionArgs = {
  _inc?: Maybe<UserSubscription_Inc_Input>;
  _set?: Maybe<UserSubscription_Set_Input>;
  where: UserSubscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserSubscription_By_PkArgs = {
  _inc?: Maybe<UserSubscription_Inc_Input>;
  _set?: Maybe<UserSubscription_Set_Input>;
  pk_columns: UserSubscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_VideoChatServerArgs = {
  _inc?: Maybe<VideoChatServer_Inc_Input>;
  _set?: Maybe<VideoChatServer_Set_Input>;
  where: VideoChatServer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_VideoChatServer_By_PkArgs = {
  _inc?: Maybe<VideoChatServer_Inc_Input>;
  _set?: Maybe<VideoChatServer_Set_Input>;
  pk_columns: VideoChatServer_Pk_Columns_Input;
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = "asc",
  /** in the ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in the ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in the descending order, nulls first */
  Desc = "desc",
  /** in the descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in the descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** query root */
export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "Budget" */
  Budget: Array<Budget>;
  /** fetch aggregated fields from the table: "Budget" */
  Budget_aggregate: Budget_Aggregate;
  /** fetch data from the table: "Budget" using primary key columns */
  Budget_by_pk?: Maybe<Budget>;
  /** fetch data from the table: "CurrentBudget" */
  CurrentBudget: Array<CurrentBudget>;
  /** fetch aggregated fields from the table: "CurrentBudget" */
  CurrentBudget_aggregate: CurrentBudget_Aggregate;
  /** fetch data from the table: "ExternalToInternalContent" */
  ExternalToInternalContent: Array<ExternalToInternalContent>;
  /** fetch aggregated fields from the table: "ExternalToInternalContent" */
  ExternalToInternalContent_aggregate: ExternalToInternalContent_Aggregate;
  /** fetch data from the table: "ExternalToInternalContent" using primary key columns */
  ExternalToInternalContent_by_pk?: Maybe<ExternalToInternalContent>;
  /** fetch data from the table: "Genre" */
  Genre: Array<Genre>;
  /** fetch data from the table: "GenreI18N" */
  GenreI18N: Array<GenreI18N>;
  /** fetch aggregated fields from the table: "GenreI18N" */
  GenreI18N_aggregate: GenreI18N_Aggregate;
  /** fetch data from the table: "GenreI18N" using primary key columns */
  GenreI18N_by_pk?: Maybe<GenreI18N>;
  /** fetch data from the table: "GenreMediaContent" */
  GenreMediaContent: Array<GenreMediaContent>;
  /** fetch aggregated fields from the table: "GenreMediaContent" */
  GenreMediaContent_aggregate: GenreMediaContent_Aggregate;
  /** fetch data from the table: "GenreMediaContent" using primary key columns */
  GenreMediaContent_by_pk?: Maybe<GenreMediaContent>;
  /** fetch aggregated fields from the table: "Genre" */
  Genre_aggregate: Genre_Aggregate;
  /** fetch data from the table: "Genre" using primary key columns */
  Genre_by_pk?: Maybe<Genre>;
  /** fetch data from the table: "Languages" */
  Languages: Array<Languages>;
  /** fetch aggregated fields from the table: "Languages" */
  Languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "Languages" using primary key columns */
  Languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "MediaContent" */
  MediaContent: Array<MediaContent>;
  /** fetch data from the table: "MediaContentI18N" */
  MediaContentI18N: Array<MediaContentI18N>;
  /** fetch aggregated fields from the table: "MediaContentI18N" */
  MediaContentI18N_aggregate: MediaContentI18N_Aggregate;
  /** fetch data from the table: "MediaContentI18N" using primary key columns */
  MediaContentI18N_by_pk?: Maybe<MediaContentI18N>;
  /** fetch data from the table: "MediaContentReferences" */
  MediaContentReferences: Array<MediaContentReferences>;
  /** fetch aggregated fields from the table: "MediaContentReferences" */
  MediaContentReferences_aggregate: MediaContentReferences_Aggregate;
  /** fetch data from the table: "MediaContentReferences" using primary key columns */
  MediaContentReferences_by_pk?: Maybe<MediaContentReferences>;
  /** fetch aggregated fields from the table: "MediaContent" */
  MediaContent_aggregate: MediaContent_Aggregate;
  /** fetch data from the table: "MediaContent" using primary key columns */
  MediaContent_by_pk?: Maybe<MediaContent>;
  /** fetch data from the table: "MediaType" */
  MediaType: Array<MediaType>;
  /** fetch aggregated fields from the table: "MediaType" */
  MediaType_aggregate: MediaType_Aggregate;
  /** fetch data from the table: "MediaType" using primary key columns */
  MediaType_by_pk?: Maybe<MediaType>;
  /** fetch data from the table: "Message" */
  Message: Array<Message>;
  /** fetch aggregated fields from the table: "Message" */
  Message_aggregate: Message_Aggregate;
  /** fetch data from the table: "Message" using primary key columns */
  Message_by_pk?: Maybe<Message>;
  /** fetch data from the table: "MostWatchedContent" */
  MostWatchedContent: Array<MostWatchedContent>;
  /** fetch aggregated fields from the table: "MostWatchedContent" */
  MostWatchedContent_aggregate: MostWatchedContent_Aggregate;
  /** fetch data from the table: "Participants" */
  Participants: Array<Participants>;
  /** fetch aggregated fields from the table: "Participants" */
  Participants_aggregate: Participants_Aggregate;
  /** fetch data from the table: "PublicParties" */
  PublicParties: Array<PublicParties>;
  /** fetch aggregated fields from the table: "PublicParties" */
  PublicParties_aggregate: PublicParties_Aggregate;
  /** fetch data from the table: "Session" */
  Session: Array<Session>;
  /** fetch data from the table: "SessionParticipation" */
  SessionParticipation: Array<SessionParticipation>;
  /** fetch aggregated fields from the table: "SessionParticipation" */
  SessionParticipation_aggregate: SessionParticipation_Aggregate;
  /** fetch data from the table: "SessionParticipation" using primary key columns */
  SessionParticipation_by_pk?: Maybe<SessionParticipation>;
  /** fetch aggregated fields from the table: "Session" */
  Session_aggregate: Session_Aggregate;
  /** fetch data from the table: "Session" using primary key columns */
  Session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "StreamingService" */
  StreamingService: Array<StreamingService>;
  /** fetch aggregated fields from the table: "StreamingService" */
  StreamingService_aggregate: StreamingService_Aggregate;
  /** fetch data from the table: "StreamingService" using primary key columns */
  StreamingService_by_pk?: Maybe<StreamingService>;
  /** fetch data from the table: "SubscriptionPlanTier" */
  SubscriptionPlanTier: Array<SubscriptionPlanTier>;
  /** fetch aggregated fields from the table: "SubscriptionPlanTier" */
  SubscriptionPlanTier_aggregate: SubscriptionPlanTier_Aggregate;
  /** fetch data from the table: "SubscriptionPlanTier" using primary key columns */
  SubscriptionPlanTier_by_pk?: Maybe<SubscriptionPlanTier>;
  /** fetch data from the table: "Tier" */
  Tier: Array<Tier>;
  /** fetch aggregated fields from the table: "Tier" */
  Tier_aggregate: Tier_Aggregate;
  /** fetch data from the table: "Tier" using primary key columns */
  Tier_by_pk?: Maybe<Tier>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserGeneratedContent" */
  UserGeneratedContent: Array<UserGeneratedContent>;
  /** fetch aggregated fields from the table: "UserGeneratedContent" */
  UserGeneratedContent_aggregate: UserGeneratedContent_Aggregate;
  /** fetch data from the table: "UserGeneratedContent" using primary key columns */
  UserGeneratedContent_by_pk?: Maybe<UserGeneratedContent>;
  /** fetch data from the table: "UserPublic" */
  UserPublic: Array<UserPublic>;
  /** fetch aggregated fields from the table: "UserPublic" */
  UserPublic_aggregate: UserPublic_Aggregate;
  /** fetch data from the table: "UserStreamingService" */
  UserStreamingService: Array<UserStreamingService>;
  /** fetch aggregated fields from the table: "UserStreamingService" */
  UserStreamingService_aggregate: UserStreamingService_Aggregate;
  /** fetch data from the table: "UserStreamingService" using primary key columns */
  UserStreamingService_by_pk?: Maybe<UserStreamingService>;
  /** fetch data from the table: "UserSubscription" */
  UserSubscription: Array<UserSubscription>;
  /** fetch aggregated fields from the table: "UserSubscription" */
  UserSubscription_aggregate: UserSubscription_Aggregate;
  /** fetch data from the table: "UserSubscription" using primary key columns */
  UserSubscription_by_pk?: Maybe<UserSubscription>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** fetch data from the table: "VideoChatServer" */
  VideoChatServer: Array<VideoChatServer>;
  /** fetch aggregated fields from the table: "VideoChatServer" */
  VideoChatServer_aggregate: VideoChatServer_Aggregate;
  /** fetch data from the table: "VideoChatServer" using primary key columns */
  VideoChatServer_by_pk?: Maybe<VideoChatServer>;
};

/** query root */
export type Query_RootBudgetArgs = {
  distinct_on?: Maybe<Array<Budget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Budget_Order_By>>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** query root */
export type Query_RootBudget_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Budget_Order_By>>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** query root */
export type Query_RootBudget_By_PkArgs = {
  month: Scalars["Int"];
  userId: Scalars["String"];
  year: Scalars["Int"];
};

/** query root */
export type Query_RootCurrentBudgetArgs = {
  distinct_on?: Maybe<Array<CurrentBudget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CurrentBudget_Order_By>>;
  where?: Maybe<CurrentBudget_Bool_Exp>;
};

/** query root */
export type Query_RootCurrentBudget_AggregateArgs = {
  distinct_on?: Maybe<Array<CurrentBudget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CurrentBudget_Order_By>>;
  where?: Maybe<CurrentBudget_Bool_Exp>;
};

/** query root */
export type Query_RootExternalToInternalContentArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** query root */
export type Query_RootExternalToInternalContent_AggregateArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** query root */
export type Query_RootExternalToInternalContent_By_PkArgs = {
  host: Scalars["String"];
  hostContentId: Scalars["String"];
};

/** query root */
export type Query_RootGenreArgs = {
  distinct_on?: Maybe<Array<Genre_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Genre_Order_By>>;
  where?: Maybe<Genre_Bool_Exp>;
};

/** query root */
export type Query_RootGenreI18NArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** query root */
export type Query_RootGenreI18N_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** query root */
export type Query_RootGenreI18N_By_PkArgs = {
  id: Scalars["Int"];
  language: Scalars["String"];
};

/** query root */
export type Query_RootGenreMediaContentArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** query root */
export type Query_RootGenreMediaContent_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** query root */
export type Query_RootGenreMediaContent_By_PkArgs = {
  genreId: Scalars["Int"];
  mediaContentId: Scalars["uuid"];
};

/** query root */
export type Query_RootGenre_AggregateArgs = {
  distinct_on?: Maybe<Array<Genre_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Genre_Order_By>>;
  where?: Maybe<Genre_Bool_Exp>;
};

/** query root */
export type Query_RootGenre_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootLanguagesArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};

/** query root */
export type Query_RootLanguages_AggregateArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};

/** query root */
export type Query_RootLanguages_By_PkArgs = {
  code: Scalars["String"];
};

/** query root */
export type Query_RootMediaContentArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** query root */
export type Query_RootMediaContentI18NArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** query root */
export type Query_RootMediaContentI18N_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** query root */
export type Query_RootMediaContentI18N_By_PkArgs = {
  id: Scalars["uuid"];
  language: Scalars["String"];
};

/** query root */
export type Query_RootMediaContentReferencesArgs = {
  distinct_on?: Maybe<Array<MediaContentReferences_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentReferences_Order_By>>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** query root */
export type Query_RootMediaContentReferences_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentReferences_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentReferences_Order_By>>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** query root */
export type Query_RootMediaContentReferences_By_PkArgs = {
  id: Scalars["uuid"];
};

/** query root */
export type Query_RootMediaContent_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** query root */
export type Query_RootMediaContent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** query root */
export type Query_RootMediaTypeArgs = {
  distinct_on?: Maybe<Array<MediaType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaType_Order_By>>;
  where?: Maybe<MediaType_Bool_Exp>;
};

/** query root */
export type Query_RootMediaType_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaType_Order_By>>;
  where?: Maybe<MediaType_Bool_Exp>;
};

/** query root */
export type Query_RootMediaType_By_PkArgs = {
  id: Scalars["String"];
};

/** query root */
export type Query_RootMessageArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** query root */
export type Query_RootMessage_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** query root */
export type Query_RootMessage_By_PkArgs = {
  id: Scalars["uuid"];
};

/** query root */
export type Query_RootMostWatchedContentArgs = {
  distinct_on?: Maybe<Array<MostWatchedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MostWatchedContent_Order_By>>;
  where?: Maybe<MostWatchedContent_Bool_Exp>;
};

/** query root */
export type Query_RootMostWatchedContent_AggregateArgs = {
  distinct_on?: Maybe<Array<MostWatchedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MostWatchedContent_Order_By>>;
  where?: Maybe<MostWatchedContent_Bool_Exp>;
};

/** query root */
export type Query_RootParticipantsArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};

/** query root */
export type Query_RootParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};

/** query root */
export type Query_RootPublicPartiesArgs = {
  distinct_on?: Maybe<Array<PublicParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PublicParties_Order_By>>;
  where?: Maybe<PublicParties_Bool_Exp>;
};

/** query root */
export type Query_RootPublicParties_AggregateArgs = {
  distinct_on?: Maybe<Array<PublicParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PublicParties_Order_By>>;
  where?: Maybe<PublicParties_Bool_Exp>;
};

/** query root */
export type Query_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** query root */
export type Query_RootSessionParticipationArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** query root */
export type Query_RootSessionParticipation_AggregateArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** query root */
export type Query_RootSessionParticipation_By_PkArgs = {
  sessionId: Scalars["uuid"];
  userId: Scalars["String"];
};

/** query root */
export type Query_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** query root */
export type Query_RootSession_By_PkArgs = {
  id: Scalars["uuid"];
};

/** query root */
export type Query_RootStreamingServiceArgs = {
  distinct_on?: Maybe<Array<StreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<StreamingService_Order_By>>;
  where?: Maybe<StreamingService_Bool_Exp>;
};

/** query root */
export type Query_RootStreamingService_AggregateArgs = {
  distinct_on?: Maybe<Array<StreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<StreamingService_Order_By>>;
  where?: Maybe<StreamingService_Bool_Exp>;
};

/** query root */
export type Query_RootStreamingService_By_PkArgs = {
  id: Scalars["String"];
};

/** query root */
export type Query_RootSubscriptionPlanTierArgs = {
  distinct_on?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SubscriptionPlanTier_Order_By>>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** query root */
export type Query_RootSubscriptionPlanTier_AggregateArgs = {
  distinct_on?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SubscriptionPlanTier_Order_By>>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** query root */
export type Query_RootSubscriptionPlanTier_By_PkArgs = {
  subscriptionPlanId: Scalars["String"];
};

/** query root */
export type Query_RootTierArgs = {
  distinct_on?: Maybe<Array<Tier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Tier_Order_By>>;
  where?: Maybe<Tier_Bool_Exp>;
};

/** query root */
export type Query_RootTier_AggregateArgs = {
  distinct_on?: Maybe<Array<Tier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Tier_Order_By>>;
  where?: Maybe<Tier_Bool_Exp>;
};

/** query root */
export type Query_RootTier_By_PkArgs = {
  id: Scalars["String"];
};

/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUserGeneratedContentArgs = {
  distinct_on?: Maybe<Array<UserGeneratedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserGeneratedContent_Order_By>>;
  where?: Maybe<UserGeneratedContent_Bool_Exp>;
};

/** query root */
export type Query_RootUserGeneratedContent_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGeneratedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserGeneratedContent_Order_By>>;
  where?: Maybe<UserGeneratedContent_Bool_Exp>;
};

/** query root */
export type Query_RootUserGeneratedContent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** query root */
export type Query_RootUserPublicArgs = {
  distinct_on?: Maybe<Array<UserPublic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserPublic_Order_By>>;
  where?: Maybe<UserPublic_Bool_Exp>;
};

/** query root */
export type Query_RootUserPublic_AggregateArgs = {
  distinct_on?: Maybe<Array<UserPublic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserPublic_Order_By>>;
  where?: Maybe<UserPublic_Bool_Exp>;
};

/** query root */
export type Query_RootUserStreamingServiceArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** query root */
export type Query_RootUserStreamingService_AggregateArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** query root */
export type Query_RootUserStreamingService_By_PkArgs = {
  serviceId: Scalars["String"];
  userId: Scalars["String"];
};

/** query root */
export type Query_RootUserSubscriptionArgs = {
  distinct_on?: Maybe<Array<UserSubscription_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserSubscription_Order_By>>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** query root */
export type Query_RootUserSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSubscription_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserSubscription_Order_By>>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** query root */
export type Query_RootUserSubscription_By_PkArgs = {
  userId: Scalars["String"];
};

/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars["String"];
};

/** query root */
export type Query_RootVideoChatServerArgs = {
  distinct_on?: Maybe<Array<VideoChatServer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VideoChatServer_Order_By>>;
  where?: Maybe<VideoChatServer_Bool_Exp>;
};

/** query root */
export type Query_RootVideoChatServer_AggregateArgs = {
  distinct_on?: Maybe<Array<VideoChatServer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VideoChatServer_Order_By>>;
  where?: Maybe<VideoChatServer_Bool_Exp>;
};

/** query root */
export type Query_RootVideoChatServer_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "Budget" */
  Budget: Array<Budget>;
  /** fetch aggregated fields from the table: "Budget" */
  Budget_aggregate: Budget_Aggregate;
  /** fetch data from the table: "Budget" using primary key columns */
  Budget_by_pk?: Maybe<Budget>;
  /** fetch data from the table: "CurrentBudget" */
  CurrentBudget: Array<CurrentBudget>;
  /** fetch aggregated fields from the table: "CurrentBudget" */
  CurrentBudget_aggregate: CurrentBudget_Aggregate;
  /** fetch data from the table: "ExternalToInternalContent" */
  ExternalToInternalContent: Array<ExternalToInternalContent>;
  /** fetch aggregated fields from the table: "ExternalToInternalContent" */
  ExternalToInternalContent_aggregate: ExternalToInternalContent_Aggregate;
  /** fetch data from the table: "ExternalToInternalContent" using primary key columns */
  ExternalToInternalContent_by_pk?: Maybe<ExternalToInternalContent>;
  /** fetch data from the table: "Genre" */
  Genre: Array<Genre>;
  /** fetch data from the table: "GenreI18N" */
  GenreI18N: Array<GenreI18N>;
  /** fetch aggregated fields from the table: "GenreI18N" */
  GenreI18N_aggregate: GenreI18N_Aggregate;
  /** fetch data from the table: "GenreI18N" using primary key columns */
  GenreI18N_by_pk?: Maybe<GenreI18N>;
  /** fetch data from the table: "GenreMediaContent" */
  GenreMediaContent: Array<GenreMediaContent>;
  /** fetch aggregated fields from the table: "GenreMediaContent" */
  GenreMediaContent_aggregate: GenreMediaContent_Aggregate;
  /** fetch data from the table: "GenreMediaContent" using primary key columns */
  GenreMediaContent_by_pk?: Maybe<GenreMediaContent>;
  /** fetch aggregated fields from the table: "Genre" */
  Genre_aggregate: Genre_Aggregate;
  /** fetch data from the table: "Genre" using primary key columns */
  Genre_by_pk?: Maybe<Genre>;
  /** fetch data from the table: "Languages" */
  Languages: Array<Languages>;
  /** fetch aggregated fields from the table: "Languages" */
  Languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "Languages" using primary key columns */
  Languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "MediaContent" */
  MediaContent: Array<MediaContent>;
  /** fetch data from the table: "MediaContentI18N" */
  MediaContentI18N: Array<MediaContentI18N>;
  /** fetch aggregated fields from the table: "MediaContentI18N" */
  MediaContentI18N_aggregate: MediaContentI18N_Aggregate;
  /** fetch data from the table: "MediaContentI18N" using primary key columns */
  MediaContentI18N_by_pk?: Maybe<MediaContentI18N>;
  /** fetch data from the table: "MediaContentReferences" */
  MediaContentReferences: Array<MediaContentReferences>;
  /** fetch aggregated fields from the table: "MediaContentReferences" */
  MediaContentReferences_aggregate: MediaContentReferences_Aggregate;
  /** fetch data from the table: "MediaContentReferences" using primary key columns */
  MediaContentReferences_by_pk?: Maybe<MediaContentReferences>;
  /** fetch aggregated fields from the table: "MediaContent" */
  MediaContent_aggregate: MediaContent_Aggregate;
  /** fetch data from the table: "MediaContent" using primary key columns */
  MediaContent_by_pk?: Maybe<MediaContent>;
  /** fetch data from the table: "MediaType" */
  MediaType: Array<MediaType>;
  /** fetch aggregated fields from the table: "MediaType" */
  MediaType_aggregate: MediaType_Aggregate;
  /** fetch data from the table: "MediaType" using primary key columns */
  MediaType_by_pk?: Maybe<MediaType>;
  /** fetch data from the table: "Message" */
  Message: Array<Message>;
  /** fetch aggregated fields from the table: "Message" */
  Message_aggregate: Message_Aggregate;
  /** fetch data from the table: "Message" using primary key columns */
  Message_by_pk?: Maybe<Message>;
  /** fetch data from the table: "MostWatchedContent" */
  MostWatchedContent: Array<MostWatchedContent>;
  /** fetch aggregated fields from the table: "MostWatchedContent" */
  MostWatchedContent_aggregate: MostWatchedContent_Aggregate;
  /** fetch data from the table: "Participants" */
  Participants: Array<Participants>;
  /** fetch aggregated fields from the table: "Participants" */
  Participants_aggregate: Participants_Aggregate;
  /** fetch data from the table: "PublicParties" */
  PublicParties: Array<PublicParties>;
  /** fetch aggregated fields from the table: "PublicParties" */
  PublicParties_aggregate: PublicParties_Aggregate;
  /** fetch data from the table: "Session" */
  Session: Array<Session>;
  /** fetch data from the table: "SessionParticipation" */
  SessionParticipation: Array<SessionParticipation>;
  /** fetch aggregated fields from the table: "SessionParticipation" */
  SessionParticipation_aggregate: SessionParticipation_Aggregate;
  /** fetch data from the table: "SessionParticipation" using primary key columns */
  SessionParticipation_by_pk?: Maybe<SessionParticipation>;
  /** fetch aggregated fields from the table: "Session" */
  Session_aggregate: Session_Aggregate;
  /** fetch data from the table: "Session" using primary key columns */
  Session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "StreamingService" */
  StreamingService: Array<StreamingService>;
  /** fetch aggregated fields from the table: "StreamingService" */
  StreamingService_aggregate: StreamingService_Aggregate;
  /** fetch data from the table: "StreamingService" using primary key columns */
  StreamingService_by_pk?: Maybe<StreamingService>;
  /** fetch data from the table: "SubscriptionPlanTier" */
  SubscriptionPlanTier: Array<SubscriptionPlanTier>;
  /** fetch aggregated fields from the table: "SubscriptionPlanTier" */
  SubscriptionPlanTier_aggregate: SubscriptionPlanTier_Aggregate;
  /** fetch data from the table: "SubscriptionPlanTier" using primary key columns */
  SubscriptionPlanTier_by_pk?: Maybe<SubscriptionPlanTier>;
  /** fetch data from the table: "Tier" */
  Tier: Array<Tier>;
  /** fetch aggregated fields from the table: "Tier" */
  Tier_aggregate: Tier_Aggregate;
  /** fetch data from the table: "Tier" using primary key columns */
  Tier_by_pk?: Maybe<Tier>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserGeneratedContent" */
  UserGeneratedContent: Array<UserGeneratedContent>;
  /** fetch aggregated fields from the table: "UserGeneratedContent" */
  UserGeneratedContent_aggregate: UserGeneratedContent_Aggregate;
  /** fetch data from the table: "UserGeneratedContent" using primary key columns */
  UserGeneratedContent_by_pk?: Maybe<UserGeneratedContent>;
  /** fetch data from the table: "UserPublic" */
  UserPublic: Array<UserPublic>;
  /** fetch aggregated fields from the table: "UserPublic" */
  UserPublic_aggregate: UserPublic_Aggregate;
  /** fetch data from the table: "UserStreamingService" */
  UserStreamingService: Array<UserStreamingService>;
  /** fetch aggregated fields from the table: "UserStreamingService" */
  UserStreamingService_aggregate: UserStreamingService_Aggregate;
  /** fetch data from the table: "UserStreamingService" using primary key columns */
  UserStreamingService_by_pk?: Maybe<UserStreamingService>;
  /** fetch data from the table: "UserSubscription" */
  UserSubscription: Array<UserSubscription>;
  /** fetch aggregated fields from the table: "UserSubscription" */
  UserSubscription_aggregate: UserSubscription_Aggregate;
  /** fetch data from the table: "UserSubscription" using primary key columns */
  UserSubscription_by_pk?: Maybe<UserSubscription>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** fetch data from the table: "VideoChatServer" */
  VideoChatServer: Array<VideoChatServer>;
  /** fetch aggregated fields from the table: "VideoChatServer" */
  VideoChatServer_aggregate: VideoChatServer_Aggregate;
  /** fetch data from the table: "VideoChatServer" using primary key columns */
  VideoChatServer_by_pk?: Maybe<VideoChatServer>;
};

/** subscription root */
export type Subscription_RootBudgetArgs = {
  distinct_on?: Maybe<Array<Budget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Budget_Order_By>>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootBudget_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Budget_Order_By>>;
  where?: Maybe<Budget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootBudget_By_PkArgs = {
  month: Scalars["Int"];
  userId: Scalars["String"];
  year: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootCurrentBudgetArgs = {
  distinct_on?: Maybe<Array<CurrentBudget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CurrentBudget_Order_By>>;
  where?: Maybe<CurrentBudget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCurrentBudget_AggregateArgs = {
  distinct_on?: Maybe<Array<CurrentBudget_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CurrentBudget_Order_By>>;
  where?: Maybe<CurrentBudget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootExternalToInternalContentArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootExternalToInternalContent_AggregateArgs = {
  distinct_on?: Maybe<Array<ExternalToInternalContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ExternalToInternalContent_Order_By>>;
  where?: Maybe<ExternalToInternalContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootExternalToInternalContent_By_PkArgs = {
  host: Scalars["String"];
  hostContentId: Scalars["String"];
};

/** subscription root */
export type Subscription_RootGenreArgs = {
  distinct_on?: Maybe<Array<Genre_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Genre_Order_By>>;
  where?: Maybe<Genre_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGenreI18NArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGenreI18N_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreI18N_Order_By>>;
  where?: Maybe<GenreI18N_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGenreI18N_By_PkArgs = {
  id: Scalars["Int"];
  language: Scalars["String"];
};

/** subscription root */
export type Subscription_RootGenreMediaContentArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGenreMediaContent_AggregateArgs = {
  distinct_on?: Maybe<Array<GenreMediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<GenreMediaContent_Order_By>>;
  where?: Maybe<GenreMediaContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGenreMediaContent_By_PkArgs = {
  genreId: Scalars["Int"];
  mediaContentId: Scalars["uuid"];
};

/** subscription root */
export type Subscription_RootGenre_AggregateArgs = {
  distinct_on?: Maybe<Array<Genre_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Genre_Order_By>>;
  where?: Maybe<Genre_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGenre_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootLanguagesArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootLanguages_AggregateArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootLanguages_By_PkArgs = {
  code: Scalars["String"];
};

/** subscription root */
export type Subscription_RootMediaContentArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaContentI18NArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaContentI18N_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentI18N_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentI18N_Order_By>>;
  where?: Maybe<MediaContentI18N_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaContentI18N_By_PkArgs = {
  id: Scalars["uuid"];
  language: Scalars["String"];
};

/** subscription root */
export type Subscription_RootMediaContentReferencesArgs = {
  distinct_on?: Maybe<Array<MediaContentReferences_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentReferences_Order_By>>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaContentReferences_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContentReferences_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContentReferences_Order_By>>;
  where?: Maybe<MediaContentReferences_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaContentReferences_By_PkArgs = {
  id: Scalars["uuid"];
};

/** subscription root */
export type Subscription_RootMediaContent_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaContent_Order_By>>;
  where?: Maybe<MediaContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaContent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** subscription root */
export type Subscription_RootMediaTypeArgs = {
  distinct_on?: Maybe<Array<MediaType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaType_Order_By>>;
  where?: Maybe<MediaType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaType_AggregateArgs = {
  distinct_on?: Maybe<Array<MediaType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaType_Order_By>>;
  where?: Maybe<MediaType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMediaType_By_PkArgs = {
  id: Scalars["String"];
};

/** subscription root */
export type Subscription_RootMessageArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMessage_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Message_Order_By>>;
  where?: Maybe<Message_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMessage_By_PkArgs = {
  id: Scalars["uuid"];
};

/** subscription root */
export type Subscription_RootMostWatchedContentArgs = {
  distinct_on?: Maybe<Array<MostWatchedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MostWatchedContent_Order_By>>;
  where?: Maybe<MostWatchedContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMostWatchedContent_AggregateArgs = {
  distinct_on?: Maybe<Array<MostWatchedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MostWatchedContent_Order_By>>;
  where?: Maybe<MostWatchedContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootParticipantsArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Participants_Order_By>>;
  where?: Maybe<Participants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPublicPartiesArgs = {
  distinct_on?: Maybe<Array<PublicParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PublicParties_Order_By>>;
  where?: Maybe<PublicParties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootPublicParties_AggregateArgs = {
  distinct_on?: Maybe<Array<PublicParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PublicParties_Order_By>>;
  where?: Maybe<PublicParties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSessionParticipationArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSessionParticipation_AggregateArgs = {
  distinct_on?: Maybe<Array<SessionParticipation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SessionParticipation_Order_By>>;
  where?: Maybe<SessionParticipation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSessionParticipation_By_PkArgs = {
  sessionId: Scalars["uuid"];
  userId: Scalars["String"];
};

/** subscription root */
export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSession_By_PkArgs = {
  id: Scalars["uuid"];
};

/** subscription root */
export type Subscription_RootStreamingServiceArgs = {
  distinct_on?: Maybe<Array<StreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<StreamingService_Order_By>>;
  where?: Maybe<StreamingService_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootStreamingService_AggregateArgs = {
  distinct_on?: Maybe<Array<StreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<StreamingService_Order_By>>;
  where?: Maybe<StreamingService_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootStreamingService_By_PkArgs = {
  id: Scalars["String"];
};

/** subscription root */
export type Subscription_RootSubscriptionPlanTierArgs = {
  distinct_on?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SubscriptionPlanTier_Order_By>>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSubscriptionPlanTier_AggregateArgs = {
  distinct_on?: Maybe<Array<SubscriptionPlanTier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<SubscriptionPlanTier_Order_By>>;
  where?: Maybe<SubscriptionPlanTier_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSubscriptionPlanTier_By_PkArgs = {
  subscriptionPlanId: Scalars["String"];
};

/** subscription root */
export type Subscription_RootTierArgs = {
  distinct_on?: Maybe<Array<Tier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Tier_Order_By>>;
  where?: Maybe<Tier_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTier_AggregateArgs = {
  distinct_on?: Maybe<Array<Tier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Tier_Order_By>>;
  where?: Maybe<Tier_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTier_By_PkArgs = {
  id: Scalars["String"];
};

/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserGeneratedContentArgs = {
  distinct_on?: Maybe<Array<UserGeneratedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserGeneratedContent_Order_By>>;
  where?: Maybe<UserGeneratedContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserGeneratedContent_AggregateArgs = {
  distinct_on?: Maybe<Array<UserGeneratedContent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserGeneratedContent_Order_By>>;
  where?: Maybe<UserGeneratedContent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserGeneratedContent_By_PkArgs = {
  id: Scalars["uuid"];
};

/** subscription root */
export type Subscription_RootUserPublicArgs = {
  distinct_on?: Maybe<Array<UserPublic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserPublic_Order_By>>;
  where?: Maybe<UserPublic_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserPublic_AggregateArgs = {
  distinct_on?: Maybe<Array<UserPublic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserPublic_Order_By>>;
  where?: Maybe<UserPublic_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserStreamingServiceArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserStreamingService_AggregateArgs = {
  distinct_on?: Maybe<Array<UserStreamingService_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserStreamingService_Order_By>>;
  where?: Maybe<UserStreamingService_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserStreamingService_By_PkArgs = {
  serviceId: Scalars["String"];
  userId: Scalars["String"];
};

/** subscription root */
export type Subscription_RootUserSubscriptionArgs = {
  distinct_on?: Maybe<Array<UserSubscription_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserSubscription_Order_By>>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<UserSubscription_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserSubscription_Order_By>>;
  where?: Maybe<UserSubscription_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserSubscription_By_PkArgs = {
  userId: Scalars["String"];
};

/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars["String"];
};

/** subscription root */
export type Subscription_RootVideoChatServerArgs = {
  distinct_on?: Maybe<Array<VideoChatServer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VideoChatServer_Order_By>>;
  where?: Maybe<VideoChatServer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVideoChatServer_AggregateArgs = {
  distinct_on?: Maybe<Array<VideoChatServer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VideoChatServer_Order_By>>;
  where?: Maybe<VideoChatServer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVideoChatServer_By_PkArgs = {
  id: Scalars["Int"];
};

/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

export type DeleteSessionMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteSessionMutation = { __typename?: "mutation_root" } & {
  delete_Session_by_pk?: Maybe<{ __typename?: "Session" } & Pick<Session, "id">>;
};

export type UpdateSubscriptionMutationVariables = Exact<{
  userId: Scalars["String"];
  data?: Maybe<UserSubscription_Set_Input>;
}>;

export type UpdateSubscriptionMutation = { __typename?: "mutation_root" } & {
  update_UserSubscription_by_pk?: Maybe<
    { __typename?: "UserSubscription" } & Pick<UserSubscription, "userId">
  >;
};

export type UpdateExtraTimeMutationVariables = Exact<{
  userId: Scalars["String"];
  extraTime: Scalars["Int"];
}>;

export type UpdateExtraTimeMutation = { __typename?: "mutation_root" } & {
  update_UserSubscription_by_pk?: Maybe<
    { __typename?: "UserSubscription" } & Pick<UserSubscription, "extraTime">
  >;
};

export type UpdateUsernameMutationVariables = Exact<{
  userId: Scalars["String"];
  username: Scalars["String"];
}>;

export type UpdateUsernameMutation = { __typename?: "mutation_root" } & {
  update_User_by_pk?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
};

export type UpdateEmailMutationVariables = Exact<{
  userId: Scalars["String"];
  email: Scalars["String"];
}>;

export type UpdateEmailMutation = { __typename?: "mutation_root" } & {
  update_User_by_pk?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
};

export type SetStreamingServicesMutationVariables = Exact<{
  userId: Scalars["String"];
  objects: Array<UserStreamingService_Insert_Input>;
}>;

export type SetStreamingServicesMutation = { __typename?: "mutation_root" } & {
  delete_UserStreamingService?: Maybe<
    { __typename?: "UserStreamingService_mutation_response" } & Pick<
      UserStreamingService_Mutation_Response,
      "affected_rows"
    >
  >;
  insert_UserStreamingService?: Maybe<
    { __typename?: "UserStreamingService_mutation_response" } & {
      returning: Array<
        { __typename?: "UserStreamingService" } & Pick<UserStreamingService, "serviceId">
      >;
    }
  >;
};

export type SetAvatarVersionMutationVariables = Exact<{
  userId: Scalars["String"];
  avatarVersion: Scalars["String"];
}>;

export type SetAvatarVersionMutation = { __typename?: "mutation_root" } & {
  update_User_by_pk?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars["String"];
}>;

export type DeleteUserMutation = { __typename?: "mutation_root" } & {
  delete_User_by_pk?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
};

export type ParticipantsCountFragment = { __typename?: "PublicParties" } & Pick<
  PublicParties,
  "maxParticipants"
> & { Participants?: Maybe<{ __typename?: "Participants" } & Pick<Participants, "count">> };

export type ParticipantsCountSessionFragment = { __typename?: "Session" } & Pick<
  Session,
  "maxParticipants"
> & {
    SessionParticipations_aggregate: { __typename?: "SessionParticipation_aggregate" } & {
      aggregate?: Maybe<
        { __typename?: "SessionParticipation_aggregate_fields" } & Pick<
          SessionParticipation_Aggregate_Fields,
          "count"
        >
      >;
    };
  };

export type MediaContentInformationFragment = { __typename?: "MediaContent" } & Pick<
  MediaContent,
  | "id"
  | "type"
  | "title"
  | "overview"
  | "slug"
  | "seasonNumber"
  | "episodeNumber"
  | "posterURL"
  | "backdropURL"
  | "stillURL"
  | "releasedOn"
> & {
    Show?: Maybe<
      { __typename?: "MediaContent" } & Pick<
        MediaContent,
        "id" | "type" | "title" | "overview" | "backdropURL" | "posterURL" | "slug"
      >
    >;
    GenreMediaContents: Array<
      { __typename?: "GenreMediaContent" } & Pick<GenreMediaContent, "genreId"> & {
          Genre: { __typename?: "Genre" } & Pick<Genre, "name">;
        }
    >;
    MediaContentI18Ns: Array<
      { __typename?: "MediaContentI18N" } & Pick<
        MediaContentI18N,
        "language" | "title" | "overview"
      >
    >;
  };

export type ContentInformationFragment = { __typename?: "PublicParties" } & {
  ExternalContent?: Maybe<
    { __typename?: "ExternalToInternalContent" } & {
      MediaContent?: Maybe<{ __typename?: "MediaContent" } & MediaContentInformationFragment>;
      UserGeneratedContent?: Maybe<
        { __typename?: "UserGeneratedContent" } & Pick<
          UserGeneratedContent,
          "title" | "subtitle" | "episodeNumber" | "seasonNumber" | "imageURL"
        >
      >;
    }
  >;
};

export type ContentInformationSessionFragment = { __typename?: "Session" } & {
  ExternalToInternalContent?: Maybe<
    { __typename?: "ExternalToInternalContent" } & {
      MediaContent?: Maybe<{ __typename?: "MediaContent" } & MediaContentInformationFragment>;
      UserGeneratedContent?: Maybe<
        { __typename?: "UserGeneratedContent" } & Pick<
          UserGeneratedContent,
          "title" | "subtitle" | "episodeNumber" | "seasonNumber" | "imageURL"
        >
      >;
    }
  >;
};

export type PartyInformationFragment = { __typename?: "PublicParties" } & Pick<
  PublicParties,
  "id" | "title" | "startTime" | "ownerLeftTime" | "endTime" | "host"
> & {
    Owner?: Maybe<
      { __typename?: "UserPublic" } & Pick<UserPublic, "id" | "username" | "avatar_version">
    >;
  };

export type MyPartyInformationFragment = { __typename?: "Session" } & Pick<
  Session,
  "id" | "title" | "startTime" | "endTime" | "host"
>;

export type GetPopularPublicPartiesLiveQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  hostnames?: Maybe<Array<Scalars["String"]>>;
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetPopularPublicPartiesLiveQuery = { __typename?: "query_root" } & {
  PublicParties: Array<
    { __typename?: "PublicParties" } & PartyInformationFragment &
      ParticipantsCountFragment &
      ContentInformationFragment
  >;
};

export type GetUpcomingPartiesQueryVariables = Exact<{
  currentTime: Scalars["timestamp"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  hostnames?: Maybe<Array<Scalars["String"]>>;
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetUpcomingPartiesQuery = { __typename?: "query_root" } & {
  PublicParties: Array<
    { __typename?: "PublicParties" } & PartyInformationFragment &
      ParticipantsCountFragment &
      ContentInformationFragment
  >;
};

export type GetPartiesByContentQueryVariables = Exact<{
  contentId: Scalars["uuid"];
  currentTime: Scalars["timestamp"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  excludeId?: Maybe<Scalars["uuid"]>;
}>;

export type GetPartiesByContentQuery = { __typename?: "query_root" } & {
  PublicParties: Array<
    { __typename?: "PublicParties" } & PartyInformationFragment &
      ParticipantsCountFragment &
      ContentInformationFragment
  >;
};

export type GetPopularContentQueryVariables = Exact<{
  limit: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  hostnames?: Maybe<Array<Scalars["String"]>>;
}>;

export type GetPopularContentQuery = { __typename?: "query_root" } & {
  MostWatchedContent: Array<
    { __typename?: "MostWatchedContent" } & {
      MediaContent?: Maybe<
        { __typename?: "MediaContent" } & {
          ExternalToInternalContents: Array<
            { __typename?: "ExternalToInternalContent" } & Pick<ExternalToInternalContent, "host">
          >;
        } & MediaContentInformationFragment
      >;
    }
  >;
};

export type GetCategoriesQueryVariables = Exact<{
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetCategoriesQuery = { __typename?: "query_root" } & {
  Genre: Array<
    { __typename?: "Genre" } & Pick<Genre, "id" | "name" | "slug"> & {
        GenreI18Ns: Array<{ __typename?: "GenreI18N" } & Pick<GenreI18N, "name" | "language">>;
      }
  >;
};

export type GetNewestContentQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  hostnames?: Maybe<Array<Scalars["String"]>>;
}>;

export type GetNewestContentQuery = { __typename?: "query_root" } & {
  MediaContent: Array<
    { __typename?: "MediaContent" } & {
      ExternalToInternalContents: Array<
        { __typename?: "ExternalToInternalContent" } & Pick<ExternalToInternalContent, "host">
      >;
    } & MediaContentInformationFragment
  >;
};

export type GetNewlyAddedContentQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  hostnames?: Maybe<Array<Scalars["String"]>>;
}>;

export type GetNewlyAddedContentQuery = { __typename?: "query_root" } & {
  MediaContent: Array<
    { __typename?: "MediaContent" } & {
      ExternalToInternalContents: Array<
        { __typename?: "ExternalToInternalContent" } & Pick<ExternalToInternalContent, "host">
      >;
    } & MediaContentInformationFragment
  >;
};

export type GetMediaContentQueryVariables = Exact<{
  id: Scalars["uuid"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetMediaContentQuery = { __typename?: "query_root" } & {
  MediaContent_by_pk?: Maybe<
    { __typename?: "MediaContent" } & {
      ExternalToInternalContents: Array<
        { __typename?: "ExternalToInternalContent" } & Pick<ExternalToInternalContent, "host">
      >;
    } & MediaContentInformationFragment
  >;
};

export type GetMediaContentBySlugQueryVariables = Exact<{
  slug: Scalars["String"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetMediaContentBySlugQuery = { __typename?: "query_root" } & {
  MediaContent: Array<
    { __typename?: "MediaContent" } & {
      Episodes: Array<
        { __typename?: "MediaContent" } & Pick<
          MediaContent,
          "id" | "seasonNumber" | "episodeNumber" | "title"
        > & {
            ExternalToInternalContents: Array<
              { __typename?: "ExternalToInternalContent" } & Pick<
                ExternalToInternalContent,
                "host" | "hostContentId"
              >
            >;
          }
      >;
      MediaContentReferences: Array<
        { __typename?: "MediaContentReferences" } & Pick<MediaContentReferences, "tmdbId">
      >;
      ExternalToInternalContents: Array<
        { __typename?: "ExternalToInternalContent" } & Pick<
          ExternalToInternalContent,
          "host" | "hostContentId"
        >
      >;
    } & MediaContentInformationFragment
  >;
};

export type GetPublicPartyQueryVariables = Exact<{
  id: Scalars["uuid"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetPublicPartyQuery = { __typename?: "query_root" } & {
  PublicParties: Array<
    { __typename?: "PublicParties" } & {
      Owner?: Maybe<{ __typename?: "UserPublic" } & Pick<UserPublic, "id">>;
      ExternalContent?: Maybe<
        { __typename?: "ExternalToInternalContent" } & {
          MediaContent?: Maybe<
            { __typename?: "MediaContent" } & {
              MediaContentReferences: Array<
                { __typename?: "MediaContentReferences" } & Pick<MediaContentReferences, "tmdbId">
              >;
            }
          >;
        }
      >;
    } & PartyInformationFragment &
      ContentInformationFragment &
      ParticipantsCountFragment
  >;
};

export type GetSearchResultQueryVariables = Exact<{
  search: Scalars["String"];
  offset: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetSearchResultQuery = { __typename?: "query_root" } & {
  PublicParties: Array<
    { __typename?: "PublicParties" } & PartyInformationFragment &
      ContentInformationFragment &
      ParticipantsCountFragment
  >;
  MediaContent: Array<{ __typename?: "MediaContent" } & MediaContentInformationFragment>;
};

export type GetStreamingServicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStreamingServicesQuery = { __typename?: "query_root" } & {
  StreamingService: Array<
    { __typename?: "StreamingService" } & Pick<StreamingService, "id" | "hostname" | "name">
  >;
};

export type GetResultsByGenreQueryVariables = Exact<{
  genreSlug: Scalars["String"];
  partyOffset: Scalars["Int"];
  partyLimit: Scalars["Int"];
  contentOffset: Scalars["Int"];
  contentLimit: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetResultsByGenreQuery = { __typename?: "query_root" } & {
  Genre: Array<
    { __typename?: "Genre" } & Pick<Genre, "id" | "name"> & {
        GenreI18Ns: Array<{ __typename?: "GenreI18N" } & Pick<GenreI18N, "name" | "language">>;
      }
  >;
  PublicParties: Array<
    { __typename?: "PublicParties" } & Pick<
      PublicParties,
      "id" | "title" | "startTime" | "host"
    > & {
        Owner?: Maybe<{ __typename?: "UserPublic" } & Pick<UserPublic, "username">>;
      } & ContentInformationFragment &
      ParticipantsCountFragment
  >;
  MediaContent: Array<{ __typename?: "MediaContent" } & MediaContentInformationFragment>;
};

export type GetPartiesOfUserQueryVariables = Exact<{
  ownerId: Scalars["String"];
  limit: Scalars["Int"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetPartiesOfUserQuery = { __typename?: "query_root" } & {
  Session: Array<
    { __typename?: "Session" } & MyPartyInformationFragment &
      ContentInformationSessionFragment &
      ParticipantsCountSessionFragment
  >;
};

export type GetPrivatePartyQueryVariables = Exact<{
  id: Scalars["uuid"];
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetPrivatePartyQuery = { __typename?: "query_root" } & {
  Session_by_pk?: Maybe<
    { __typename?: "Session" } & Pick<
      Session,
      "id" | "title" | "startTime" | "host" | "ownerId" | "ownerLeftTime" | "endTime"
    > & {
        User: { __typename?: "User" } & Pick<User, "username">;
      } & ContentInformationSessionFragment &
      ParticipantsCountSessionFragment
  >;
};

export type GetPartyTypeQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPartyTypeQuery = { __typename?: "query_root" } & {
  Session_by_pk?: Maybe<{ __typename?: "Session" } & Pick<Session, "public">>;
};

export type GetPartyForDeleteQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetPartyForDeleteQuery = { __typename?: "query_root" } & {
  Session_by_pk?: Maybe<
    { __typename?: "Session" } & Pick<Session, "ownerId" | "startTime" | "endTime">
  >;
};

export type GetUserStreamingServicesQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetUserStreamingServicesQuery = { __typename?: "query_root" } & {
  User_by_pk?: Maybe<
    { __typename?: "User" } & Pick<User, "id" | "username" | "email"> & {
        UserStreamingServices: Array<
          { __typename?: "UserStreamingService" } & {
            StreamingService: { __typename?: "StreamingService" } & Pick<
              StreamingService,
              "id" | "hostname"
            >;
          }
        >;
      }
  >;
};

export type GetAvatarQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetAvatarQuery = { __typename?: "query_root" } & {
  User_by_pk?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "avatar_version">>;
};

export type GetAccountInformationQueryVariables = Exact<{
  id: Scalars["String"];
  year: Scalars["Int"];
  month: Scalars["Int"];
}>;

export type GetAccountInformationQuery = { __typename?: "query_root" } & {
  User_by_pk?: Maybe<
    { __typename?: "User" } & Pick<User, "id" | "username" | "email" | "avatar_version"> & {
        Budgets: Array<{ __typename?: "Budget" } & Pick<Budget, "timeLeft">>;
        UserStreamingServices: Array<
          { __typename?: "UserStreamingService" } & {
            StreamingService: { __typename?: "StreamingService" } & Pick<
              StreamingService,
              "id" | "hostname"
            >;
          }
        >;
        UserSubscription?: Maybe<
          { __typename?: "UserSubscription" } & Pick<
            UserSubscription,
            "extraTime" | "cancellationEffectiveDate" | "updateUrl" | "cancelUrl"
          > & {
              SubscriptionPlanTier: { __typename?: "SubscriptionPlanTier" } & {
                Tier: { __typename?: "Tier" } & Pick<Tier, "freeTime" | "id" | "unlimitedTime">;
              };
            }
        >;
      }
  >;
};

export type GetTierQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetTierQuery = { __typename?: "query_root" } & {
  UserSubscription_by_pk?: Maybe<
    { __typename?: "UserSubscription" } & {
      SubscriptionPlanTier: { __typename?: "SubscriptionPlanTier" } & {
        Tier: { __typename?: "Tier" } & Pick<Tier, "freeTime" | "id" | "unlimitedTime">;
      };
    }
  >;
};

export type GetSubscriptionInformationQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetSubscriptionInformationQuery = { __typename?: "query_root" } & {
  UserSubscription_by_pk?: Maybe<
    { __typename?: "UserSubscription" } & Pick<
      UserSubscription,
      "cancellationEffectiveDate" | "subscriptionId" | "subscriptionPlanId" | "status"
    >
  >;
};

export type GetSessionQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetSessionQuery = { __typename?: "query_root" } & {
  Session: Array<{ __typename?: "Session" } & Pick<Session, "id" | "title" | "ownerId">>;
};

export type GetAllGenresQueryVariables = Exact<{
  fetchI18n: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
}>;

export type GetAllGenresQuery = { __typename?: "query_root" } & {
  Genre: Array<
    { __typename?: "Genre" } & Pick<Genre, "id" | "name"> & {
        GenreI18Ns: Array<{ __typename?: "GenreI18N" } & Pick<GenreI18N, "name">>;
      }
  >;
};

export type GetUserPrivateQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetUserPrivateQuery = { __typename?: "query_root" } & {
  User_by_pk?: Maybe<
    { __typename?: "User" } & Pick<User, "username"> & {
        UserStreamingServices: Array<
          { __typename?: "UserStreamingService" } & Pick<UserStreamingService, "serviceId">
        >;
      }
  >;
};

export type CheckUsernameQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type CheckUsernameQuery = { __typename?: "query_root" } & {
  UserPublic: Array<{ __typename?: "UserPublic" } & Pick<UserPublic, "username">>;
};

export type UpsertUserMutationVariables = Exact<{
  userId: Scalars["String"];
  user: User_Insert_Input;
  subscriptionPlanId: Scalars["String"];
}>;

export type UpsertUserMutation = { __typename?: "mutation_root" } & {
  delete_UserStreamingService?: Maybe<
    { __typename?: "UserStreamingService_mutation_response" } & Pick<
      UserStreamingService_Mutation_Response,
      "affected_rows"
    >
  >;
  insert_User_one?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
  insert_UserSubscription_one?: Maybe<
    { __typename?: "UserSubscription" } & Pick<UserSubscription, "userId">
  >;
};

export const ParticipantsCountFragmentDoc = gql`
  fragment ParticipantsCount on PublicParties {
    Participants {
      count
    }
    maxParticipants
  }
`;
export const ParticipantsCountSessionFragmentDoc = gql`
  fragment ParticipantsCountSession on Session {
    SessionParticipations_aggregate(where: { active: { _eq: true } }) {
      aggregate {
        count
      }
    }
    maxParticipants
  }
`;
export const MediaContentInformationFragmentDoc = gql`
  fragment MediaContentInformation on MediaContent {
    id
    type
    title
    overview
    slug
    seasonNumber
    episodeNumber
    posterURL
    backdropURL
    stillURL
    releasedOn
    Show {
      id
      type
      title
      overview
      backdropURL
      posterURL
      slug
    }
    GenreMediaContents {
      genreId
      Genre {
        name
      }
    }
    MediaContentI18Ns(where: { language: { _eq: $language } }) @include(if: $fetchI18n) {
      language
      title
      overview
    }
  }
`;
export const ContentInformationFragmentDoc = gql`
  fragment ContentInformation on PublicParties {
    ExternalContent {
      MediaContent {
        ...MediaContentInformation
      }
      UserGeneratedContent {
        title
        subtitle
        episodeNumber
        seasonNumber
        imageURL
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;
export const ContentInformationSessionFragmentDoc = gql`
  fragment ContentInformationSession on Session {
    ExternalToInternalContent {
      MediaContent {
        ...MediaContentInformation
      }
      UserGeneratedContent {
        title
        subtitle
        episodeNumber
        seasonNumber
        imageURL
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;
export const PartyInformationFragmentDoc = gql`
  fragment PartyInformation on PublicParties {
    id
    title
    startTime
    ownerLeftTime
    endTime
    host
    Owner {
      id
      username
      avatar_version
    }
  }
`;
export const MyPartyInformationFragmentDoc = gql`
  fragment MyPartyInformation on Session {
    id
    title
    startTime
    endTime
    host
  }
`;
export const DeleteSessionDocument = gql`
  mutation deleteSession($id: uuid!) {
    delete_Session_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteSessionMutationFn = Apollo.MutationFunction<
  DeleteSessionMutation,
  DeleteSessionMutationVariables
>;

/**
 * __useDeleteSessionMutation__
 *
 * To run a mutation, you first call `useDeleteSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionMutation, { data, loading, error }] = useDeleteSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSessionMutation, DeleteSessionMutationVariables>
) {
  return Apollo.useMutation<DeleteSessionMutation, DeleteSessionMutationVariables>(
    DeleteSessionDocument,
    baseOptions
  );
}
export type DeleteSessionMutationHookResult = ReturnType<typeof useDeleteSessionMutation>;
export type DeleteSessionMutationResult = Apollo.MutationResult<DeleteSessionMutation>;
export type DeleteSessionMutationOptions = Apollo.BaseMutationOptions<
  DeleteSessionMutation,
  DeleteSessionMutationVariables
>;
export const UpdateSubscriptionDocument = gql`
  mutation updateSubscription($userId: String!, $data: UserSubscription_set_input) {
    update_UserSubscription_by_pk(pk_columns: { userId: $userId }, _set: $data) {
      userId
    }
  }
`;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >
) {
  return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(
    UpdateSubscriptionDocument,
    baseOptions
  );
}
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export const UpdateExtraTimeDocument = gql`
  mutation updateExtraTime($userId: String!, $extraTime: Int!) {
    update_UserSubscription_by_pk(
      pk_columns: { userId: $userId }
      _inc: { extraTime: $extraTime }
    ) {
      extraTime
    }
  }
`;
export type UpdateExtraTimeMutationFn = Apollo.MutationFunction<
  UpdateExtraTimeMutation,
  UpdateExtraTimeMutationVariables
>;

/**
 * __useUpdateExtraTimeMutation__
 *
 * To run a mutation, you first call `useUpdateExtraTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraTimeMutation, { data, loading, error }] = useUpdateExtraTimeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      extraTime: // value for 'extraTime'
 *   },
 * });
 */
export function useUpdateExtraTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExtraTimeMutation,
    UpdateExtraTimeMutationVariables
  >
) {
  return Apollo.useMutation<UpdateExtraTimeMutation, UpdateExtraTimeMutationVariables>(
    UpdateExtraTimeDocument,
    baseOptions
  );
}
export type UpdateExtraTimeMutationHookResult = ReturnType<typeof useUpdateExtraTimeMutation>;
export type UpdateExtraTimeMutationResult = Apollo.MutationResult<UpdateExtraTimeMutation>;
export type UpdateExtraTimeMutationOptions = Apollo.BaseMutationOptions<
  UpdateExtraTimeMutation,
  UpdateExtraTimeMutationVariables
>;
export const UpdateUsernameDocument = gql`
  mutation updateUsername($userId: String!, $username: String!) {
    update_User_by_pk(pk_columns: { id: $userId }, _set: { username: $username }) {
      id
    }
  }
`;
export type UpdateUsernameMutationFn = Apollo.MutationFunction<
  UpdateUsernameMutation,
  UpdateUsernameMutationVariables
>;

/**
 * __useUpdateUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsernameMutation, { data, loading, error }] = useUpdateUsernameMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUpdateUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsernameMutation, UpdateUsernameMutationVariables>
) {
  return Apollo.useMutation<UpdateUsernameMutation, UpdateUsernameMutationVariables>(
    UpdateUsernameDocument,
    baseOptions
  );
}
export type UpdateUsernameMutationHookResult = ReturnType<typeof useUpdateUsernameMutation>;
export type UpdateUsernameMutationResult = Apollo.MutationResult<UpdateUsernameMutation>;
export type UpdateUsernameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsernameMutation,
  UpdateUsernameMutationVariables
>;
export const UpdateEmailDocument = gql`
  mutation updateEmail($userId: String!, $email: String!) {
    update_User_by_pk(pk_columns: { id: $userId }, _set: { email: $email }) {
      id
    }
  }
`;
export type UpdateEmailMutationFn = Apollo.MutationFunction<
  UpdateEmailMutation,
  UpdateEmailMutationVariables
>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>
) {
  return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(
    UpdateEmailDocument,
    baseOptions
  );
}
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailMutation,
  UpdateEmailMutationVariables
>;
export const SetStreamingServicesDocument = gql`
  mutation setStreamingServices($userId: String!, $objects: [UserStreamingService_insert_input!]!) {
    delete_UserStreamingService(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    insert_UserStreamingService(objects: $objects) {
      returning {
        serviceId
      }
    }
  }
`;
export type SetStreamingServicesMutationFn = Apollo.MutationFunction<
  SetStreamingServicesMutation,
  SetStreamingServicesMutationVariables
>;

/**
 * __useSetStreamingServicesMutation__
 *
 * To run a mutation, you first call `useSetStreamingServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStreamingServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStreamingServicesMutation, { data, loading, error }] = useSetStreamingServicesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSetStreamingServicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStreamingServicesMutation,
    SetStreamingServicesMutationVariables
  >
) {
  return Apollo.useMutation<SetStreamingServicesMutation, SetStreamingServicesMutationVariables>(
    SetStreamingServicesDocument,
    baseOptions
  );
}
export type SetStreamingServicesMutationHookResult = ReturnType<
  typeof useSetStreamingServicesMutation
>;
export type SetStreamingServicesMutationResult = Apollo.MutationResult<SetStreamingServicesMutation>;
export type SetStreamingServicesMutationOptions = Apollo.BaseMutationOptions<
  SetStreamingServicesMutation,
  SetStreamingServicesMutationVariables
>;
export const SetAvatarVersionDocument = gql`
  mutation setAvatarVersion($userId: String!, $avatarVersion: String!) {
    update_User_by_pk(pk_columns: { id: $userId }, _set: { avatar_version: $avatarVersion }) {
      id
    }
  }
`;
export type SetAvatarVersionMutationFn = Apollo.MutationFunction<
  SetAvatarVersionMutation,
  SetAvatarVersionMutationVariables
>;

/**
 * __useSetAvatarVersionMutation__
 *
 * To run a mutation, you first call `useSetAvatarVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAvatarVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAvatarVersionMutation, { data, loading, error }] = useSetAvatarVersionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      avatarVersion: // value for 'avatarVersion'
 *   },
 * });
 */
export function useSetAvatarVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAvatarVersionMutation,
    SetAvatarVersionMutationVariables
  >
) {
  return Apollo.useMutation<SetAvatarVersionMutation, SetAvatarVersionMutationVariables>(
    SetAvatarVersionDocument,
    baseOptions
  );
}
export type SetAvatarVersionMutationHookResult = ReturnType<typeof useSetAvatarVersionMutation>;
export type SetAvatarVersionMutationResult = Apollo.MutationResult<SetAvatarVersionMutation>;
export type SetAvatarVersionMutationOptions = Apollo.BaseMutationOptions<
  SetAvatarVersionMutation,
  SetAvatarVersionMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: String!) {
    delete_User_by_pk(id: $userId) {
      id
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    baseOptions
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const GetPopularPublicPartiesLiveDocument = gql`
  query getPopularPublicPartiesLive(
    $limit: Int!
    $offset: Int!
    $hostnames: [String!]
    $fetchI18n: Boolean!
    $language: String
  ) {
    PublicParties(
      limit: $limit
      offset: $offset
      where: {
        Participants: { count: { _gt: 0 } }
        ownerLeftTime: { _is_null: true }
        host: { _in: $hostnames }
      }
      order_by: { Participants: { count: desc_nulls_last } }
    ) {
      ...PartyInformation
      ...ParticipantsCount
      ...ContentInformation
    }
  }
  ${PartyInformationFragmentDoc}
  ${ParticipantsCountFragmentDoc}
  ${ContentInformationFragmentDoc}
`;

/**
 * __useGetPopularPublicPartiesLiveQuery__
 *
 * To run a query within a React component, call `useGetPopularPublicPartiesLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopularPublicPartiesLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopularPublicPartiesLiveQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      hostnames: // value for 'hostnames'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetPopularPublicPartiesLiveQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPopularPublicPartiesLiveQuery,
    GetPopularPublicPartiesLiveQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPopularPublicPartiesLiveQuery,
    GetPopularPublicPartiesLiveQueryVariables
  >(GetPopularPublicPartiesLiveDocument, baseOptions);
}
export function useGetPopularPublicPartiesLiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPopularPublicPartiesLiveQuery,
    GetPopularPublicPartiesLiveQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPopularPublicPartiesLiveQuery,
    GetPopularPublicPartiesLiveQueryVariables
  >(GetPopularPublicPartiesLiveDocument, baseOptions);
}
export type GetPopularPublicPartiesLiveQueryHookResult = ReturnType<
  typeof useGetPopularPublicPartiesLiveQuery
>;
export type GetPopularPublicPartiesLiveLazyQueryHookResult = ReturnType<
  typeof useGetPopularPublicPartiesLiveLazyQuery
>;
export type GetPopularPublicPartiesLiveQueryResult = Apollo.QueryResult<
  GetPopularPublicPartiesLiveQuery,
  GetPopularPublicPartiesLiveQueryVariables
>;
export const GetUpcomingPartiesDocument = gql`
  query getUpcomingParties(
    $currentTime: timestamp!
    $limit: Int!
    $offset: Int!
    $hostnames: [String!]
    $fetchI18n: Boolean!
    $language: String
  ) {
    PublicParties(
      limit: $limit
      offset: $offset
      where: { startTime: { _gt: $currentTime }, host: { _in: $hostnames } }
      order_by: { startTime: asc_nulls_last }
    ) {
      ...PartyInformation
      ...ParticipantsCount
      ...ContentInformation
    }
  }
  ${PartyInformationFragmentDoc}
  ${ParticipantsCountFragmentDoc}
  ${ContentInformationFragmentDoc}
`;

/**
 * __useGetUpcomingPartiesQuery__
 *
 * To run a query within a React component, call `useGetUpcomingPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingPartiesQuery({
 *   variables: {
 *      currentTime: // value for 'currentTime'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      hostnames: // value for 'hostnames'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetUpcomingPartiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUpcomingPartiesQuery, GetUpcomingPartiesQueryVariables>
) {
  return Apollo.useQuery<GetUpcomingPartiesQuery, GetUpcomingPartiesQueryVariables>(
    GetUpcomingPartiesDocument,
    baseOptions
  );
}
export function useGetUpcomingPartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingPartiesQuery,
    GetUpcomingPartiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUpcomingPartiesQuery, GetUpcomingPartiesQueryVariables>(
    GetUpcomingPartiesDocument,
    baseOptions
  );
}
export type GetUpcomingPartiesQueryHookResult = ReturnType<typeof useGetUpcomingPartiesQuery>;
export type GetUpcomingPartiesLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingPartiesLazyQuery
>;
export type GetUpcomingPartiesQueryResult = Apollo.QueryResult<
  GetUpcomingPartiesQuery,
  GetUpcomingPartiesQueryVariables
>;
export const GetPartiesByContentDocument = gql`
  query getPartiesByContent(
    $contentId: uuid!
    $currentTime: timestamp!
    $limit: Int!
    $offset: Int!
    $fetchI18n: Boolean!
    $language: String
    $excludeId: uuid
  ) {
    PublicParties(
      limit: $limit
      offset: $offset
      where: {
        _not: { id: { _eq: $excludeId } }
        _or: [
          { Participants: { count: { _gt: 0 } }, ownerLeftTime: { _is_null: true } }
          { startTime: { _gt: $currentTime } }
        ]
        ExternalContent: { MediaContent: { id: { _eq: $contentId } } }
      }
      order_by: { startTime: asc_nulls_last }
    ) {
      ...PartyInformation
      ...ParticipantsCount
      ...ContentInformation
    }
  }
  ${PartyInformationFragmentDoc}
  ${ParticipantsCountFragmentDoc}
  ${ContentInformationFragmentDoc}
`;

/**
 * __useGetPartiesByContentQuery__
 *
 * To run a query within a React component, call `useGetPartiesByContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartiesByContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartiesByContentQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      currentTime: // value for 'currentTime'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *      excludeId: // value for 'excludeId'
 *   },
 * });
 */
export function useGetPartiesByContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPartiesByContentQuery, GetPartiesByContentQueryVariables>
) {
  return Apollo.useQuery<GetPartiesByContentQuery, GetPartiesByContentQueryVariables>(
    GetPartiesByContentDocument,
    baseOptions
  );
}
export function useGetPartiesByContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartiesByContentQuery,
    GetPartiesByContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPartiesByContentQuery, GetPartiesByContentQueryVariables>(
    GetPartiesByContentDocument,
    baseOptions
  );
}
export type GetPartiesByContentQueryHookResult = ReturnType<typeof useGetPartiesByContentQuery>;
export type GetPartiesByContentLazyQueryHookResult = ReturnType<
  typeof useGetPartiesByContentLazyQuery
>;
export type GetPartiesByContentQueryResult = Apollo.QueryResult<
  GetPartiesByContentQuery,
  GetPartiesByContentQueryVariables
>;
export const GetPopularContentDocument = gql`
  query getPopularContent(
    $limit: Int!
    $fetchI18n: Boolean!
    $language: String
    $hostnames: [String!]
  ) {
    MostWatchedContent(
      limit: $limit
      order_by: { count: desc }
      where: { MediaContent: { ExternalToInternalContents: { host: { _in: $hostnames } } } }
    ) {
      MediaContent {
        ...MediaContentInformation
        ExternalToInternalContents {
          host
        }
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetPopularContentQuery__
 *
 * To run a query within a React component, call `useGetPopularContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopularContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopularContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *      hostnames: // value for 'hostnames'
 *   },
 * });
 */
export function useGetPopularContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPopularContentQuery, GetPopularContentQueryVariables>
) {
  return Apollo.useQuery<GetPopularContentQuery, GetPopularContentQueryVariables>(
    GetPopularContentDocument,
    baseOptions
  );
}
export function useGetPopularContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPopularContentQuery, GetPopularContentQueryVariables>
) {
  return Apollo.useLazyQuery<GetPopularContentQuery, GetPopularContentQueryVariables>(
    GetPopularContentDocument,
    baseOptions
  );
}
export type GetPopularContentQueryHookResult = ReturnType<typeof useGetPopularContentQuery>;
export type GetPopularContentLazyQueryHookResult = ReturnType<typeof useGetPopularContentLazyQuery>;
export type GetPopularContentQueryResult = Apollo.QueryResult<
  GetPopularContentQuery,
  GetPopularContentQueryVariables
>;
export const GetCategoriesDocument = gql`
  query getCategories($fetchI18n: Boolean!, $language: String) {
    Genre {
      id
      name
      slug
      GenreI18Ns(where: { language: { _eq: $language } }) @include(if: $fetchI18n) {
        name
        language
      }
    }
  }
`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>
) {
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    baseOptions
  );
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>
) {
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    baseOptions
  );
}
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<
  GetCategoriesQuery,
  GetCategoriesQueryVariables
>;
export const GetNewestContentDocument = gql`
  query getNewestContent(
    $limit: Int!
    $offset: Int!
    $fetchI18n: Boolean!
    $language: String
    $hostnames: [String!]
  ) {
    MediaContent(
      order_by: { releasedOn: desc_nulls_last }
      limit: $limit
      offset: $offset
      where: {
        ExternalToInternalContents: { host: { _in: $hostnames } }
        _or: [{ type: { _eq: "show" } }, { type: { _eq: "movie" } }]
      }
    ) {
      ...MediaContentInformation
      ExternalToInternalContents {
        host
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetNewestContentQuery__
 *
 * To run a query within a React component, call `useGetNewestContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewestContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewestContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *      hostnames: // value for 'hostnames'
 *   },
 * });
 */
export function useGetNewestContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNewestContentQuery, GetNewestContentQueryVariables>
) {
  return Apollo.useQuery<GetNewestContentQuery, GetNewestContentQueryVariables>(
    GetNewestContentDocument,
    baseOptions
  );
}
export function useGetNewestContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNewestContentQuery, GetNewestContentQueryVariables>
) {
  return Apollo.useLazyQuery<GetNewestContentQuery, GetNewestContentQueryVariables>(
    GetNewestContentDocument,
    baseOptions
  );
}
export type GetNewestContentQueryHookResult = ReturnType<typeof useGetNewestContentQuery>;
export type GetNewestContentLazyQueryHookResult = ReturnType<typeof useGetNewestContentLazyQuery>;
export type GetNewestContentQueryResult = Apollo.QueryResult<
  GetNewestContentQuery,
  GetNewestContentQueryVariables
>;
export const GetNewlyAddedContentDocument = gql`
  query getNewlyAddedContent(
    $limit: Int!
    $offset: Int!
    $fetchI18n: Boolean!
    $language: String
    $hostnames: [String!]
  ) {
    MediaContent(
      order_by: { createdAt: desc_nulls_last }
      limit: $limit
      offset: $offset
      where: {
        ExternalToInternalContents: { host: { _in: $hostnames } }
        _or: [{ type: { _eq: "show" } }, { type: { _eq: "movie" } }]
      }
    ) {
      ...MediaContentInformation
      ExternalToInternalContents {
        host
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetNewlyAddedContentQuery__
 *
 * To run a query within a React component, call `useGetNewlyAddedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewlyAddedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewlyAddedContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *      hostnames: // value for 'hostnames'
 *   },
 * });
 */
export function useGetNewlyAddedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNewlyAddedContentQuery,
    GetNewlyAddedContentQueryVariables
  >
) {
  return Apollo.useQuery<GetNewlyAddedContentQuery, GetNewlyAddedContentQueryVariables>(
    GetNewlyAddedContentDocument,
    baseOptions
  );
}
export function useGetNewlyAddedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewlyAddedContentQuery,
    GetNewlyAddedContentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetNewlyAddedContentQuery, GetNewlyAddedContentQueryVariables>(
    GetNewlyAddedContentDocument,
    baseOptions
  );
}
export type GetNewlyAddedContentQueryHookResult = ReturnType<typeof useGetNewlyAddedContentQuery>;
export type GetNewlyAddedContentLazyQueryHookResult = ReturnType<
  typeof useGetNewlyAddedContentLazyQuery
>;
export type GetNewlyAddedContentQueryResult = Apollo.QueryResult<
  GetNewlyAddedContentQuery,
  GetNewlyAddedContentQueryVariables
>;
export const GetMediaContentDocument = gql`
  query getMediaContent($id: uuid!, $fetchI18n: Boolean!, $language: String) {
    MediaContent_by_pk(id: $id) {
      ...MediaContentInformation
      ExternalToInternalContents {
        host
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetMediaContentQuery__
 *
 * To run a query within a React component, call `useGetMediaContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetMediaContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMediaContentQuery, GetMediaContentQueryVariables>
) {
  return Apollo.useQuery<GetMediaContentQuery, GetMediaContentQueryVariables>(
    GetMediaContentDocument,
    baseOptions
  );
}
export function useGetMediaContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMediaContentQuery, GetMediaContentQueryVariables>
) {
  return Apollo.useLazyQuery<GetMediaContentQuery, GetMediaContentQueryVariables>(
    GetMediaContentDocument,
    baseOptions
  );
}
export type GetMediaContentQueryHookResult = ReturnType<typeof useGetMediaContentQuery>;
export type GetMediaContentLazyQueryHookResult = ReturnType<typeof useGetMediaContentLazyQuery>;
export type GetMediaContentQueryResult = Apollo.QueryResult<
  GetMediaContentQuery,
  GetMediaContentQueryVariables
>;
export const GetMediaContentBySlugDocument = gql`
  query getMediaContentBySlug($slug: String!, $fetchI18n: Boolean!, $language: String) {
    MediaContent(where: { slug: { _eq: $slug } }) {
      ...MediaContentInformation
      Episodes {
        id
        seasonNumber
        episodeNumber
        title
        ExternalToInternalContents {
          host
          hostContentId
        }
      }
      MediaContentReferences {
        tmdbId
      }
      ExternalToInternalContents {
        host
        hostContentId
      }
    }
  }
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetMediaContentBySlugQuery__
 *
 * To run a query within a React component, call `useGetMediaContentBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaContentBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaContentBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetMediaContentBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMediaContentBySlugQuery,
    GetMediaContentBySlugQueryVariables
  >
) {
  return Apollo.useQuery<GetMediaContentBySlugQuery, GetMediaContentBySlugQueryVariables>(
    GetMediaContentBySlugDocument,
    baseOptions
  );
}
export function useGetMediaContentBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaContentBySlugQuery,
    GetMediaContentBySlugQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetMediaContentBySlugQuery, GetMediaContentBySlugQueryVariables>(
    GetMediaContentBySlugDocument,
    baseOptions
  );
}
export type GetMediaContentBySlugQueryHookResult = ReturnType<typeof useGetMediaContentBySlugQuery>;
export type GetMediaContentBySlugLazyQueryHookResult = ReturnType<
  typeof useGetMediaContentBySlugLazyQuery
>;
export type GetMediaContentBySlugQueryResult = Apollo.QueryResult<
  GetMediaContentBySlugQuery,
  GetMediaContentBySlugQueryVariables
>;
export const GetPublicPartyDocument = gql`
  query getPublicParty($id: uuid!, $fetchI18n: Boolean!, $language: String) {
    PublicParties(where: { id: { _eq: $id } }) {
      ...PartyInformation
      Owner {
        id
      }
      ExternalContent {
        MediaContent {
          MediaContentReferences {
            tmdbId
          }
        }
      }
      ...ContentInformation
      ...ParticipantsCount
    }
  }
  ${PartyInformationFragmentDoc}
  ${ContentInformationFragmentDoc}
  ${ParticipantsCountFragmentDoc}
`;

/**
 * __useGetPublicPartyQuery__
 *
 * To run a query within a React component, call `useGetPublicPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicPartyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetPublicPartyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPublicPartyQuery, GetPublicPartyQueryVariables>
) {
  return Apollo.useQuery<GetPublicPartyQuery, GetPublicPartyQueryVariables>(
    GetPublicPartyDocument,
    baseOptions
  );
}
export function useGetPublicPartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPublicPartyQuery, GetPublicPartyQueryVariables>
) {
  return Apollo.useLazyQuery<GetPublicPartyQuery, GetPublicPartyQueryVariables>(
    GetPublicPartyDocument,
    baseOptions
  );
}
export type GetPublicPartyQueryHookResult = ReturnType<typeof useGetPublicPartyQuery>;
export type GetPublicPartyLazyQueryHookResult = ReturnType<typeof useGetPublicPartyLazyQuery>;
export type GetPublicPartyQueryResult = Apollo.QueryResult<
  GetPublicPartyQuery,
  GetPublicPartyQueryVariables
>;
export const GetSearchResultDocument = gql`
  query getSearchResult($search: String!, $offset: Int!, $fetchI18n: Boolean!, $language: String) {
    PublicParties(
      limit: 5
      offset: $offset
      where: {
        Participants: { count: { _gt: 0 } }
        ownerLeftTime: { _is_null: true }
        _or: [
          { title: { _ilike: $search } }
          { ExternalContent: { MediaContent: { title: { _ilike: $search } } } }
        ]
      }
      order_by: [{ Participants: { count: desc_nulls_last } }, { startTime: desc_nulls_last }]
    ) {
      ...PartyInformation
      ...ContentInformation
      ...ParticipantsCount
    }
    MediaContent(
      limit: 20
      where: {
        title: { _ilike: $search }
        _or: [{ type: { _eq: "movie" } }, { type: { _eq: "show" } }]
      }
      order_by: { releasedOn: desc_nulls_last }
    ) {
      ...MediaContentInformation
    }
  }
  ${PartyInformationFragmentDoc}
  ${ContentInformationFragmentDoc}
  ${ParticipantsCountFragmentDoc}
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetSearchResultQuery__
 *
 * To run a query within a React component, call `useGetSearchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetSearchResultQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSearchResultQuery, GetSearchResultQueryVariables>
) {
  return Apollo.useQuery<GetSearchResultQuery, GetSearchResultQueryVariables>(
    GetSearchResultDocument,
    baseOptions
  );
}
export function useGetSearchResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultQuery, GetSearchResultQueryVariables>
) {
  return Apollo.useLazyQuery<GetSearchResultQuery, GetSearchResultQueryVariables>(
    GetSearchResultDocument,
    baseOptions
  );
}
export type GetSearchResultQueryHookResult = ReturnType<typeof useGetSearchResultQuery>;
export type GetSearchResultLazyQueryHookResult = ReturnType<typeof useGetSearchResultLazyQuery>;
export type GetSearchResultQueryResult = Apollo.QueryResult<
  GetSearchResultQuery,
  GetSearchResultQueryVariables
>;
export const GetStreamingServicesDocument = gql`
  query getStreamingServices {
    StreamingService {
      id
      hostname
      name
    }
  }
`;

/**
 * __useGetStreamingServicesQuery__
 *
 * To run a query within a React component, call `useGetStreamingServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamingServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamingServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStreamingServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStreamingServicesQuery,
    GetStreamingServicesQueryVariables
  >
) {
  return Apollo.useQuery<GetStreamingServicesQuery, GetStreamingServicesQueryVariables>(
    GetStreamingServicesDocument,
    baseOptions
  );
}
export function useGetStreamingServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStreamingServicesQuery,
    GetStreamingServicesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetStreamingServicesQuery, GetStreamingServicesQueryVariables>(
    GetStreamingServicesDocument,
    baseOptions
  );
}
export type GetStreamingServicesQueryHookResult = ReturnType<typeof useGetStreamingServicesQuery>;
export type GetStreamingServicesLazyQueryHookResult = ReturnType<
  typeof useGetStreamingServicesLazyQuery
>;
export type GetStreamingServicesQueryResult = Apollo.QueryResult<
  GetStreamingServicesQuery,
  GetStreamingServicesQueryVariables
>;
export const GetResultsByGenreDocument = gql`
  query getResultsByGenre(
    $genreSlug: String!
    $partyOffset: Int!
    $partyLimit: Int!
    $contentOffset: Int!
    $contentLimit: Int!
    $fetchI18n: Boolean!
    $language: String
  ) {
    Genre(where: { slug: { _eq: $genreSlug } }) {
      id
      name
      GenreI18Ns(where: { language: { _eq: $language } }) @include(if: $fetchI18n) {
        name
        language
      }
    }
    PublicParties(
      limit: $partyLimit
      offset: $partyOffset
      where: {
        ExternalContent: {
          MediaContent: { GenreMediaContents: { Genre: { slug: { _eq: $genreSlug } } } }
        }
      }
      order_by: [{ Participants: { count: desc_nulls_last } }, { startTime: desc_nulls_last }]
    ) {
      id
      title
      startTime
      host
      Owner {
        username
      }
      ...ContentInformation
      ...ParticipantsCount
    }
    MediaContent(
      offset: $contentOffset
      limit: $contentLimit
      where: {
        GenreMediaContents: { Genre: { slug: { _eq: $genreSlug } } }
        _or: [{ type: { _eq: "movie" } }, { type: { _eq: "show" } }]
      }
      order_by: { releasedOn: desc_nulls_last }
    ) {
      ...MediaContentInformation
    }
  }
  ${ContentInformationFragmentDoc}
  ${ParticipantsCountFragmentDoc}
  ${MediaContentInformationFragmentDoc}
`;

/**
 * __useGetResultsByGenreQuery__
 *
 * To run a query within a React component, call `useGetResultsByGenreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultsByGenreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultsByGenreQuery({
 *   variables: {
 *      genreSlug: // value for 'genreSlug'
 *      partyOffset: // value for 'partyOffset'
 *      partyLimit: // value for 'partyLimit'
 *      contentOffset: // value for 'contentOffset'
 *      contentLimit: // value for 'contentLimit'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetResultsByGenreQuery(
  baseOptions?: Apollo.QueryHookOptions<GetResultsByGenreQuery, GetResultsByGenreQueryVariables>
) {
  return Apollo.useQuery<GetResultsByGenreQuery, GetResultsByGenreQueryVariables>(
    GetResultsByGenreDocument,
    baseOptions
  );
}
export function useGetResultsByGenreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetResultsByGenreQuery, GetResultsByGenreQueryVariables>
) {
  return Apollo.useLazyQuery<GetResultsByGenreQuery, GetResultsByGenreQueryVariables>(
    GetResultsByGenreDocument,
    baseOptions
  );
}
export type GetResultsByGenreQueryHookResult = ReturnType<typeof useGetResultsByGenreQuery>;
export type GetResultsByGenreLazyQueryHookResult = ReturnType<typeof useGetResultsByGenreLazyQuery>;
export type GetResultsByGenreQueryResult = Apollo.QueryResult<
  GetResultsByGenreQuery,
  GetResultsByGenreQueryVariables
>;
export const GetPartiesOfUserDocument = gql`
  query getPartiesOfUser($ownerId: String!, $limit: Int!, $fetchI18n: Boolean!, $language: String) {
    Session(
      limit: $limit
      where: { ownerId: { _eq: $ownerId }, endTime: { _is_null: true } }
      order_by: { startTime: asc_nulls_last }
    ) {
      ...MyPartyInformation
      ...ContentInformationSession
      ...ParticipantsCountSession
    }
  }
  ${MyPartyInformationFragmentDoc}
  ${ContentInformationSessionFragmentDoc}
  ${ParticipantsCountSessionFragmentDoc}
`;

/**
 * __useGetPartiesOfUserQuery__
 *
 * To run a query within a React component, call `useGetPartiesOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartiesOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartiesOfUserQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      limit: // value for 'limit'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetPartiesOfUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPartiesOfUserQuery, GetPartiesOfUserQueryVariables>
) {
  return Apollo.useQuery<GetPartiesOfUserQuery, GetPartiesOfUserQueryVariables>(
    GetPartiesOfUserDocument,
    baseOptions
  );
}
export function useGetPartiesOfUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartiesOfUserQuery, GetPartiesOfUserQueryVariables>
) {
  return Apollo.useLazyQuery<GetPartiesOfUserQuery, GetPartiesOfUserQueryVariables>(
    GetPartiesOfUserDocument,
    baseOptions
  );
}
export type GetPartiesOfUserQueryHookResult = ReturnType<typeof useGetPartiesOfUserQuery>;
export type GetPartiesOfUserLazyQueryHookResult = ReturnType<typeof useGetPartiesOfUserLazyQuery>;
export type GetPartiesOfUserQueryResult = Apollo.QueryResult<
  GetPartiesOfUserQuery,
  GetPartiesOfUserQueryVariables
>;
export const GetPrivatePartyDocument = gql`
  query getPrivateParty($id: uuid!, $fetchI18n: Boolean!, $language: String) {
    Session_by_pk(id: $id) {
      id
      title
      startTime
      host
      ownerId
      ownerLeftTime
      endTime
      ...ContentInformationSession
      ...ParticipantsCountSession
      User {
        username
      }
    }
  }
  ${ContentInformationSessionFragmentDoc}
  ${ParticipantsCountSessionFragmentDoc}
`;

/**
 * __useGetPrivatePartyQuery__
 *
 * To run a query within a React component, call `useGetPrivatePartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivatePartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivatePartyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetPrivatePartyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPrivatePartyQuery, GetPrivatePartyQueryVariables>
) {
  return Apollo.useQuery<GetPrivatePartyQuery, GetPrivatePartyQueryVariables>(
    GetPrivatePartyDocument,
    baseOptions
  );
}
export function useGetPrivatePartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrivatePartyQuery, GetPrivatePartyQueryVariables>
) {
  return Apollo.useLazyQuery<GetPrivatePartyQuery, GetPrivatePartyQueryVariables>(
    GetPrivatePartyDocument,
    baseOptions
  );
}
export type GetPrivatePartyQueryHookResult = ReturnType<typeof useGetPrivatePartyQuery>;
export type GetPrivatePartyLazyQueryHookResult = ReturnType<typeof useGetPrivatePartyLazyQuery>;
export type GetPrivatePartyQueryResult = Apollo.QueryResult<
  GetPrivatePartyQuery,
  GetPrivatePartyQueryVariables
>;
export const GetPartyTypeDocument = gql`
  query getPartyType($id: uuid!) {
    Session_by_pk(id: $id) {
      public
    }
  }
`;

/**
 * __useGetPartyTypeQuery__
 *
 * To run a query within a React component, call `useGetPartyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartyTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPartyTypeQuery, GetPartyTypeQueryVariables>
) {
  return Apollo.useQuery<GetPartyTypeQuery, GetPartyTypeQueryVariables>(
    GetPartyTypeDocument,
    baseOptions
  );
}
export function useGetPartyTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartyTypeQuery, GetPartyTypeQueryVariables>
) {
  return Apollo.useLazyQuery<GetPartyTypeQuery, GetPartyTypeQueryVariables>(
    GetPartyTypeDocument,
    baseOptions
  );
}
export type GetPartyTypeQueryHookResult = ReturnType<typeof useGetPartyTypeQuery>;
export type GetPartyTypeLazyQueryHookResult = ReturnType<typeof useGetPartyTypeLazyQuery>;
export type GetPartyTypeQueryResult = Apollo.QueryResult<
  GetPartyTypeQuery,
  GetPartyTypeQueryVariables
>;
export const GetPartyForDeleteDocument = gql`
  query getPartyForDelete($id: uuid!) {
    Session_by_pk(id: $id) {
      ownerId
      startTime
      endTime
    }
  }
`;

/**
 * __useGetPartyForDeleteQuery__
 *
 * To run a query within a React component, call `useGetPartyForDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartyForDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartyForDeleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartyForDeleteQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPartyForDeleteQuery, GetPartyForDeleteQueryVariables>
) {
  return Apollo.useQuery<GetPartyForDeleteQuery, GetPartyForDeleteQueryVariables>(
    GetPartyForDeleteDocument,
    baseOptions
  );
}
export function useGetPartyForDeleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartyForDeleteQuery, GetPartyForDeleteQueryVariables>
) {
  return Apollo.useLazyQuery<GetPartyForDeleteQuery, GetPartyForDeleteQueryVariables>(
    GetPartyForDeleteDocument,
    baseOptions
  );
}
export type GetPartyForDeleteQueryHookResult = ReturnType<typeof useGetPartyForDeleteQuery>;
export type GetPartyForDeleteLazyQueryHookResult = ReturnType<typeof useGetPartyForDeleteLazyQuery>;
export type GetPartyForDeleteQueryResult = Apollo.QueryResult<
  GetPartyForDeleteQuery,
  GetPartyForDeleteQueryVariables
>;
export const GetUserStreamingServicesDocument = gql`
  query getUserStreamingServices($id: String!) {
    User_by_pk(id: $id) {
      id
      username
      email
      UserStreamingServices {
        StreamingService {
          id
          hostname
        }
      }
    }
  }
`;

/**
 * __useGetUserStreamingServicesQuery__
 *
 * To run a query within a React component, call `useGetUserStreamingServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStreamingServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStreamingServicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserStreamingServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserStreamingServicesQuery,
    GetUserStreamingServicesQueryVariables
  >
) {
  return Apollo.useQuery<GetUserStreamingServicesQuery, GetUserStreamingServicesQueryVariables>(
    GetUserStreamingServicesDocument,
    baseOptions
  );
}
export function useGetUserStreamingServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStreamingServicesQuery,
    GetUserStreamingServicesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserStreamingServicesQuery, GetUserStreamingServicesQueryVariables>(
    GetUserStreamingServicesDocument,
    baseOptions
  );
}
export type GetUserStreamingServicesQueryHookResult = ReturnType<
  typeof useGetUserStreamingServicesQuery
>;
export type GetUserStreamingServicesLazyQueryHookResult = ReturnType<
  typeof useGetUserStreamingServicesLazyQuery
>;
export type GetUserStreamingServicesQueryResult = Apollo.QueryResult<
  GetUserStreamingServicesQuery,
  GetUserStreamingServicesQueryVariables
>;
export const GetAvatarDocument = gql`
  query getAvatar($id: String!) {
    User_by_pk(id: $id) {
      id
      avatar_version
    }
  }
`;

/**
 * __useGetAvatarQuery__
 *
 * To run a query within a React component, call `useGetAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvatarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAvatarQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAvatarQuery, GetAvatarQueryVariables>
) {
  return Apollo.useQuery<GetAvatarQuery, GetAvatarQueryVariables>(GetAvatarDocument, baseOptions);
}
export function useGetAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAvatarQuery, GetAvatarQueryVariables>
) {
  return Apollo.useLazyQuery<GetAvatarQuery, GetAvatarQueryVariables>(
    GetAvatarDocument,
    baseOptions
  );
}
export type GetAvatarQueryHookResult = ReturnType<typeof useGetAvatarQuery>;
export type GetAvatarLazyQueryHookResult = ReturnType<typeof useGetAvatarLazyQuery>;
export type GetAvatarQueryResult = Apollo.QueryResult<GetAvatarQuery, GetAvatarQueryVariables>;
export const GetAccountInformationDocument = gql`
  query getAccountInformation($id: String!, $year: Int!, $month: Int!) {
    User_by_pk(id: $id) {
      id
      username
      email
      avatar_version
      Budgets(where: { year: { _eq: $year }, month: { _eq: $month } }) {
        timeLeft
      }
      UserStreamingServices {
        StreamingService {
          id
          hostname
        }
      }
      UserSubscription {
        extraTime
        cancellationEffectiveDate
        updateUrl
        cancelUrl
        SubscriptionPlanTier {
          Tier {
            freeTime
            id
            unlimitedTime
          }
        }
      }
    }
  }
`;

/**
 * __useGetAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetAccountInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountInformationQuery,
    GetAccountInformationQueryVariables
  >
) {
  return Apollo.useQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(
    GetAccountInformationDocument,
    baseOptions
  );
}
export function useGetAccountInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountInformationQuery,
    GetAccountInformationQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(
    GetAccountInformationDocument,
    baseOptions
  );
}
export type GetAccountInformationQueryHookResult = ReturnType<typeof useGetAccountInformationQuery>;
export type GetAccountInformationLazyQueryHookResult = ReturnType<
  typeof useGetAccountInformationLazyQuery
>;
export type GetAccountInformationQueryResult = Apollo.QueryResult<
  GetAccountInformationQuery,
  GetAccountInformationQueryVariables
>;
export const GetTierDocument = gql`
  query getTier($userId: String!) {
    UserSubscription_by_pk(userId: $userId) {
      SubscriptionPlanTier {
        Tier {
          freeTime
          id
          unlimitedTime
        }
      }
    }
  }
`;

/**
 * __useGetTierQuery__
 *
 * To run a query within a React component, call `useGetTierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTierQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTierQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTierQuery, GetTierQueryVariables>
) {
  return Apollo.useQuery<GetTierQuery, GetTierQueryVariables>(GetTierDocument, baseOptions);
}
export function useGetTierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTierQuery, GetTierQueryVariables>
) {
  return Apollo.useLazyQuery<GetTierQuery, GetTierQueryVariables>(GetTierDocument, baseOptions);
}
export type GetTierQueryHookResult = ReturnType<typeof useGetTierQuery>;
export type GetTierLazyQueryHookResult = ReturnType<typeof useGetTierLazyQuery>;
export type GetTierQueryResult = Apollo.QueryResult<GetTierQuery, GetTierQueryVariables>;
export const GetSubscriptionInformationDocument = gql`
  query getSubscriptionInformation($userId: String!) {
    UserSubscription_by_pk(userId: $userId) {
      cancellationEffectiveDate
      subscriptionId
      subscriptionPlanId
      status
    }
  }
`;

/**
 * __useGetSubscriptionInformationQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionInformationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSubscriptionInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionInformationQuery,
    GetSubscriptionInformationQueryVariables
  >
) {
  return Apollo.useQuery<GetSubscriptionInformationQuery, GetSubscriptionInformationQueryVariables>(
    GetSubscriptionInformationDocument,
    baseOptions
  );
}
export function useGetSubscriptionInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionInformationQuery,
    GetSubscriptionInformationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSubscriptionInformationQuery,
    GetSubscriptionInformationQueryVariables
  >(GetSubscriptionInformationDocument, baseOptions);
}
export type GetSubscriptionInformationQueryHookResult = ReturnType<
  typeof useGetSubscriptionInformationQuery
>;
export type GetSubscriptionInformationLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionInformationLazyQuery
>;
export type GetSubscriptionInformationQueryResult = Apollo.QueryResult<
  GetSubscriptionInformationQuery,
  GetSubscriptionInformationQueryVariables
>;
export const GetSessionDocument = gql`
  query getSession($id: uuid!) {
    Session(where: { id: { _eq: $id } }) {
      id
      title
      ownerId
    }
  }
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>
) {
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    baseOptions
  );
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>
) {
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    baseOptions
  );
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export const GetAllGenresDocument = gql`
  query getAllGenres($fetchI18n: Boolean!, $language: String) {
    Genre {
      id
      name
      GenreI18Ns(where: { language: { _eq: $language } }) @include(if: $fetchI18n) {
        name
      }
    }
  }
`;

/**
 * __useGetAllGenresQuery__
 *
 * To run a query within a React component, call `useGetAllGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGenresQuery({
 *   variables: {
 *      fetchI18n: // value for 'fetchI18n'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetAllGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllGenresQuery, GetAllGenresQueryVariables>
) {
  return Apollo.useQuery<GetAllGenresQuery, GetAllGenresQueryVariables>(
    GetAllGenresDocument,
    baseOptions
  );
}
export function useGetAllGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllGenresQuery, GetAllGenresQueryVariables>
) {
  return Apollo.useLazyQuery<GetAllGenresQuery, GetAllGenresQueryVariables>(
    GetAllGenresDocument,
    baseOptions
  );
}
export type GetAllGenresQueryHookResult = ReturnType<typeof useGetAllGenresQuery>;
export type GetAllGenresLazyQueryHookResult = ReturnType<typeof useGetAllGenresLazyQuery>;
export type GetAllGenresQueryResult = Apollo.QueryResult<
  GetAllGenresQuery,
  GetAllGenresQueryVariables
>;
export const GetUserPrivateDocument = gql`
  query getUserPrivate($id: String!) {
    User_by_pk(id: $id) {
      username
      UserStreamingServices {
        serviceId
      }
    }
  }
`;

/**
 * __useGetUserPrivateQuery__
 *
 * To run a query within a React component, call `useGetUserPrivateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPrivateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPrivateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserPrivateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserPrivateQuery, GetUserPrivateQueryVariables>
) {
  return Apollo.useQuery<GetUserPrivateQuery, GetUserPrivateQueryVariables>(
    GetUserPrivateDocument,
    baseOptions
  );
}
export function useGetUserPrivateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPrivateQuery, GetUserPrivateQueryVariables>
) {
  return Apollo.useLazyQuery<GetUserPrivateQuery, GetUserPrivateQueryVariables>(
    GetUserPrivateDocument,
    baseOptions
  );
}
export type GetUserPrivateQueryHookResult = ReturnType<typeof useGetUserPrivateQuery>;
export type GetUserPrivateLazyQueryHookResult = ReturnType<typeof useGetUserPrivateLazyQuery>;
export type GetUserPrivateQueryResult = Apollo.QueryResult<
  GetUserPrivateQuery,
  GetUserPrivateQueryVariables
>;
export const CheckUsernameDocument = gql`
  query checkUsername($username: String!) {
    UserPublic(where: { username: { _eq: $username } }) {
      username
    }
  }
`;

/**
 * __useCheckUsernameQuery__
 *
 * To run a query within a React component, call `useCheckUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCheckUsernameQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckUsernameQuery, CheckUsernameQueryVariables>
) {
  return Apollo.useQuery<CheckUsernameQuery, CheckUsernameQueryVariables>(
    CheckUsernameDocument,
    baseOptions
  );
}
export function useCheckUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckUsernameQuery, CheckUsernameQueryVariables>
) {
  return Apollo.useLazyQuery<CheckUsernameQuery, CheckUsernameQueryVariables>(
    CheckUsernameDocument,
    baseOptions
  );
}
export type CheckUsernameQueryHookResult = ReturnType<typeof useCheckUsernameQuery>;
export type CheckUsernameLazyQueryHookResult = ReturnType<typeof useCheckUsernameLazyQuery>;
export type CheckUsernameQueryResult = Apollo.QueryResult<
  CheckUsernameQuery,
  CheckUsernameQueryVariables
>;
export const UpsertUserDocument = gql`
  mutation upsertUser($userId: String!, $user: User_insert_input!, $subscriptionPlanId: String!) {
    delete_UserStreamingService(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    insert_User_one(
      object: $user
      on_conflict: { constraint: users_pkey, update_columns: [id, email, username] }
    ) {
      id
    }
    insert_UserSubscription_one(
      object: { userId: $userId, subscriptionPlanId: $subscriptionPlanId }
      on_conflict: { constraint: UserTier_userId_key, update_columns: [] }
    ) {
      userId
    }
  }
`;
export type UpsertUserMutationFn = Apollo.MutationFunction<
  UpsertUserMutation,
  UpsertUserMutationVariables
>;

/**
 * __useUpsertUserMutation__
 *
 * To run a mutation, you first call `useUpsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserMutation, { data, loading, error }] = useUpsertUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      user: // value for 'user'
 *      subscriptionPlanId: // value for 'subscriptionPlanId'
 *   },
 * });
 */
export function useUpsertUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertUserMutation, UpsertUserMutationVariables>
) {
  return Apollo.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(
    UpsertUserDocument,
    baseOptions
  );
}
export type UpsertUserMutationHookResult = ReturnType<typeof useUpsertUserMutation>;
export type UpsertUserMutationResult = Apollo.MutationResult<UpsertUserMutation>;
export type UpsertUserMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserMutation,
  UpsertUserMutationVariables
>;
